import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Box,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Grid,
  ListItemText,
  LinearProgress
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ADMIN_USERS } from "src/services/queries";
import { CREATE_ADMIN_USER, DELETE_USER } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";
import { Skeleton } from "@material-ui/lab";
import SkeletonTable from "src/components/SkeletonTable";
import AvatarUser from "src/components/AvatarUser";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1"
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const AdminUsersList = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  const [adminusers, setAdminUsers] = useState([]);
  const [totalAdminUsers, setTotalAdminUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirm: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [getAdmins, resultAdmins] = useLazyQuery(ADMIN_USERS);
  useEffect(() => {
    if (resultAdmins.data) {
      console.log(resultAdmins.data.usersAdmin);
      setAdminUsers(resultAdmins.data.usersAdmin);
      setTotalAdminUsers(resultAdmins.data.usersAdmin.length);
    }
  }, [resultAdmins]);

  const [adminMutation, resultAdminMutation] = useMutation(CREATE_ADMIN_USER, {
    refetchQueries: [{ query: ADMIN_USERS }],
    onCompleted(data) {
      console.log(data);
      if (data.createAdmin.message === "User added") {
        console.log("CREATE_ADMIN_USER");
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
        });
        setValues(initialRecordState);
        fetchAllAdministrator();
        formRef.current?.resetForm();
      } else {
        setSubmitted(false);
        setOpenAlert(true);
        //setValues(initialRecordState);
        setErrorMsg({
          type: "error",
          message: data.createAdmin.message
        });
      }
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      //setValues(initialRecordState);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
    }
  });

  const [deleteUserMutation, resultDeleteUserMutation] = useMutation(
    DELETE_USER,
    {
      refetchQueries: [{ query: ADMIN_USERS }],
      onCompleted(data) {
        console.log(data);
        if (data.deleteAdminUser == true) {
          console.log("DELETE_ADMIN_USER");
          setOpenAlert(true);
          setAdminUsers(adminusers.filter(item => item.id !== itemSelected.id));
          setErrorMsg({
            type: "success",
            message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
          });
          openCloseModalDelete();
        } else {
          setOpenAlert(true);
          openCloseModalDelete();
          setErrorMsg({
            type: "error",
            message: "No se pudo eliminar."
          });
        }
      },
      onError(error) {
        console.log(error);
        setOpenAlert(true);
        openCloseModalDelete();
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
        });
      }
    }
  );

  /* const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }; */

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, adminusers.length - page * rowsPerPage);

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setAdminUsers(
        [...items].sort((a, b) =>
          a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setAdminUsers(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  /**
   * Get All
   */
  const fetchAllAdministrator = () => {
    let isLoaded = true;

    getAdmins();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAllAdministrator();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * deleteAdminUser
   */
  const deleteAdminUser = id => {
    console.log("ID: " + id);
    deleteUserMutation({
      variables: {
        id
      }
    });
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteAdminUser(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  const saveData = (values, setSubmitting, resetForm) => {
    console.log(values);
    adminMutation({
      variables: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        role: 1
      }
    });
  };

  if (!adminusers) return null;

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={values}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Debe ser un correo electrónico válido")
            .required("El correo electrónico es obligatorio"),
          first_name: Yup.string().required("El nombre es requerido."),
          last_name: Yup.string().required("El apellido es requerido."),
          password: Yup.string()
            .required("La contraseña es requerida.")
            .min(
              8,
              "La contraseña es muy corta - Debería tener mínimo 8 caracteres."
            )
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              "Debe de contener mayúscula, número y al menos un caracter especial."
            ),
          password_confirm: Yup.string()
            .required("Confirmar la contraseña es requerido.")
            .min(
              8,
              "La contraseña es muy corta - Debería tener mínimo 8 caracteres."
            )
            .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden.")
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              "Debe de contener al menos una mayúscula, un número y un caracter especial."
            )
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          saveData(values, setSubmitting, resetForm);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <Card>
              <CardHeader title="Agregar administrador" />

              {resultAdminMutation.loading && <LinearProgress />}
              <Divider />
              <CardContent>
                {/* Alert Success Delete | default hide */}
                <Collapse in={openAlert}>
                  <Alert
                    severity={errorMsg.type}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {errorMsg.message}
                  </Alert>
                  <Box p={1}></Box>
                </Collapse>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      label="Nombre"
                      name="first_name"
                      type="text"
                      required
                      value={values.first_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      label="Apellido"
                      name="last_name"
                      type="text"
                      required
                      value={values.last_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      label="Correo electrónico"
                      name="email"
                      type="text"
                      required
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      label="Crear contraseña"
                      name="password"
                      type="password"
                      required
                      value={values.password}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(
                        touched.password_confirm && errors.password_confirm
                      )}
                      helperText={
                        touched.password_confirm && errors.password_confirm
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      label="Confirmar contraseña"
                      name="password_confirm"
                      type="password"
                      required
                      value={values.password_confirm}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  disabled={
                    isSubmitting && resultAdminMutation.loading ? true : false
                  }
                  color="secondary"
                  variant="contained"
                  //onClick={newAdministrator}
                  type="submit"
                >
                  AGREGAR
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
      <Box p={1}></Box>
      <Card className={clsx(classes.root, className)} {...rest}>
        {resultDeleteUserMutation.loading && <LinearProgress />}

        {/*}
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {*/}

        <Modal open={modalEliminar} onClose={openCloseModalDelete}>
          {bodyModalDialogConfirmDelete}
        </Modal>
        <CardHeader
          action={
            <Box alignItems="center" display="flex">
              <Typography align="left" color="textPrimary" variant="h6">
                Mostrar por:
              </Typography>
              <Button
                className={classes.sortButton}
                onClick={handleSortOpen}
                ref={sortRef}
              >
                {selectedSort}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={sortRef.current}
                className={classes.menu}
                onClose={handleSortClose}
                open={openSort}
              >
                {[
                  SYSTEM_MESSAGES.MOST_RECENT,
                  SYSTEM_MESSAGES.ALPHABETIC_ORDER
                ].map(option => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option}
                    onClick={() => handleSortSelect(option, adminusers)}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }
          title={
            <Box display="flex">
              <Typography
                className={classes.card_header_title}
                variant={"h6"}
                gutterBottom
              >
                Administradores &nbsp;
              </Typography>
              <Typography
                className={classes.card_subtitle_total}
                variant={"h6"}
                gutterBottom
              >
                {totalAdminUsers ? totalAdminUsers : 0} total
              </Typography>
            </Box>
          }
        />
        {resultAdmins.loading && <LinearProgress />}
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Administrador</TableCell>
                  <TableCell>Correo electrónico</TableCell>
                  <TableCell>Fecha agregado</TableCell>
                  <TableCell>Agregado por</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminusers
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => (
                    <TableRow hover key={item.id}>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <AvatarUser id={item.id} only />
                          <Typography color="textPrimary" variant="body1">
                            <Link
                              className={classes.text}
                              to={
                                "/admin/users/admin-user/" +
                                `${item.id}` +
                                "/detail"
                              }
                            >
                              {item.first_name != ""
                                ? item.first_name +
                                  " " +
                                  (item.last_name ? item.last_name : "")
                                : item.email}
                            </Link>
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{item.email ? item.email : 0}</TableCell>
                      <TableCell>
                        {item.createdAt
                          ? Utils.formatDateShort(item.createdAt)
                          : Utils.formatDateShort(item.createdAt)}
                      </TableCell>
                      <TableCell>
                        {item.addedBy && (
                          <Box alignItems="center" display="flex">
                            <Typography color="textPrimary" variant="body1">
                              {item.addedBy}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>
                        <MenuMoreActions data={item} />
                        <Link
                          className={classes.text}
                          to={
                            "/admin/users/admin-user/" +
                            `${item.id}` +
                            "/detail"
                          }
                        >
                          <IconButton edge="end" size="small">
                            <ArrowForwardIcon />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                {/*emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )*/}

                {resultAdminMutation.loading && (
                  <SkeletonTable rows={5} columns={4} />
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>

        <Box display="flex" justifyContent="flex-end" p={2}></Box>
      </Card>
    </div>
  );
};

AdminUsersList.propTypes = {
  className: PropTypes.string,
  adminusers: PropTypes.array
};

AdminUsersList.defaultProps = {
  adminusers: []
};

export default AdminUsersList;
