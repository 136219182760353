import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PerfectScrollbar from "react-perfect-scrollbar";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Container,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Breadcrumbs,
  colors,
  LinearProgress
} from "@material-ui/core";
import Page from "src/components/Page";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_ARTIST } from "src/services/mutations";
import { COUNTRIES } from "src/services/queries";

import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px",
    margin: "auto"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  list: {
    maxHeight: 320
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  title_head_name: {
    color: "#212529"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  },
  avatar_teacher: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  textLinkUpload: {
    color: "#0074BC"
  }
}));

const AddNewArtist = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    first_name: "",
    last_name: "",
    countries_id: "",
    bio: "",
    image: ""
  };

  const countriesInitialState = [
    {
      code: "CR",
      name: "Costa Rica"
    }
  ];

  const [values, setValues] = useState(initialRecordState);
  const [imageUpload, setImageUpload] = useState("");
  const [countries, setCountries] = useState(countriesInitialState);
  const [dashboard, setDashboard] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [getCountries, resultcountries] = useLazyQuery(COUNTRIES, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getCountries");
      console.log(result);

      if (result) {
        if (result.countries) {
          setCountries(result.countries);
        }
      }
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [artistMutation, resultArtistMutation] = useMutation(CREATE_ARTIST, {
    onCompleted(data) {
      console.log("CREATE_ARTIST");
      console.log(data);
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
      });
      setValues(initialRecordState);
      handleRemoveAll();

      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      //setValues(initialRecordState);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
      handleRemoveAll();
    }
  });

  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles.find(f => f);

    setFiles(acceptedFiles);
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {};

    if (!Utils.validateFileImage(file)) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
      });
      handleRemoveAll();
    } else {
      setImageUpload(file);
    }
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
    setImageUpload("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  /**
   * saveNewArtist
   */
  const saveNewArtist = values => {
    if (imageUpload.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    console.log({
      first_name: values.first_name,
      last_name: values.last_name,
      countries_id: parseInt(values.countries_id),
      bio: values.bio,
      image: imageUpload
    });
    artistMutation({
      variables: {
        first_name: values.first_name,
        last_name: values.last_name,
        countries_id: parseInt(values.countries_id),
        bio: values.bio,
        image: imageUpload
      }
    });
  };

  /**
   * Get All
   */
  const fetchAllCountries = () => {
    getCountries();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAllCountries();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Page className={classes.root} title="Agregar Artista">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.text} to={"/admin/arts"}>
              Artistas
            </Link>
            <Typography color="textPrimary">Agregar Artista</Typography>
          </Breadcrumbs>
        </Box>
        <Box mb={1}>
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            Agregar nuevo artista
          </Typography>
        </Box>
        <Grid
          className={clsx(classes.root, className)}
          {...rest}
          container
          spacing={3}
        >
          <Grid item lg={8} sm={6} xs={12} mb={1}>
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialRecordState}
              validationSchema={Yup.object().shape({
                first_name: Yup.string().required("El nombre es requerido."),
                last_name: Yup.string().required("El apellido es requerido."),
                countries_id: Yup.string().required("El país es requerido."),
                bio: Yup.string().required("La biografía es requerida.")
              })}
              onSubmit={values => {
                saveNewArtist(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <Card>
                    <CardHeader title="Información del artista" />
                    {resultArtistMutation.loading && <LinearProgress />}
                    <Divider />
                    <CardContent>
                      <Collapse in={openAlert}>
                        <Alert
                          severity={errorMsg.type}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenAlert(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsg.message}
                        </Alert>
                        <Box p={1}></Box>
                      </Collapse>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Nombre"
                            name="first_name"
                            error={Boolean(
                              touched.first_name && errors.first_name
                            )}
                            helperText={touched.first_name && errors.first_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.first_name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Apellido"
                            name="last_name"
                            error={Boolean(
                              touched.last_name && errors.last_name
                            )}
                            helperText={touched.last_name && errors.last_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="País"
                            name="countries_id"
                            error={Boolean(
                              touched.countries_id && errors.countries_id
                            )}
                            helperText={
                              touched.countries_id && errors.countries_id
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.countries_id}
                            variant="outlined"
                          >
                            <option key={""} value={""}></option>
                            {countries.map(option => (
                              <option key={option.code} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Biografia"
                            name="bio"
                            error={Boolean(touched.bio && errors.bio)}
                            helperText={touched.bio && errors.bio}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            type="text"
                            value={values.bio}
                            variant="outlined"
                            multiline
                            rows={4}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={
                          isSubmitting && resultArtistMutation.loading
                            ? true
                            : false
                        }
                      >
                        GUARDAR
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
          <Grid item lg={4} sm={6} xs={12} mb={1}>
            <Card>
              <CardHeader title="Cargar imagen" />
              <Divider />
              <CardContent>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  className={clsx({
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive
                  })}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div>
                    <img
                      alt="Seleccionar archivo"
                      className={classes.image}
                      src={
                        process.env.PUBLIC_URL +
                        "/static/images/ic_upload_file.png"
                      }
                    />
                  </div>
                  <Typography gutterBottom variant="h3">
                    Agregar archivo
                  </Typography>
                  <Typography
                    className={classes.info}
                    color="textSecondary"
                    variant="body1"
                  >
                    Suelta los archivos aquí o haz clic en <br></br> cargar{" "}
                    desde tu dispositivo
                  </Typography>
                  <Typography gutterBottom variant="h6">
                    Dimensiones: 512px x 512px
                  </Typography>
                  {files.length > 0 && (
                    <Fragment>
                      {files.map((file, i) => (
                        <Typography
                          key={i}
                          className={classes.info}
                          color="textSecondary"
                          variant="body1"
                        >
                          {file.name} - {bytesToSize(file.size)}
                        </Typography>
                      ))}
                    </Fragment>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Box p={3}></Box>
        </Grid>
      </Container>
    </Page>
  );
};

AddNewArtist.propTypes = {
  className: PropTypes.string
};

export default AddNewArtist;
