import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PerfectScrollbar from "react-perfect-scrollbar";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Container,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Breadcrumbs,
  colors,
  LinearProgress
} from "@material-ui/core";
import Page from "src/components/Page";
import CloseIcon from "@material-ui/icons/Close";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ARTISTS,
  GET_ARTWORK,
  GET_CURRENT_QUESTION,
  GET_STYLES,
  GET_TECHNIQUES
} from "src/services/queries";
import {
  DELETE_QUESTION,
  SAVE_QUESTION,
  UPDATE_ARTWORK
} from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px",
    margin: "auto"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  list: {
    maxHeight: 320
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  title_head_name: {
    color: "#212529"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  },
  avatar_teacher: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  textLinkUpload: {
    color: "#0074BC"
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  titleTypeAnswer: {
    color: "#263238"
  },
  customright: {
    marginRight: "10px"
  },
  colorRed: {
    background: "#ffe4e4",

    "& .MuiLinearProgress-bar": {
      backgroundColor: "#ff0000"
    }
  }
}));

const DetailArt = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* art id */
  let { artId } = useParams();
  /* Error message */
  const errors = {
    type: "error",
    message: SYSTEM_MESSAGES.MESSAGE_ERROR_VALIDATION
  };

  const initialRecordState = {
    name: "",
    artists_id: "",
    description: "",
    story: "",
    year: "",
    styles_id: "",
    techniques_id: "",
    thumbnail: "",
    image: "",
    audio: "",
    priority: ""
  };

  const styleInitialState = [
    {
      id: 1,
      nombre: ""
    }
  ];

  const isDragActive = false;
  const [arts, setArts] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(initialRecordState);
  const [stylearts, setStyleArts] = useState(styleInitialState);
  const [arttechniques, setArtTechniques] = useState(styleInitialState);
  const [artists, setArtists] = useState([]);
  const [dashboard, setDashboard] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertQuiz, setOpenAlertQuiz] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [imageUpload, setImageUpload] = useState("");
  const [imagethumbnail, setImageThumbnail] = useState("");
  const [audiotrack, setAudioTrack] = useState("");
  const [files, setFiles] = useState([]);
  const [files_thumbnail, setFileThumbnail] = useState([]);
  const [files_audio, setFileAudio] = useState([]);

  const [getQuestion, resultQuestion] = useLazyQuery(GET_CURRENT_QUESTION, {
    fetchPolicy: "no-cache",
    onCompleted: resultQ => {
      //console.log("getQuestion: ", resultQ);

      if (resultQ.question == 0) addQuestions(0);

      if (resultQ) {
        if (resultQ.question) {
          let objResult = Object.assign([], resultQ.question);

          objResult.map((item, i) => {
            if (item.type == 2) {
              item.respuestas = [
                { texto: item.question1, imagen: "" },
                { texto: item.question2, imagen: "" },
                { texto: item.question3, imagen: "" }
              ];
            }

            if (item.type == 1) {
              item.respuestas = [
                { texto: "", imagen: item.question1 },
                { texto: "", imagen: item.question2 },
                { texto: "", imagen: item.question3 }
              ];
            }
          });
          //console.log(objResult);
          setFormValues(objResult);
        }
      }
    }
  });

  useEffect(() => {
    //console.log("Testing . . . . . .");
    getQuestion({
      variables: {
        artboard_id: artId
      }
    });
  }, []);

  const [getArt, resultArt] = useLazyQuery(GET_ARTWORK, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getArt", result);

      if (result) {
        if (result.artwork) {
          setDashboard(result.artwork);
          setValues(result.artwork);
        }
      }
    }
  });

  const [getStyles, resultStyles] = useLazyQuery(GET_STYLES);
  useEffect(() => {
    if (resultStyles.data) {
      setStyleArts(resultStyles.data.styles);
    }
  }, [resultStyles]);

  const [getTechniques, resultTechniques] = useLazyQuery(GET_TECHNIQUES);
  useEffect(() => {
    if (resultTechniques.data) {
      setArtTechniques(resultTechniques.data.techniques);
    }
  }, [resultTechniques]);

  const [getArtists, resultArtists] = useLazyQuery(GET_ARTISTS);
  useEffect(() => {
    if (resultArtists.data) {
      setArtists(resultArtists.data.artists);
    }
  }, [resultArtists]);

  const [artMutation, resultArtMutation] = useMutation(UPDATE_ARTWORK, {
    onCompleted(data) {
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
      });
      fetchDetailArtwork();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
      });
      fetchDetailArtwork();
    }
  });

  const [questionMutation, resultQuestionMutation] = useMutation(
    SAVE_QUESTION,
    {
      onCompleted(data) {
        console.log("question saved", data);
        setSubmitted(true);
        setOpenAlertQuiz(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
        });
        fetchDetailArtwork();
        getQuestion({
          variables: {
            artboard_id: artId
          }
        });
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlertQuiz(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
        });
        fetchDetailArtwork();
      }
    }
  );

  const [deleteMutation, resultDeleteMutation] = useMutation(DELETE_QUESTION, {
    onCompleted(data) {
      setSubmitted(true);
      setOpenAlertQuiz(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
      });
      getQuestion({
        variables: {
          artboard_id: artId
        }
      });
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlertQuiz(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
      });
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, arts.length - page * rowsPerPage);

  const UploadCover = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFiles(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileImage(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setImageUpload(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFiles([]);
      setImageUpload("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br> cargar desde tu
                dispositivo
              </Typography>
              <Typography gutterBottom variant="h6">
                Dimensiones: 512px x 512px
              </Typography>
              {files.length > 0 && (
                <Fragment>
                  {files.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  const UploadThumbnail = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFileThumbnail(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileImage(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setImageThumbnail(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFileThumbnail([]);
      setImageThumbnail("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br> cargar desde tu
                dispositivo
              </Typography>
              <Typography gutterBottom variant="h6">
                Dimensiones: 256px x 256px
              </Typography>
              {files_thumbnail.length > 0 && (
                <Fragment>
                  {files_thumbnail.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  const UploadAudioTrack = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFileAudio(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileAudio(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setAudioTrack(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFileAudio([]);
      setAudioTrack("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br> cargar desde tu
                dispositivo
              </Typography>
              {files_audio.length > 0 && (
                <Fragment>
                  {files_audio.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  /**
   * [Change the name of the question]
   * @param {*} index
   * @param {*} e
   */
  let handleChanges = (index, e) => {
    //console.log("handleChanges ", index, e.target.value);
    let newFormValues = [...formValues];
    newFormValues[index][e.target.name] = e.target.value;
    newFormValues[index].orden = index;
    setFormValues(newFormValues);
  };

  /**
   * [Changes the response when it is of text type]
   * @param {*} indexAnswer
   * @param {*} e
   */
  let handleChangesText = (index, indexAnswer, e) => {
    const items = cloneDeep(formValues);
    items[index].respuestas[indexAnswer].texto = e.target.value;
    setFormValues(items);
  };

  /**
   * [Changes the response when it is of type image]
   * @param {*} i
   * @param {*} indexAnswer
   * @param {*} e
   */
  const handleChangeResponse = (i, indexAnswer, e) => {
    console.log("handleChangeResponse ", i, indexAnswer, e);

    if (!Utils.validateFileImage(e.target.files[0])) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
      });
      return true;
    }

    const items = cloneDeep(formValues);
    e.target.files
      ? (items[i].respuestas[indexAnswer].imagen = e.target.files[0])
      : (items[i].pregunta = e.target.value);
    setFormValues(items);
  };

  /**
   * [add a new form to add a new question]
   * @param {*} index
   */
  const addQuestions = index => {
    console.log("Adding new question + + + + + + + +");
    console.log(index);

    setFormValues([
      ...formValues,
      {
        order: index,
        question: "",
        type: 1,
        respuestas: [
          { texto: "", imagen: "" },
          { texto: "", imagen: "" },
          { texto: "", imagen: "" }
        ],
        correcta: 1
      }
    ]);
    //const items = cloneDeep(formValues);
    //setTotal(items.length);
  };

  /**
   * [remove the question from any position]
   * @param {*} i
   */
  const removeFormFields = (id, i) => {
    console.log("id: ", id);
    console.log("i: ", i);

    if (id) {
      deleteMutation({
        variables: {
          id
        }
      });
    }

    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  /**
   * [change the type of answer if it is Image or Text]
   * @param {*} index
   * @param {*} e
   */
  const handleChangeTypeAnswer = (index, e) => {
    const oldItems = formValues;

    if (e.target.value == 1) {
      oldItems[index].respuestas.map((item, indexAnswer) => {
        item.texto = "";
      });
    } else if (e.target.value == 2) {
      oldItems[index].respuestas.map((item, indexAnswer) => {
        item.imagen = "";
      });
    }

    const items = cloneDeep(formValues);
    items[index].tipo = e.target.value;
    setFormValues(items);
  };

  /**
   * [change the correct answer to the question]
   * @param {*} index
   * @param {*} e
   */
  const handleChangeCorrectAnswer = (index, e) => {
    const oldItems = formValues;

    // if(e.target.value) {
    //   oldItems[index].map((item, indexAnswer) => {
    //     item.correcta = 1;
    //   });
    // }

    const items = cloneDeep(formValues);
    items[index].correcta = e.target.value;
    setFormValues(items);
  };

  let handleSubmit = event => {
    event.preventDefault();
    console.log("handleSubmit ", formValues);
    console.log(JSON.stringify(...formValues));

    // const _formData = formValues;

    // console.log(JSON.stringify(_formData));
  };

  /**
   * saveUpdateArtwork
   */
  const saveUpdateArtwork = values => {
    let dataValue = {
      artists_id: parseInt(values.artists_id),
      styles_id: parseInt(values.styles_id),
      techniques_id: parseInt(values.techniques_id),
      name: values.name,
      year: parseInt(values.year),
      description: values.description,
      story: values.story,
      currentImage: values.image,
      currentThumb: values.thumbnail,
      currentAudio: values.audio,
      priority: parseInt(values.priority),
      id: parseInt(artId)
    };

    if (imageUpload) dataValue.image = imageUpload;

    if (imagethumbnail) dataValue.thumbnail = imagethumbnail;

    if (audiotrack) dataValue.audio = audiotrack;

    console.log(dataValue);

    artMutation({
      variables: dataValue
    });
  };

  const saveUpdateQuizArtwork = () => {
    const _formDataQuestions = new FormData();
    const _formDataQuestionss = { preguntas: formValues };
    //console.log("_formDataQuestionss: ", _formDataQuestionss);
    _formDataQuestions.append("preguntas", JSON.stringify(_formDataQuestionss));
    let dataValues;

    console.log("_formData: ", formValues);

    formValues.map((item, i) => {
      const {
        respuestas,
        question,
        answer,
        order,
        type,
        correcta,
        id,
        question1,
        question2,
        question3
      } = item;
      console.log("item", item);
      if (
        (question && question.length === 0) ||
        (respuestas && respuestas.length === 0) ||
        (question1 && question1.length === 0) ||
        (question2 && question2.length === 0) ||
        (question3 && question3.length === 0)
      ) {
        setOpenAlertQuiz(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_VALIDATION
        });
      } else {
        console.log(item);
        dataValues = {
          id: id ? parseInt(id) : null,
          artboard_id: parseInt(artId),
          question: question,
          type: parseInt(type),
          answer: correcta ? parseInt(correcta) : parseInt(answer),
          order: parseInt(order)
        };

        if (type == 2) {
          dataValues.question1 = respuestas[0].texto ? respuestas[0].texto : "";
          dataValues.question2 = respuestas[1].texto ? respuestas[1].texto : "";
          dataValues.question3 = respuestas[2].texto ? respuestas[2].texto : "";
        }

        if (type == 1) {
          if (!id) {
            dataValues.questionImage1 = respuestas[0].imagen
              ? respuestas[0].imagen
              : "";
            dataValues.questionImage2 = respuestas[1].imagen
              ? respuestas[1].imagen
              : "";
            dataValues.questionImage3 = respuestas[2].imagen
              ? respuestas[2].imagen
              : "";
          } else {
            dataValues.question1 = respuestas[0].imagen
              ? respuestas[0].imagen
              : "";
            dataValues.question2 = respuestas[1].imagen
              ? respuestas[1].imagen
              : "";
            dataValues.question3 = respuestas[2].imagen
              ? respuestas[2].imagen
              : "";
          }
        }

        console.log("dataValues: ", dataValues);

        if (dataValues) {
          questionMutation({
            variables: dataValues
          });
        }
      }
    });
  };

  /**
   * Get All
   */
  const fetchDetailArtwork = () => {
    getArt({
      variables: {
        id: artId
      }
    });
  };

  /**
   * Get questions
   */
  const fetchQuizArtwork = () => {};

  const fetchStyleArts = () => {
    getStyles();
  };

  const fetchTechniques = () => {
    getTechniques();
  };

  const fetchAllArtists = () => {
    getArtists();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchDetailArtwork();
    fetchQuizArtwork();
    fetchStyleArts();
    fetchTechniques();
    fetchAllArtists();

    return () => {
      isLoaded = false;
    };
  }, []);

  const quizForm = (item, index) => {
    return (
      <div className="form-inline" key={index}>
        <Card>
          <CardHeader title="Quiz sobre la obra" />
          {resultQuestionMutation.loading && <LinearProgress />}
          {resultDeleteMutation.loading && (
            <LinearProgress className={classes.colorRed} />
          )}
          <Divider />
          <CardContent>
            <Collapse in={openAlertQuiz}>
              <Alert
                severity={errorMsg.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlertQuiz(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMsg.message}
              </Alert>
              <Box p={1}></Box>
            </Collapse>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography gutterBottom variant="h3">
                  Pregunta {index + 1}
                </Typography>
                <Box p={1}></Box>
                <TextField
                  fullWidth
                  label="Pregunta"
                  name="question"
                  onChange={e => handleChanges(index, e)}
                  required
                  value={item.question || ""}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography
                  className={classes.titleTypeAnswer}
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  Tipo de Respuesta
                </Typography>
                <div
                  id={index}
                  onChange={e => handleChangeTypeAnswer(index, e)}
                >
                  <input
                    id={"tipo" + index}
                    type="radio"
                    value="1"
                    name={index}
                    defaultChecked={item.type == 1 ? true : false}
                    onChange={() => {
                      item.type = 1;
                    }}
                  />{" "}
                  Imagen
                  <Box p={1}></Box>
                  <input
                    id={"tipo" + index}
                    type="radio"
                    value="2"
                    name={index}
                    defaultChecked={item.type == 2 ? true : false}
                    onChange={() => {
                      item.type = 2;
                    }}
                  />{" "}
                  Texto
                  <Box p={1}></Box>
                </div>
                {item.type == 2 ? (
                  <div>
                    {(item.respuestas || []).map((subitems, indexAnswer) => {
                      return (
                        <div key={indexAnswer} className={classes.content}>
                          {indexAnswer == 0 ? (
                            <div>
                              <Typography gutterBottom variant="h6">
                                Respuesta #1
                              </Typography>
                              <Box p={1}></Box>
                              <TextField
                                id={
                                  "responseName" +
                                  index +
                                  "-response" +
                                  indexAnswer
                                }
                                fullWidth
                                label="Respuesta #1"
                                name={subitems.texto}
                                onChange={e =>
                                  handleChangesText(index, indexAnswer, e)
                                }
                                required
                                value={subitems.texto ? subitems.texto : ""}
                                variant="outlined"
                              />
                              <Box p={1}></Box>
                            </div>
                          ) : (
                            ""
                          )}
                          {indexAnswer == 1 ? (
                            <div>
                              <Typography gutterBottom variant="h6">
                                Respuesta #2
                              </Typography>
                              <Box p={1}></Box>
                              <TextField
                                id={
                                  "responseName" +
                                  index +
                                  "-response" +
                                  indexAnswer
                                }
                                fullWidth
                                label="Respuesta #2"
                                name={subitems.texto}
                                onChange={e =>
                                  handleChangesText(index, indexAnswer, e)
                                }
                                required
                                value={subitems.texto ? subitems.texto : ""}
                                variant="outlined"
                              />
                              <Box p={1}></Box>
                            </div>
                          ) : (
                            ""
                          )}
                          {indexAnswer == 2 ? (
                            <div>
                              <Typography gutterBottom variant="h6">
                                Respuesta #3
                              </Typography>
                              <Box p={1}></Box>
                              <TextField
                                id={
                                  "responseName" +
                                  index +
                                  "-response" +
                                  indexAnswer
                                }
                                fullWidth
                                label="Respuesta #3"
                                name={subitems.texto}
                                onChange={e =>
                                  handleChangesText(index, indexAnswer, e)
                                }
                                required
                                value={subitems.texto ? subitems.texto : ""}
                                variant="outlined"
                              />
                              <Box p={1}></Box>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                    <Grid item md={12} xs={12}>
                      <Typography gutterBottom variant="h6">
                        Elegir respuesta correcta
                      </Typography>
                      <Box p={1}></Box>
                      <TextField
                        fullWidth
                        label=""
                        name={index}
                        onChange={e => handleChangeCorrectAnswer(index, e)}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={item.correcta ? item.correcta : item.answer}
                        variant="outlined"
                      >
                        <option key={1} value={1}>
                          Respuesta 1
                        </option>
                        <option key={2} value={2}>
                          Respuesta 2
                        </option>
                        <option key={3} value={3}>
                          Respuesta 3
                        </option>
                      </TextField>
                    </Grid>
                  </div>
                ) : (
                  <div>
                    {(item.respuestas || []).map((subitems, indexAnswer) => {
                      return (
                        <div key={indexAnswer} className={classes.content}>
                          {indexAnswer == 0 ? (
                            <div>
                              <Typography gutterBottom variant="h6">
                                Respuesta #1
                              </Typography>
                              <Box p={1}></Box>
                              <input
                                id={
                                  "questionName" +
                                  index +
                                  "-response" +
                                  indexAnswer
                                }
                                onChange={e =>
                                  handleChangeResponse(index, indexAnswer, e)
                                }
                                type="file"
                                accept="image/png, image/jpeg"
                                className="form-control questionsFile"
                                placeholder="File"
                              />
                              <Box p={1}></Box>
                            </div>
                          ) : (
                            ""
                          )}
                          {indexAnswer == 1 ? (
                            <div>
                              <Typography gutterBottom variant="h6">
                                Respuesta #2
                              </Typography>
                              <Box p={1}></Box>
                              <input
                                id={
                                  "questionName" +
                                  index +
                                  "-response" +
                                  indexAnswer
                                }
                                onChange={e =>
                                  handleChangeResponse(index, indexAnswer, e)
                                }
                                type="file"
                                accept="image/png, image/jpeg"
                                className="form-control questionsFile"
                                placeholder="File"
                              />
                              <Box p={1}></Box>
                            </div>
                          ) : (
                            ""
                          )}
                          {indexAnswer == 2 ? (
                            <div>
                              <Typography gutterBottom variant="h6">
                                Respuesta #3
                              </Typography>
                              <Box p={1}></Box>
                              <input
                                id={
                                  "questionName" +
                                  index +
                                  "-response" +
                                  indexAnswer
                                }
                                onChange={e =>
                                  handleChangeResponse(index, indexAnswer, e)
                                }
                                type="file"
                                accept="image/png, image/jpeg"
                                className="form-control questionsFile"
                                placeholder="File"
                              />
                              <Box p={1}></Box>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                    <Grid item md={12} xs={12}>
                      <Typography gutterBottom variant="h6">
                        Elegir respuesta correcta
                      </Typography>
                      <Box p={1}></Box>
                      <TextField
                        fullWidth
                        label=""
                        name={index}
                        onChange={e => handleChangeCorrectAnswer(index, e)}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={item.correcta ? item.correcta : item.answer}
                        variant="outlined"
                      >
                        <option key={1} value={1}>
                          Respuesta 1
                        </option>
                        <option key={2} value={2}>
                          Respuesta 2
                        </option>
                        <option key={3} value={3}>
                          Respuesta 3
                        </option>
                      </TextField>
                    </Grid>
                  </div>
                )}
                <Box p={1}></Box>
              </Grid>
            </Grid>
            <Box p={1}></Box>
          </CardContent>
          <Divider />
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              {formValues.length > 0 ? (
                <Box display="flex" justifyContent="flex-start" p={2}>
                  <Button onClick={() => removeFormFields(item.id, index)}>
                    ELIMINAR
                  </Button>
                </Box>
              ) : null}
            </Grid>
            <Grid item md={8} xs={12}>
              {formValues.length > 0 ? "" : ""}
              {index < formValues.length - 1 ? (
                ""
              ) : (
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    className={classes.customright}
                    color="secondary"
                    variant="outlined"
                    onClick={() => addQuestions(index)}
                  >
                    AGREGAR OTRA PREGUNTA
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={saveUpdateQuizArtwork}
                  >
                    FINALIZAR
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Card>
        <Box p={1}></Box>
      </div>
    );
  };

  return (
    <Page className={classes.root} title="Detalle de la obra">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.text} to={"/admin/arts"}>
              Arte
            </Link>

            <Link className={classes.text} to={"/admin/arts/"}>
              Obras
            </Link>
            <Typography color="textPrimary">Detalle de la obra</Typography>
          </Breadcrumbs>
        </Box>
        <Grid
          className={clsx(classes.root, className)}
          {...rest}
          container
          spacing={3}
        >
          <Grid item lg={4} sm={6} xs={12} mb={1}>
            <Card>
              <CardContent>
                <Grid container justify="space-between" spacing={3}>
                  <Grid item>
                    <Typography
                      className={classes.title_head_name}
                      gutterBottom
                      variant="h2"
                    >
                      {dashboard.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatar_teacher}>
                      <img
                        className={classes.avatarItem}
                        src={dashboard.image}
                        width={90}
                        height={90}
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src =
                            process.env.PUBLIC_URL +
                            "/static/images/neutral_avatar.jpeg";
                        }}
                      />
                    </Avatar>
                  </Grid>
                </Grid>
                <Box mt={0} display="flex" alignItems="center">
                  <Typography
                    className={classes.subtitle_text_card}
                    color="textPrimary"
                    variant="caption"
                  >
                    {dashboard.autor}
                  </Typography>
                </Box>
                <Box mt={0} display="flex" alignItems="center">
                  <Typography
                    className={classes.subtitle_text_card}
                    color="textPrimary"
                    variant="caption"
                  >
                    {dashboard.anho}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
            <Box p={1}></Box>
            <Card>
              <CardContent>
                <Grid container justify="space-between" spacing={3}>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      ASIGNACIONES BASADAS EN LA OBRA
                    </Typography>
                    <Typography color="textPrimary" variant="h3">
                      {dashboard.asignaciones ? dashboard.asignaciones : 0}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatarProgress}>
                      <ShowChartOutlinedIcon />
                    </Avatar>
                  </Grid>
                </Grid>
                <Box p={1}></Box>
              </CardContent>
            </Card>
            <Box p={1}></Box>
            <UploadCover title="Cargar imagen"></UploadCover>
            <Box p={1}></Box>
            <UploadThumbnail title="Cargar thumbnail"></UploadThumbnail>
            <Box p={1}></Box>
            <UploadAudioTrack title="Cargar pista de audio"></UploadAudioTrack>
            <Box p={1}></Box>
            <Card>
              <CardHeader title="Peso (prioridad)" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Peso (prioridad)"
                      name="priority"
                      onChange={handleChange}
                      required
                      value={values.priority}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} sm={6} xs={12} mb={1}>
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={values}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("El nombre es requerido."),
                artists_id: Yup.string().required("El artista es requerido."),
                techniques_id: Yup.string().required(
                  "La técnica es requerida."
                ),
                styles_id: Yup.string().required("La biografía es requerida."),
                year: Yup.number()
                  .typeError("Solo se permiten números.")
                  .positive("Solo número positivo.")
                  .integer("Solo se permiten enteros.")
                  .required("El año es requerido."),
                description: Yup.string().required(
                  "La descripción es requerida."
                ),
                story: Yup.string().required("La historia es requerida."),
                priority: Yup.number()
                  .typeError("Solo se permiten números.")
                  .positive("Solo número positivo.")
                  .integer("Solo se permiten enteros.")
                  .required("La prioridad es requerida.")
              })}
              onSubmit={values => {
                saveUpdateArtwork(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <Card>
                    <CardHeader title="Información de la obra" />
                    {resultArtMutation.loading && <LinearProgress />}
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Nombre de la obra"
                            name="name"
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Artista"
                            name="artists_id"
                            error={Boolean(
                              touched.artists_id && errors.artists_id
                            )}
                            helperText={touched.artists_id && errors.artists_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.artists_id}
                            variant="outlined"
                          >
                            <option key="" value=""></option>
                            {artists.map(option => (
                              <option key={option.id} value={option.id}>
                                {option.first_name} {option.last_name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Año"
                            name="year"
                            error={Boolean(touched.year && errors.year)}
                            helperText={touched.year && errors.year}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.year}
                            variant="outlined"
                            placeholder="Año"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Estilo"
                            name="styles_id"
                            error={Boolean(
                              touched.styles_id && errors.styles_id
                            )}
                            helperText={touched.styles_id && errors.styles_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.styles_id}
                            variant="outlined"
                          >
                            <option key="" value=""></option>

                            {stylearts.map(option => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Técnica"
                            name="techniques_id"
                            error={Boolean(
                              touched.techniques_id && errors.techniques_id
                            )}
                            helperText={
                              touched.techniques_id && errors.techniques_id
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.techniques_id}
                            variant="outlined"
                          >
                            <option key="" value=""></option>

                            {arttechniques.map(option => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Descripción"
                            name="description"
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            helperText={
                              touched.description && errors.description
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            type="text"
                            value={values.description}
                            variant="outlined"
                            placeholder="Descripción..."
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Historia"
                            name="story"
                            error={Boolean(touched.story && errors.story)}
                            helperText={touched.story && errors.story}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            type="text"
                            value={values.story}
                            variant="outlined"
                            placeholder="Historia..."
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <TextField
                            fullWidth
                            label="Peso (prioridad)"
                            name="priority"
                            error={Boolean(touched.priority && errors.priority)}
                            helperText={touched.priority && errors.priority}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.priority}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={
                          isSubmitting && resultArtMutation.loading
                            ? true
                            : false
                        }
                      >
                        GUARDAR
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
            <Box p={1}></Box>

            {formValues.length == 0 && (
              <Button
                className={classes.customright}
                color="secondary"
                variant="outlined"
                onClick={() => addQuestions(0)}
              >
                AGREGAR PREGUNTA
              </Button>
            )}

            {formValues &&
              formValues.map((item, index) => quizForm(item, index))}
            {/* Quiz */}
          </Grid>
          <Box p={3}></Box>
          <Grid item lg={12} sm={6} xs={12} mb={1}></Grid>
        </Grid>
      </Container>
    </Page>
  );
};

DetailArt.propTypes = {
  className: PropTypes.string
};

export default DetailArt;
