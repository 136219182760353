import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// import MainLayout from 'src/layouts/MainLayout';
import MainLayoutGuest from "src/layouts/MainLayoutGuest";
import AccountView from "src/views/account/AccountView";
import DashboardView from "src/views/reports/DashboardView";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import ProductListView from "src/views/product/ProductListView";
import RegisterView from "src/views/auth/RegisterView";
import SettingsView from "src/views/settings/SettingsView";
import ClassDetail from "src/views/groups/ClassDetail";
import Assignments from "src/views/groups/Assignments";
import EditAssignment from "src/views/groups/Assignments/EditAssignment";
import InviteStudent from "src/views/groups/Students";
import EditStudent from "src/views/groups/Students/EditStudent";
import DashboardGroups from "src/views/groups/DashboardGroups";
import ClassGroups from "src/views/groups/DashboardGroups/ClassGroups";
import NewGroupClass from "src/views/groups/NewGroups";
import AssignmentDetailList from "src/views/groups/Assignments/AssignmentDetail";
/* Schools */
import Schools from "src/views/schools";
import AddNewSchool from "src/views/schools/NewSchool";
import DetailSchool from "src/views/schools/DetailSchool";
import AddNewTeacher from "src/views/schools/DetailSchool/Teachers/NewTeacher";
import EditTeacher from "src/views/schools/DetailSchool/Teachers/EditTeacher";
import InfoTeacher from "src/views/schools/DetailSchool/Teachers/DetailTeacher";
import ListCompletedPuzzleStd from "src/views/schools/DetailSchool/Students/StudentDetail";
import NewClassGroup from "src/views/schools/DetailSchool/ClassGroups/NewClassGroup";
import DetailClassGroup from "src/views/schools/DetailSchool/ClassGroups/DetailClassGroup";
/* Arts */
import DashboardArt from "src/views/art";
import NewArt from "src/views/art/NewArt/NewArt";
import DetailArt from "src/views/art/DetailArt/DetailArt";
import DetailArtist from "src/views/art/Artists/DetailArtist/DetailArtist";
import AddNewArtist from "src/views/art/Artists/NewArtist/AddNewArtist";
import DetailStyle from "src/views/art/Styles/DetailStyle";
import DetailTechnique from "src/views/art/ArtTechniques/DetailTechnique";
/* Users */
import PublicUsersList from "src/views/users/PublicUsers";
import ListCompletedPuzzles from "src/views/users/PublicUsers/DetailPublicUser";
/* System */
import DashboardSystem from "src/views/system";
import EditAcademicLevel from "src/views/system/AcademicLevels/EditAcademicLevel";
import EditSchoolGrade from "src/views/system/SchoolGrades/EditSchoolGrade";
import EditSchoolGroup from "src/views/system/SchoolGroups/EditSchoolGroup";
import EditSchoolDifficulty from "src/views/system/SchoolDifficulty/EditSchoolDifficulty";
/* Store */
import DashboardStore from "src/views/store";
import AddNewArt from "src/views/store/Arts/NewArt";
import EditArt from "src/views/store/Arts/EditArt";
import AddNewAvatar from "src/views/store/Avatars/NewAvatar";
import EditAvatar from "src/views/store/Avatars/EditAvatar";
/* Ads */
import DashboardAds from "src/views/ads";
import AddNewAds from "src/views/ads/NewAds";
import DetailAds from "src/views/ads/DetailAds";
/* Admin Users */
import AdminUsersList from "src/views/users/AdminUsers";
import DetailAdminUser from "src/views/users/AdminUsers/DetailAdminUser";
import DashboardInit from "./layouts/DashboardInit";

const routes = [
  {
    path: "/",
    element: <DashboardInit />
  },
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "account", element: <AccountView /> },
      { path: "detail-student", element: <ClassDetail /> },
      { path: "dashboard", element: <DashboardView /> },
      { path: "products", element: <ProductListView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "detail", element: <ClassDetail /> }
    ]
  },
  {
    path: "admin",
    element: <DashboardLayout />,
    children: [
      { path: "classrooms", element: <ClassGroups /> },
      { path: "classroom/view/:classroomId", element: <DashboardGroups /> },
      { path: "classroom/new-classroom", element: <NewGroupClass /> },
      {
        path: "classroom/:classroomId/assignment/new-assignment",
        element: <Assignments />
      },
      {
        path: "classroom/:classroomId/assignment/edit/:assignmentId",
        element: <EditAssignment />
      },
      {
        path: "classroom/:classroomId/assignment/detail/:assignmentId",
        element: <AssignmentDetailList />
      },
      {
        path: "classroom/:classroomId/student/invite",
        element: <InviteStudent />
      },
      {
        path: "classroom/:classroomId/student/edit/:studentId",
        element: <EditStudent />
      },
      {
        path: "classroom/:classroomId/student/detail/:studentId",
        element: <ClassDetail />
      },
      { path: "account", element: <AccountView /> },
      { path: "schools", element: <Schools /> },
      { path: "school/new-school", element: <AddNewSchool /> },
      { path: "school/:schoolId/detail", element: <DetailSchool /> },
      { path: "school/:schoolId/detail/:tab", element: <DetailSchool /> },
      { path: "school/:schoolId/new-teacher", element: <AddNewTeacher /> },
      {
        path: "school/:schoolId/teacher/:teacherId/edit",
        element: <EditTeacher />
      },
      {
        path: "school/:schoolId/teacher/:teacherId/detail",
        element: <InfoTeacher />
      },
      {
        path: "school/:schoolId/student/:studentId/detail",
        element: <ListCompletedPuzzleStd />
      },
      { path: "school/:schoolId/new-classgroup", element: <NewClassGroup /> },
      {
        path: "school/:schoolId/classgroup/:classgroupId/detail",
        element: <DetailClassGroup />
      },
      {
        path: "school/:schoolId/classgroup/:classgroupId/detail/:tab",
        element: <DetailClassGroup />
      },
      { path: "arts", element: <DashboardArt /> },
      { path: "arts/:tab", element: <DashboardArt /> },
      { path: "art/new-art", element: <NewArt /> },
      { path: "art/:artId/detail", element: <DetailArt /> },
      { path: "art/artist/new-artist", element: <AddNewArtist /> },
      { path: "art/artist/:artistId/detail", element: <DetailArtist /> },
      { path: "art/style/:styleId/detail", element: <DetailStyle /> },
      {
        path: "art/technique/:techniqueId/detail",
        element: <DetailTechnique />
      },
      { path: "users/public-users", element: <PublicUsersList /> },
      {
        path: "users/public-user/:publicUserId/detail",
        element: <ListCompletedPuzzles />
      },
      { path: "system", element: <DashboardSystem /> },
      { path: "system/:tab", element: <DashboardSystem /> },
      {
        path: "system/academic-level/:academicLevelId/detail",
        element: <EditAcademicLevel />
      },
      {
        path: "system/school-grade/:schoolGradeId/detail",
        element: <EditSchoolGrade />
      },
      {
        path: "system/school-group/:schoolGroupId/detail",
        element: <EditSchoolGroup />
      },
      {
        path: "system/difficulty/:difficultyId/detail",
        element: <EditSchoolDifficulty />
      },
      { path: "store", element: <DashboardStore /> },
      { path: "store/:tab", element: <DashboardStore /> },
      { path: "store/art/new-art", element: <AddNewArt /> },
      { path: "store/art/:artId/edit", element: <EditArt /> },
      { path: "store/avatar/new-avatar", element: <AddNewAvatar /> },
      { path: "store/avatar/:avatarId/edit", element: <EditAvatar /> },
      { path: "ads", element: <DashboardAds /> },
      { path: "ads/new-ads", element: <AddNewAds /> },
      { path: "ads/:adsId/detail", element: <DetailAds /> },
      { path: "users/admin-users", element: <AdminUsersList /> },
      {
        path: "users/admin-user/:adminUserId/detail",
        element: <DetailAdminUser />
      },
      { path: "settings", element: <SettingsView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  },
  {
    path: "404",
    element: <NotFoundView />,
    children: [
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  }
];

const defaultRoute = [
  {
    path: "/",
    element: <MainLayoutGuest />,
    exact: true,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "*", element: <Navigate to="/login" /> }
    ]
  }
];

const routing_app = {
  routes,
  defaultRoute
};

export default routing_app;
