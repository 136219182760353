import moment from 'moment';

/**
 * [Format date]
 * @return DD/MM/YYYY
 */
function formatDateShort(date){
    let newFormatDate = new Date(date);
    
    if(date){
        newFormatDate = moment(date).format('DD/MM/YYYY');
    }

    return newFormatDate;
}

/**
 * isEmail
 * [Chek if email is valid]
 * @param string email 
 * @returns boolean
 */
function isEmail(email){
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

/**
 * isEmpty
 * [Chek if input is empty]
 * @param string data_param 
 * @returns boolean
 */
function isEmpty(data_param){
    let empty = false;

    switch (typeof data_param) {
        case 'undefined':
            empty = true;
            break;
        case 'string':
            if (data_param.trim().length === 0) {
                empty = true;
            }
            break;
        case 'object':
            if (data_param === null) {
                empty = true;
            } else if (Object.keys(data_param).length === 0) {
                empty = true;
            }
            break;
        default:
            empty = true;
    }

    return empty;
}

/**
 * isContainWhiteSpace
 * [Check if string contains whitespaces]
 * @param string str 
 * @returns boolean
 */
function isContainWhiteSpace(str) {
    if(typeof str === 'string' || typeof str === 'number'){
        return str.toString().trim().indexOf(' ') !== -1;
    } else {
        return false;
    }
}

/**
 * validateFileImage
 * @param string file
 * @returns boolean
 */
function validateFileImage(file) {
    if (file) {
      var fileName = file.name.split(".");
      if (fileName[1] == "jpg" || fileName[1] == "jpeg" || fileName[1] == "png") {
        return true;
      }
    }
    return false;
}

/**
 * validateFileAudio
 * @param string file
 * @returns boolean
 */
function validateFileAudio(file) {
    if (file) {
      var fileName = file.name.split(".");
      if (fileName[1] == "mp3") {
        return true;
      }
    }
    return false;
}

const Utils = {
    formatDateShort,
    isEmail,
    isEmpty,
    isContainWhiteSpace,
    validateFileImage,
    validateFileAudio
}

export default Utils