import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardHeader, Divider, Table, TableBody, TableCell,
  TableHead, TablePagination, TableRow, Typography, makeStyles } from '@material-ui/core';
import ApiService from 'src/services/ApiService';
import API_URL from 'src/config/config';
import Utils from 'src/utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: '40px',
    height: '40px',
    backgroundSize: 'cover',
    objectFit: 'cover'
  }
}));

const PATH_URL = API_URL;

const AssignmentDetailList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [assignments, setAssignments] = useState([]);
  const [totalAssignments, setTotalAssignments] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  /* Id classroom */
  let { classroomId } = useParams();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, assignments.length - page * rowsPerPage);

  /**
   * Fetch API
   */
   useEffect(() => {
    let isLoaded = true;

    const fetchAssignments = () => {
      ApiService.getStudentsByAssignmentId(classroomId).then(response => {
        if (isLoaded) {
          setAssignments(response.data);
          setTotalAssignments(response.data.length);
        }
      }).catch(e => {
      });
    };

    fetchAssignments();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader  title={`Estudiantes Asignados ${totalAssignments}`}/>
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                Estudiante
                </TableCell>
                <TableCell>
                Fecha entregada
                </TableCell>
                <TableCell>
                Tiempo
                </TableCell>
                <TableCell>
                Puntos
                </TableCell>
                <TableCell>
                Gemas
                </TableCell>
                <TableCell>
                Respuestas Quiz
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                <TableRow
                  hover
                  key={item.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Avatar
                        className={classes.avatar_group}>
                          {item.avatar ? <img className={classes.avatarItem} src={`${PATH_URL}`+ item.avatar} onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL +"/static/images/avatars/neutral_avatar.jpeg"}} /> : <img className={classes.avatarItem} src={process.env.PUBLIC_URL +"/static/images/avatars/neutral_avatar.jpeg"} />}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                      {item.nombre} {item.apellido}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item.fecha_entregada ? Utils.formatDateShort(item.fecha_entregada) : Utils.formatDateShort(item.fecha_agregado)}
                  </TableCell>
                  <TableCell>
                    {item.tiempo ? item.tiempo : 0}
                  </TableCell>
                  <TableCell>
                    {item.puntos ? item.puntos : 0}
                  </TableCell>
                  <TableCell>
                    {item.gemas ? item.gemas : 0}
                  </TableCell>
                  <TableCell>
                    {item.respuestas_completadas ? item.respuestas_completadas : "0/0"}
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={assignments.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
      </Box>
    </Card>
  );
};

AssignmentDetailList.propTypes = {
  className: PropTypes.string,
  assignments: PropTypes.array
};

AssignmentDetailList.defaultProps = {
  assignments: []
};

export default AssignmentDetailList;
