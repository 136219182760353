import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  ListItemText,
  LinearProgress
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import { LIST_ARTBOARDS_BY_SCHOOL_AND_STUDENT } from "src/services/queries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const PATH_URL = API_URL;

const ListCompletedPuzzleStd = ({ className, ...rest }) => {
  const classes = useStyles();
  /* student id */
  let { studentId, schoolId } = useParams();
  const [assignments, setAssignments] = useState([]);
  const [totalAssignments, setTotalAssignments] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const initialRecordState = {
    nombre: "",
    apellido: "",
    puntos: 660,
    gemas: 18
  };
  const [dashboard, setDashboard] = useState(initialRecordState);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [getArts, resultArts] = useLazyQuery(
    LIST_ARTBOARDS_BY_SCHOOL_AND_STUDENT,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getArts", result);
        if (result) {
          if (result.listArtboards) {
            setAssignments(result.listArtboards);
            setTotalAssignments(result.listArtboards.length);
          }
        }
      }
    }
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, assignments.length - page * rowsPerPage);

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setDashboard(
        [...items].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setDashboard(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  const fetchAssignments = () => {
    getArts({
      variables: {
        school_id: schoolId,
        student_id: studentId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchAssignments();
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <Box alignItems="center" display="flex">
            <Typography align="left" color="textPrimary" variant="h6">
              Mostrar por:
            </Typography>
            <Button
              className={classes.sortButton}
              onClick={handleSortOpen}
              ref={sortRef}
            >
              {selectedSort}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={sortRef.current}
              className={classes.menu}
              onClose={handleSortClose}
              open={openSort}
            >
              {[
                SYSTEM_MESSAGES.MOST_RECENT,
                SYSTEM_MESSAGES.ALPHABETIC_ORDER
              ].map(option => (
                <MenuItem
                  className={classes.menuItem}
                  key={option}
                  onClick={() => handleSortSelect(option, assignments)}
                >
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Rompecabezas Realizados &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalAssignments ? totalAssignments : 0} total
            </Typography>
          </Box>
        }
      />
      {resultArts.loading && <LinearProgress />}
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rompecabezas</TableCell>
                <TableCell>Tiempo</TableCell>
                <TableCell>Puntos</TableCell>
                <TableCell>Gemas</TableCell>
                <TableCell>Respuestas Quiz</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar className={classes.avatar_group}>
                          {item.avatar ? (
                            <img
                              className={classes.avatarItem}
                              src={`${PATH_URL}` + item.avatar}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                  process.env.PUBLIC_URL +
                                  "/static/images/marilyn.png";
                              }}
                            />
                          ) : (
                            <img
                              className={classes.avatarItem}
                              src={
                                process.env.PUBLIC_URL +
                                "/static/images/marilyn.png"
                              }
                            />
                          )}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {item.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{item.time ? item.time : 0}</TableCell>
                    <TableCell>{item.points ? item.points : 0}</TableCell>
                    <TableCell>{item.gems ? item.gems : 0}</TableCell>
                    <TableCell>
                      {item.questions ? item.questions : "0"}
                      {`/`}
                      {item.quiz ? item.quiz : "0"}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={assignments.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

ListCompletedPuzzleStd.propTypes = {
  className: PropTypes.string,
  assignments: PropTypes.array.isRequired
};

ListCompletedPuzzleStd.defaultProps = {
  assignments: []
};

export default ListCompletedPuzzleStd;
