import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, colors, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  k4kContainerNoPadd: {
    marginLeft: '0px !important',
    marginRight: '0px !important'
  },
  imgAds: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%'
  }
}));

const AdsFooter = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
      <Container maxWidth={false} className={classes.k4kContainerNoPadd}>
        <Grid
          container
          spacing={3}>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}>
            <img className={classes.imgAds} alt="K4K" src={process.env.PUBLIC_URL +"/static/images/anuncios/ads_img2.png"} />
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}>
            <img className={classes.imgAds} alt="K4K" src={process.env.PUBLIC_URL +"/static/images/anuncios/ads_img3.png"} />
          </Grid>
        </Grid>
      </Container>
  );
};

AdsFooter.propTypes = {
  className: PropTypes.string
};

export default AdsFooter;
