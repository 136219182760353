import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    textAlign: "center",
    padding: theme.spacing(3)
  },
  image: {
    height: 240,
    backgroundImage: 'url("/images/undraw_empty_xct9.svg")',
    backgroundPositionX: "right",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  }
}));

const EmptyList = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.image} />
      <Typography color="textSecondary" variant="h6">
        No hay datos para mostrar por el momento...
      </Typography>
    </div>
  );
};

EmptyList.propTypes = {
  className: PropTypes.string
};

export default EmptyList;
