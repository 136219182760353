import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography
} from "@material-ui/core";
import Page from "src/components/Page";
import ListAssignments from "./ListAssignments";
import StudentInfo from "./StudentInfo";
import AssignmentProgress from "./AssignmentProgress";
import TotalPoints from "./TotalPoints";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();

  const handleClick = event => {
    event.preventDefault();
  };

  return (
    <Page className={classes.root} title="Detalle del estudiante">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className={classes.text}
              to={"/teacher/classroom/view/" + `${classroomId}`}
            >
              Estudiantes
            </Link>
            <Typography color="textPrimary">Detalle del estudiante</Typography>
          </Breadcrumbs>
        </Box>
        <StudentInfo />
        <Grid container spacing={3}>
          {/* <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          > */}
          {/* </Grid> */}
          {/* <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <AssignmentProgress />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalPoints />
          </Grid>*/}
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <ListAssignments />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
