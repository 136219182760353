import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Grid, Typography, makeStyles } from "@material-ui/core";
/* Services */
import ApiService from "src/services/ApiService";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  containerWelcome: {
    textAlign: "left",
    marginBottom: "20px"
  },
  welcomeMainText: {
    color: "#212529",
    fontSize: "24px",
    fontWeight: "500"
  },
  subtitleWelcomeText: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "400"
  }
}));

const WelcomeTeacher = ({ className, ...rest }) => {
  const classes = useStyles();
  /* teacher id */
  let teacherId = 45;
  const initialRecordState = {
    id: teacherId,
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    pais: "",
    ciudad: ""
  };
  const [user, setUser] = useState(initialRecordState);

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchTeacherDetail = () => {
      ApiService.getTeacherProfile(teacherId)
        .then(response => {
          if (isLoaded) {
            setUser(response.data);
          }
        })
        .catch(e => {
          setUser(initialRecordState);
        });
    };

    fetchTeacherDetail();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Grid className={clsx(classes.root, className)} {...rest}>
      <div className={classes.containerWelcome}>
        <Typography
          align="left"
          color="textPrimary"
          variant="body1"
          className={classes.welcomeMainText}
        >
          Hola {user.nombre}!
        </Typography>
        <Typography
          align="left"
          color="textPrimary"
          variant="body1"
          className={classes.subtitleWelcomeText}
        >
          Estas son las novedades.
        </Typography>
      </div>
    </Grid>
  );
};

WelcomeTeacher.propTypes = {
  className: PropTypes.string
};

export default WelcomeTeacher;
