import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Page from "src/components/Page";
import SchoolList from "./Schools";
/* Services */
import ApiService from "src/services/ApiService";
import { SCHOOLS_COUNT } from "src/services/queries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [totalRecords, setTotalRecords] = useState(0);

  const [getSchoolCount, resultCount] = useLazyQuery(SCHOOLS_COUNT);

  useEffect(() => {
    if (resultCount.data) {
      console.log(resultCount.data.schoolsCount);
      setTotalRecords(resultCount.data.schoolsCount);
    }
  }, [resultCount]);
  /**
   * Get All
   */
  const fetchSchools = () => {
    ApiService.getAllSchools()
      .then(response => {
        if (response) {
          setTotalRecords(response.data.length);
        } else {
          setTotalRecords(0);
        }
      })
      .catch(e => {
        setTotalRecords(0);
      });
  };

  useEffect(() => {
    let isLoaded = true;

    getSchoolCount();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Page className={classes.root} title="Colegios">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={6} mb={1}>
            <Box display="flex" justifyContent="flex-start">
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                Colegios
              </Typography>
            </Box>
            <Box
              mt={0}
              mb={1}
              display="flex"
              justifyContent="flex-start"
              alignItems="left"
            >
              <Typography align="left" color="textPrimary" variant="h6">
                {totalRecords ? totalRecords : 0} colegios
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} mb={1}>
            <Box
              mt={0}
              mb={1}
              display="flex"
              justifyContent="flex-start"
              alignItems="left"
            >
              <Typography
                align="left"
                color="textPrimary"
                variant="h6"
              ></Typography>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            href={"/admin/school/new-school"}
          >
            <AddIcon /> AGREGAR COLEGIO
          </Button>
        </Box>
        <Box mt={3}></Box>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <SchoolList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
