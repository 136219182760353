import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback
} from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Button,
  Box,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  IconButton,
  makeStyles,
  TextField,
  Grid,
  Link,
  colors,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_ADMIN_USER, UPDATE_USER_AVATAR } from "src/services/mutations";
import { GET_USER } from "src/services/queries";
import { useDropzone } from "react-dropzone";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";

import * as Yup from "yup";
import { Formik } from "formik";
import AvatarUser from "src/components/AvatarUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1"
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  image: {
    width: 60,
    borderRadius: 500
  },
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  }
}));

const DetailAdminUser = ({ className, ...rest }) => {
  const formRef = useRef();

  const classes = useStyles();
  const [user, setUser] = useState([]);
  const [totalPublicUsers, setTotalPublicUsers] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertAvatar, setOpenAlertAvatar] = useState(false);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirm: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [errorMsgAvatar, setErrorMsgAvatar] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [files, setFiles] = useState([]);
  const [imageUpload, setImageUpload] = useState("");

  let { adminUserId } = useParams();

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [getAdmins, resultAdmins] = useLazyQuery(GET_USER);
  useEffect(() => {
    if (resultAdmins.data) {
      console.log(resultAdmins.data.user);
      setUser(resultAdmins.data.user[0]);
      setValues(resultAdmins.data.user[0]);
      //setTotalPublicUsers(resultAdmins.data.usersAdmin.length);
    }
  }, [resultAdmins]);

  const [adminMutation, resultAdminMutation] = useMutation(UPDATE_ADMIN_USER, {
    onCompleted(data) {
      console.log("UPDATE_GRADE");
      console.log(data);
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
      });
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
      });
    }
  });

  const [addMutation, resultAddMutation] = useMutation(UPDATE_USER_AVATAR, {
    onCompleted(data) {
      console.log("UPDATE_USER_AVATAR ADDED");
      console.log(data);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
      });
      setOpenAlertAvatar(true);
    },
    onError(error) {
      console.log(error);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
      });
      setOpenAlertAvatar(true);
    }
  });

  /**
   * updateAdminUser
   */
  const updateAdminUser = values => {
    let isLoaded = true;

    if (values.password !== values.password_confirm) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_PASSWORD_MATCH
      });
      return;
    }

    if (
      values.first_name === "" ||
      values.last_name === "" ||
      values.email === ""
    ) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      console.log({
        id: adminUserId,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password ? values.password : ""
      });
      adminMutation({
        variables: {
          id: adminUserId,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password ? values.password : ""
        }
      });
    }
  };

  const fetchAdministrator = () => {
    getAdmins({
      variables: {
        id: adminUserId
      }
    });
  };

  const updateAvatar = () => {
    console.log(imageUpload);

    if (imageUpload.length === 0) {
      setOpenAlertAvatar(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    if (imageUpload) {
      addMutation({
        variables: {
          image: imageUpload,
          userId: parseInt(adminUserId)
        }
      });
    }
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchAdministrator();
  }, []);

  const UploadAvatar = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFiles(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileImage(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setImageUpload(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFiles([]);
      setImageUpload("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <AvatarUser only id={adminUserId} />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br>{" "}
                <Link underline="always" className={classes.textLinkUpload}>
                  cargar
                </Link>{" "}
                desde tu dispositivo
              </Typography>
              <Typography gutterBottom variant="h6">
                Dimensiones: 512px x 512px
              </Typography>
              {files.length > 0 && (
                <Fragment>
                  {files.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  if (!values) return false;

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={values}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Debe ser un correo electrónico válido")
            .required("El correo electrónico es obligatorio"),
          first_name: Yup.string().required("El nombre es requerido."),
          last_name: Yup.string().required("El apellido es requerido.")
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updateAdminUser(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Card>
              <CardHeader title="Información del administrador" />
              {resultAdminMutation.loading && <LinearProgress />}
              <Divider />
              <CardContent>
                {/* Alert Success Delete | default hide */}
                <Collapse in={openAlert}>
                  <Alert
                    severity={errorMsg.type}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {errorMsg.message}
                  </Alert>
                  <Box p={1}></Box>
                </Collapse>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Nombre"
                      name="first_name"
                      type="text"
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Apellido"
                      name="last_name"
                      type="text"
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Correo electrónico"
                      name="email"
                      type="text"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Contraseña"
                      name="password"
                      type="password"
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Confirmar contraseña"
                      name="password_confirm"
                      type="password"
                      error={Boolean(
                        touched.password_confirm && errors.password_confirm
                      )}
                      helperText={
                        touched.password_confirm && errors.password_confirm
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.password_confirm}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={
                    isSubmitting && resultAdminMutation.loading ? true : false
                  }
                >
                  GUARDAR
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
      <Box p={1}></Box>
      <Card>
        <CardHeader title="Avatar" />
        {resultAddMutation.loading && <LinearProgress />}
        <Divider />
        <CardContent>
          <Collapse in={openAlertAvatar}>
            <Alert
              severity={errorMsg.type}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlertAvatar(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMsg.message}
            </Alert>
            <Box p={1}></Box>
          </Collapse>

          <UploadAvatar title="Cargar imagen"></UploadAvatar>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="secondary" variant="contained" onClick={updateAvatar}>
            GUARDAR
          </Button>
        </Box>
      </Card>
    </div>
  );
};

DetailAdminUser.propTypes = {
  className: PropTypes.string
};

export default DetailAdminUser;
