import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Link,
  colors,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_SCHOOL } from "src/services/mutations";
import { COUNTRIES } from "src/services/queries";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px",
    margin: "auto"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  textLinkUpload: {
    color: "#0074BC"
  }
}));

const AddNewSchool = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };

  const initialRecordState = {
    nombre: "",
    correo_institucion: "",
    direccion: "",
    pais: "",
    ciudad: "",
    telefono: ""
  };

  const [initialValues, setInitialValues] = useState(initialRecordState);

  const [countries, setCountries] = useState([]);
  const [values, setValues] = useState(initialRecordState);
  const [imageUpload, setImageUpload] = useState("");
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [getCountries, resultcountries] = useLazyQuery(COUNTRIES, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getCountries");
      console.log(result);

      if (result) {
        if (result.countries) {
          setCountries(result.countries);
        }
      }
    }
  });

  const [schoolMutation, resultSchoolMutation] = useMutation(CREATE_SCHOOL, {
    onCompleted(data) {
      console.log("CREATE_SCHOOL");
      console.log(data);

      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
      });
      setValues(null);
      handleRemoveAll();

      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
      //handleRemoveAll();
    }
  });

  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles.find(f => f);
    let reader = new FileReader();

    setFiles(acceptedFiles);

    reader.readAsDataURL(file);
    reader.onload = () => {};

    if (!Utils.validateFileImage(file)) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
      });
      handleRemoveAll();
    } else {
      setImageUpload(file);
    }
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
    setImageUpload("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  /**
   * saveNewSchool
   */
  const saveNewSchool = values => {
    if (imageUpload.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    if (
      values.nombre == "" ||
      values.correo_institucion == "" ||
      values.direccion == "" ||
      values.pais == "" ||
      values.ciudad == "" ||
      values.telefono == ""
    ) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      const sendData = {
        name: values.nombre,
        email: values.correo_institucion,
        address: values.direccion,
        countries_id: parseInt(values.pais),
        city: values.ciudad,
        phone: values.telefono,
        image: imageUpload
      };
      console.log(sendData);
      schoolMutation({
        variables: sendData
      });
    }
  };

  /**
   * Get All
   */
  const fetchAllCountries = () => {
    getCountries();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAllCountries();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item lg={8} sm={6} xs={12} mb={1}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            correo_institucion: Yup.string()
              .email("Debe ser un correo electrónico válido")
              .required("El correo electrónico es obligatorio"),
            nombre: Yup.string().required("El nombre es requerido."),
            direccion: Yup.string().required("La dirección es requerida."),
            pais: Yup.string().required("El país es requerido."),
            ciudad: Yup.string().required("La ciudad es requerida."),
            telefono: Yup.string().required("El teléfono es requerido.")
          })}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            console.log(values);
            saveNewSchool(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              encType="multipart/form-data"
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader title="Información de la institución" />
                {resultSchoolMutation.loading && <LinearProgress />}
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nombre de la institución"
                        name="nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.nombre && errors.nombre)}
                        helperText={touched.nombre && errors.nombre}
                        required
                        value={values.nombre}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Correo electrónico"
                        name="correo_institucion"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.correo_institucion &&
                            errors.correo_institucion
                        )}
                        helperText={
                          touched.correo_institucion &&
                          errors.correo_institucion
                        }
                        required
                        value={values.correo_institucion}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Dirección"
                        name="direccion"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.direccion && errors.direccion)}
                        helperText={touched.direccion && errors.direccion}
                        required
                        type="text"
                        value={values.direccion}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="País"
                        name="pais"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.pais && errors.pais)}
                        helperText={touched.pais && errors.pais}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.pais}
                        variant="outlined"
                      >
                        <option key="" value=""></option>
                        {countries.map(option => (
                          <option key={option.code} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Ciudad / Provincia"
                        name="ciudad"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.ciudad && errors.ciudad)}
                        helperText={touched.ciudad && errors.ciudad}
                        required
                        type="text"
                        value={values.ciudad}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Teléfono"
                        name="telefono"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.telefono && errors.telefono)}
                        helperText={touched.telefono && errors.telefono}
                        required
                        type="text"
                        value={values.telefono}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    disabled={
                      isSubmitting && resultSchoolMutation.loading
                        ? true
                        : false
                    }
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    GUARDAR
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
      <Grid item lg={4} sm={6} xs={12} mb={1}>
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Cargar imagen" />
            <Divider />
            <CardContent>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                className={clsx({
                  [classes.dropZone]: true,
                  [classes.dragActive]: isDragActive
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div>
                  <img
                    alt="Seleccionar archivo"
                    className={classes.image}
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/ic_upload_file.png"
                    }
                  />
                </div>
                <Typography gutterBottom variant="h3">
                  Agregar archivo
                </Typography>
                <Typography
                  className={classes.info}
                  color="textSecondary"
                  variant="body1"
                >
                  Suelta los archivos aquí o haz clic en <br></br>{" "}
                  <Link underline="always" className={classes.textLinkUpload}>
                    cargar
                  </Link>{" "}
                  desde tu dispositivo
                </Typography>
                <Typography gutterBottom variant="h6">
                  Dimensiones: 450px x 450px
                </Typography>

                {files.length > 0 && (
                  <Fragment>
                    {files.map((file, i) => (
                      <Typography
                        key={i}
                        className={classes.info}
                        color="textSecondary"
                        variant="body1"
                      >
                        {file.name} - {bytesToSize(file.size)}
                      </Typography>
                    ))}
                  </Fragment>
                )}
              </Box>
            </CardContent>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};

AddNewSchool.propTypes = {
  className: PropTypes.string
};

export default AddNewSchool;
