import React, { Fragment, useState, useCallback, useRef } from "react";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Link,
  colors,
  Select,
  MenuItem,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { CREATE_AD } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  textLinkUpload: {
    color: "#0074BC"
  }
}));

const AddNewAds = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };

  const _today = moment().format("YYYY-MM-DD hh:mm:ss");
  //const _currentTime = moment().format("hh:mm:ss");

  const initialRecordState = {
    title: "",
    url: "",
    start_date: _today,
    end_date: _today,
    image: "",
    mobile_image: "",
    weight: ""
  };

  const [values, setValues] = useState(initialRecordState);
  const [imageUpload, setImageUpload] = useState("");
  const [imageUploadMovil, setImageMovil] = useState("");
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [adMutation, resultAdMutation] = useMutation(CREATE_AD, {
    onCompleted(data) {
      console.log("CREATE_AD");
      console.log(data);

      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
      });
      setValues(initialRecordState);

      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
      //setValues(initialRecordState);
    }
  });

  const handleChangeWeight = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [files, setFiles] = useState([]);
  const [files_movil, setFilesMovil] = useState([]);

  const UploadCoverAds = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFiles(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileImage(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setImageUpload(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFiles([]);
      setImageUpload("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br>{" "}
                <Link underline="always" className={classes.textLinkUpload}>
                  cargar
                </Link>{" "}
                desde tu dispositivo
              </Typography>
              {files.length > 0 && (
                <Fragment>
                  {files.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
              <Typography gutterBottom variant="h6">
                Vertical: 508 x 615 pixeles
              </Typography>
              <Typography gutterBottom variant="h6">
                Horizontal 1: 613 x 207 pixeles
              </Typography>
              <Typography gutterBottom variant="h6">
                Horizontal 2: 773 x 72 pixeles
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  const UploadCoverMovil = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFilesMovil(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileImage(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setImageMovil(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFilesMovil([]);
      setImageMovil("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br>{" "}
                <Link underline="always" className={classes.textLinkUpload}>
                  cargar
                </Link>{" "}
                desde tu dispositivo
              </Typography>
              {files_movil.length > 0 && (
                <Fragment>
                  {files_movil.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
              <Typography gutterBottom variant="h6">
                Vertical: 508 x 615 pixeles
              </Typography>
              <Typography gutterBottom variant="h6">
                Horizontal 1: 613 x 207 pixeles
              </Typography>
              <Typography gutterBottom variant="h6">
                Horizontal 2: 773 x 72 pixeles
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  /**
   * saveNewAds
   */
  const saveNewAds = values => {
    console.log("init SAVE");

    if (imageUpload.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    if (imageUploadMovil.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    if (values.start_date == "" || values.end_date == "") {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Las fechas son requeridas."
      });
    } else {
      console.log({
        title: values.title,
        url: values.url,
        start_date: values.start_date,
        end_date: values.end_date,
        image: imageUpload,
        mobile_image: imageUploadMovil,
        weight: parseInt(values.weight),
        position: parseInt(values.position)
      });
      adMutation({
        variables: {
          title: values.title,
          url: values.url,
          start_date: values.start_date,
          end_date: values.end_date,
          image: imageUpload,
          mobile_image: imageUploadMovil,
          weight: parseInt(values.weight),
          position: parseInt(values.position)
        }
      });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={8} md={6} xs={12}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={values}
          validationSchema={Yup.object().shape({
            title: Yup.string().required("El nivel es requerido."),
            url: Yup.string()
              .required("La URL es requerida.")
              .matches(
                /^(?=.{4,2048}$)((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]{1,63}(\.[a-zA-Z]{1,63}){1,5}(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
                "Introduzca una URL válida."
              ),
            position: Yup.string().required("La posición es requerida."),
            weight: Yup.number()
              .typeError("Solo se permiten números.")
              .positive("Solo número positivo.")
              .integer("Solo se permiten enteros.")
              .required("La frecuencia es requerida.")
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            saveNewAds(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              encType="multipart/form-data"
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader title="Información del ad" />
                {resultAdMutation.loading && <LinearProgress />}
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Titulo"
                        name="title"
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.title}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Url"
                        name="url"
                        error={Boolean(touched.url && errors.url)}
                        helperText={touched.url && errors.url}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.url}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        id="fecha_inicial"
                        label="Fecha de inicio"
                        name="start_date"
                        error={Boolean(touched.start_date && errors.start_date)}
                        helperText={touched.start_date && errors.start_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="date"
                        value={values.start_date}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        id="fecha_final"
                        label="Fecha final"
                        name="end_date"
                        error={Boolean(touched.end_date && errors.end_date)}
                        helperText={touched.end_date && errors.end_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="date"
                        value={values.end_date}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Posición"
                        name="position"
                        error={Boolean(touched.position && errors.position)}
                        helperText={touched.position && errors.position}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.position}
                        variant="outlined"
                      >
                        <option key={0} value={""}></option>
                        {/* 
                      1 = main vertical 
                      2 = main horizontal 
                      3 = small horizontal
                      4 = Docente 1
                      5 = Docente 2
                      6 = Docente 3 
                      7 = Docente 4 
                      8 = Docente 5 
                    */}
                        <option key={1} value={1}>
                          Vertical
                        </option>
                        <option key={2} value={2}>
                          Horizontal 1
                        </option>
                        <option key={3} value={3}>
                          Horizontal 2
                        </option>
                        <option key={4} value={4}>
                          Docente 1
                        </option>
                        <option key={5} value={5}>
                          Docente 2
                        </option>
                        <option key={6} value={6}>
                          Docente 3
                        </option>
                        <option key={7} value={7}>
                          Docente 4
                        </option>
                        <option key={8} value={8}>
                          Docente 5
                        </option>
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Peso (frecuencia)"
                        name="weight"
                        error={Boolean(touched.weight && errors.weight)}
                        helperText={touched.weight && errors.weight}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.weight}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting && resultAdMutation.loading ? true : false
                    }
                  >
                    GUARDAR
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <UploadCoverAds title="Cargar imagen"></UploadCoverAds>
          <Box p={1}></Box>
          <UploadCoverMovil title="Cargar imagen móvil"></UploadCoverMovil>
        </form>
      </Grid>
    </Grid>
  );
};

AddNewAds.propTypes = {
  className: PropTypes.string
};

export default AddNewAds;
