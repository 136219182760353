import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Box, Button, Collapse, Card, CardContent, CardHeader, Divider, Grid, TextField, makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
/* Services */
import ApiService from 'src/services/ApiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditAssignment = ({ className, ...rest }) => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();
  /* assignment id */
  let { assignmentId } = useParams();
  const [artworks, setArtworks] = useState([]);
  const [students, setStudents] = useState([]);
  const [difficulties, setDifficulties] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectObra, setObraSeleccionado] = useState({id: 0, nombre: 'Seleccione...'});
  const [selectDificultad, setDificultadSeleccionado] = useState({id: 0, dificultad: 'Seleccione...'});
  const [selectEstudiante, setEstudianteSeleccionado] = useState({id: 0, nombre: 'Todos'});
  const initialRecordState = {
    obra: 0,
    asignar_a: 0,
    dificultad: 0,
    classroom_id: classroomId,
    fecha_entrega: new Date().toLocaleString()
  };
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errors);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * updateAssignment
   */
  const updateAssignment = () => {
    let data = {
      obra: values.obra,
      dificultad: values.dificultad,
      asignar_a: values.asignar_a,
      classroom_id: classroomId,
      fecha_entrega: values.fecha_entrega+"T"+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds()+"Z",
      user_id: 1
    };

    ApiService.editAssignment(assignmentId, JSON.stringify(data)).then(response => {
      if(response){
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({"type": "success", "message": "Los datos de la asignación se han actualizado correctamente."});

        ApiService.getAssignmentsById(assignmentId).then(response => {
          if (response) {
            const initialRecordState = {
              obra: response.data.obra,
              asignar_a: response.data.asignar_a,
              dificultad: response.data.dificultad,
              classroom_id: classroomId,
              fecha_entrega: new Date().toLocaleString()
            };
            setValues(initialRecordState);
          }
        }).catch(e => {
        });
      }
    }).catch(err => {
      setSubmitted(false);
      setOpenAlert(false);
    });
  };

  /**
   * Fetch API
   */
   useEffect(() => {
    let isLoaded = true;

    const getAssignmentsById = () => {
      ApiService.getAssignmentsById(assignmentId).then(response => {
        if (isLoaded) {
          const initialRecordState = {
            obra: response.data.obra,
            asignar_a: response.data.asignar_a,
            dificultad: response.data.dificultad,
            classroom_id: classroomId,
            fecha_entrega: new Date().toLocaleString()
          };
          setValues(initialRecordState);
        }
      }).catch(e => {
        setValues(initialRecordState);
      });
    };

    const fetchgArtworks = () => {
      ApiService.getArtworks().then(response => {
        if (isLoaded) {
          setArtworks(response.data);
        }
      }).catch(e => {
      });
    };

    const fetchStudents = () => {
      ApiService.getStudentsByGroupId(1).then(response => {
        if (isLoaded) {
          setStudents(response.data);
        }
      }).catch(e => {
      });
    };

    const fetchDifficulties = () => {
      ApiService.getDifficulties().then(response => {
        if (isLoaded) {
          setDifficulties(response.data);
        }
      }).catch(e => {
      });
    };
    
    getAssignmentsById();
    fetchgArtworks();
    fetchStudents();
    fetchDifficulties();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title="Asignación"
        />
        <Divider />
        <CardContent>
          <Collapse in={openAlert}>
            <Alert severity={errorMsg.type}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
            {errorMsg.message}
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Obras"
                name="obra"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.obra}
                variant="outlined"
              >
                <option
                  key={selectObra.id}
                  value={selectObra.id}
                >
                  {selectObra.nombre}
                </option>
                {artworks.map((artwork) => (
                  <option
                    key={artwork.id}
                    value={artwork.id}
                  >
                    {artwork.autor} - {artwork.nombre}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Asignar a"
                name="asignar_a"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.asignar_a}
                variant="outlined"
              >
                <option
                  key={selectEstudiante.id}
                  value={selectEstudiante.id}
                >
                  {selectEstudiante.nombre}
                </option>
                {students.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.nombre} {option.apellido}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Dificultad"
                name="dificultad"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.dificultad}
                variant="outlined"
              >
                <option
                  key={selectDificultad.id}
                  value={selectDificultad.id}
                >
                  {selectDificultad.dificultad}
                </option>
                {difficulties.map((difficultie) => (
                  <option
                    key={difficultie.id}
                    value={difficultie.id}
                  >
                  {difficultie.dificultad}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                id="fecha_entrega"
                label="Fecha de entrega"
                name="fecha_entrega"
                onChange={handleChange}
                type="date"
                value={values.fecha_entrega}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updateAssignment}
          >
            GUARDAR
          </Button>
        </Box>
      </Card>
    </form>
  );
};

EditAssignment.propTypes = {
  className: PropTypes.string
};

export default EditAssignment;
