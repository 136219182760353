import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback
} from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PerfectScrollbar from "react-perfect-scrollbar";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Container,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Breadcrumbs,
  Menu,
  MenuItem,
  ListItemText,
  colors,
  LinearProgress
} from "@material-ui/core";
import Page from "src/components/Page";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  COUNTRIES,
  GET_ARTIST,
  GET_ARTWORKS_BY_ARTIST
} from "src/services/queries";
import { UPDATE_ARTIST } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px",
    margin: "auto"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  list: {
    maxHeight: 320
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  avatarItemBlock: {
    width: "60px",
    height: "60px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  title_head_name: {
    color: "#212529"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  },
  avatar_content_block: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  textLinkUpload: {
    color: "#0074BC"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const DetailArtist = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* artist id */
  let { artistId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    first_name: "",
    last_name: "",
    countries_id: "",
    bio: "",
    image: ""
  };

  const countriesInitialState = [
    {
      code: "CR",
      name: "Costa Rica"
    }
  ];
  const [arts, setArts] = useState([]);
  const [totalArts, setTotalArts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(initialRecordState);
  const [imageUpload, setImageUpload] = useState("");
  const [countries, setCountries] = useState(countriesInitialState);
  const [dashboard, setDashboard] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [getArtworks, resultGetArtworks] = useLazyQuery(
    GET_ARTWORKS_BY_ARTIST,
    {
      onCompleted: result => {
        if (result && result.artworks_by_artist) {
          console.log("GET_ARTWORKS_BY_ARTIST", result.artworks_by_artist);
          setArts(result.artworks_by_artist);
          setTotalArts(result.artworks_by_artist.length);
        }
      },
      onError: error => {
        console.log("error", error);
      }
    }
  );

  const [getCountries, resultcountries] = useLazyQuery(COUNTRIES, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getCountries");
      console.log(result);

      if (result) {
        if (result.countries) {
          setCountries(result.countries);
        }
      }
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [getArtist, resultArtist] = useLazyQuery(GET_ARTIST);
  const [artistMutation, resultArtistMutation] = useMutation(UPDATE_ARTIST, {
    onCompleted(data) {
      console.log("UPDATE_ARTIST");
      console.log(data);
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
      });
      fetchArtistDetail();
      handleRemoveAll();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
      });
      fetchArtistDetail();
      //handleRemoveAll();
    }
  });

  useEffect(() => {
    if (resultArtist.data) {
      console.log(resultArtist.data.artist);
      setValues(resultArtist.data.artist);
      setDashboard(resultArtist.data.artist);
    }
  }, [resultArtist]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, arts.length - page * rowsPerPage);

  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles.find(f => f);

    setFiles(acceptedFiles);
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {};

    if (!Utils.validateFileImage(file)) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
      });
      handleRemoveAll();
    } else {
      setImageUpload(file);
    }
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
    setImageUpload("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setArts(
        [...items].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setArts(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  /**
   * saveUpdateArtist
   */
  const saveUpdateArtist = values => {
    console.log({
      id: parseInt(artistId),
      first_name: values.first_name,
      last_name: values.last_name,
      countries_id: parseInt(values.countries_id),
      bio: values.bio,
      currentImage: values.image,
      image: imageUpload ? imageUpload : null
    });
    artistMutation({
      variables: {
        id: parseInt(artistId),
        first_name: values.first_name,
        last_name: values.last_name,
        countries_id: parseInt(values.countries_id),
        bio: values.bio,
        currentImage: values.image,
        image: imageUpload ? imageUpload : null
      }
    });
  };

  const fetchArtistDetail = () => {
    getArtist({
      variables: {
        id: artistId
      }
    });
  };

  const fetchArtsByArtist = () => {
    getArtworks({
      variables: {
        id: artistId
      }
    });
  };

  /**
   * Get All
   */
  const fetchAllCountries = () => {
    getCountries();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchArtistDetail();
    fetchArtsByArtist();
    fetchAllCountries();
  }, []);

  if (!values) return null;

  return (
    <Page className={classes.root} title="Detalle del artista">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.text} to={"/admin/arts"}>
              Arte
            </Link>
            <Link className={classes.text} to={"/admin/arts/1"}>
              Artistas
            </Link>
            <Typography color="textPrimary">Detalle del artista</Typography>
          </Breadcrumbs>
        </Box>
        <Grid
          className={clsx(classes.root, className)}
          {...rest}
          container
          spacing={3}
        >
          <Grid item lg={4} sm={6} xs={12} mb={1}>
            <Card>
              <CardContent>
                <Grid container justify="space-between" spacing={3}>
                  <Grid item>
                    <Typography
                      className={classes.title_head_name}
                      gutterBottom
                      variant="h2"
                    >
                      {dashboard.first_name} {dashboard.last_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatar_content_block}>
                      <img
                        className={classes.avatarItemBlock}
                        src={dashboard.image}
                        width={90}
                        height={90}
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src =
                            process.env.PUBLIC_URL +
                            "/static/images/neutral_avatar.jpeg";
                        }}
                      />
                    </Avatar>
                  </Grid>
                </Grid>
                <Box mt={0} display="flex" alignItems="center">
                  <Typography
                    className={classes.subtitle_text_card}
                    color="textPrimary"
                    variant="caption"
                  >
                    {dashboard.pais_full}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
            <Box p={1}></Box>
            <Card>
              <CardHeader title="Cargar imagen" />
              <Divider />
              <CardContent>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  className={clsx({
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive
                  })}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div>
                    <img
                      alt="Seleccionar archivo"
                      className={classes.image}
                      src={
                        process.env.PUBLIC_URL +
                        "/static/images/ic_upload_file.png"
                      }
                    />
                  </div>
                  <Typography gutterBottom variant="h3">
                    Agregar archivo
                  </Typography>
                  <Typography
                    className={classes.info}
                    color="textSecondary"
                    variant="body1"
                  >
                    Suelta los archivos aquí o haz clic en <br></br> cargar{" "}
                    desde tu dispositivo
                  </Typography>
                  {files.length > 0 && (
                    <Fragment>
                      {files.map((file, i) => (
                        <Typography
                          key={i}
                          className={classes.info}
                          color="textSecondary"
                          variant="body1"
                        >
                          {file.name} - {bytesToSize(file.size)}
                        </Typography>
                      ))}
                    </Fragment>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} sm={6} xs={12} mb={1}>
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={values}
              validationSchema={Yup.object().shape({
                first_name: Yup.string().required("El nombre es requerido."),
                last_name: Yup.string().required("El apellido es requerido."),
                countries_id: Yup.string().required("El país es requerido."),
                bio: Yup.string().required("La biografía es requerida.")
              })}
              onSubmit={values => {
                saveUpdateArtist(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <Card>
                    <CardHeader title="Información del artista" />
                    <Divider />
                    <CardContent>
                      <Collapse in={openAlert}>
                        <Alert
                          severity={errorMsg.type}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenAlert(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsg.message}
                        </Alert>
                        <Box p={1}></Box>
                      </Collapse>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Nombre"
                            name="first_name"
                            error={Boolean(
                              touched.first_name && errors.first_name
                            )}
                            helperText={touched.first_name && errors.first_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.first_name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Apellido"
                            name="last_name"
                            error={Boolean(
                              touched.last_name && errors.last_name
                            )}
                            helperText={touched.last_name && errors.last_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.last_name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="País"
                            name="countries_id"
                            error={Boolean(
                              touched.countries_id && errors.countries_id
                            )}
                            helperText={
                              touched.countries_id && errors.countries_id
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.countries_id}
                            variant="outlined"
                          >
                            <option key={""} value={""}></option>
                            {countries.map(option => (
                              <option key={option.code} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Biografia"
                            name="bio"
                            error={Boolean(touched.bio && errors.bio)}
                            helperText={touched.bio && errors.bio}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            type="text"
                            value={values.bio}
                            variant="outlined"
                            multiline
                            rows={4}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={
                          isSubmitting && resultArtistMutation.loading
                            ? true
                            : false
                        }
                      >
                        GUARDAR
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
          <Box p={3}></Box>
          <Grid item lg={12} sm={12} xs={12} mb={1}>
            <Card>
              <CardHeader
                action={
                  <Box alignItems="center" display="flex">
                    <Typography align="left" color="textPrimary" variant="h6">
                      Mostrar por:
                    </Typography>
                    <Button
                      className={classes.sortButton}
                      onClick={handleSortOpen}
                      ref={sortRef}
                    >
                      {selectedSort}
                      <ArrowDropDownIcon />
                    </Button>
                    <Menu
                      anchorEl={sortRef.current}
                      className={classes.menu}
                      onClose={handleSortClose}
                      open={openSort}
                    >
                      {[
                        SYSTEM_MESSAGES.MOST_RECENT,
                        SYSTEM_MESSAGES.ALPHABETIC_ORDER
                      ].map(option => (
                        <MenuItem
                          className={classes.menuItem}
                          key={option}
                          onClick={() => handleSortSelect(option, arts)}
                        >
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                }
                title={
                  <Box display="flex">
                    <Typography
                      className={classes.card_header_title}
                      variant={"h6"}
                      gutterBottom
                    >
                      Obras &nbsp;
                    </Typography>
                    <Typography
                      className={classes.card_subtitle_total}
                      variant={"h6"}
                      gutterBottom
                    >
                      {totalArts ? totalArts : 0} total
                    </Typography>
                  </Box>
                }
              />
              {resultGetArtworks.loading && <LinearProgress />}
              <Divider />
              <PerfectScrollbar>
                <Box minWidth={800}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Obra</TableCell>
                        <TableCell>Año</TableCell>
                        <TableCell>Fecha Agregada</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {arts
                        /*.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )*/
                        .map(item => (
                          <TableRow hover key={item.id}>
                            <TableCell>
                              <Box alignItems="center" display="flex">
                                <Avatar className={classes.avatar_group}>
                                  {item.thumbnail ? (
                                    <img
                                      className={classes.avatarItem}
                                      src={item.thumbnail}
                                      onError={e => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          process.env.PUBLIC_URL +
                                          "/static/images/neutral_avatar.jpeg";
                                      }}
                                    />
                                  ) : (
                                    <img
                                      className={classes.avatarItem}
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/static/images/neutral_avatar.jpeg"
                                      }
                                    />
                                  )}
                                </Avatar>
                                <Typography color="textPrimary" variant="body1">
                                  {item.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>{item.year ? item.year : ""}</TableCell>
                            <TableCell>
                              {item.createdAt
                                ? Utils.formatDateShort(item.createdAt)
                                : "-"}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={4} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={arts.length}
                onChangePage={handlePageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
              />
              <Box display="flex" justifyContent="flex-end" p={2}></Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

DetailArtist.propTypes = {
  className: PropTypes.string
};

export default DetailArtist;
