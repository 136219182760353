import { gql } from "@apollo/client";

export const SCHOOL_LIST = gql`
  query {
    schools {
      id
      name
      countries_id
      address
      city
      user {
        email
        id
        age
      }
    }
  }
`;

export const SCHOOL_BY_ID = gql`
  query($id: ID!) {
    school(id: $id) {
      id
      name
      email
      school_code
      address
      countries_id
      city
      phone
      image
    }
  }
`;

export const SCHOOL_OVERVIEW = gql`
  query {
    schoolsOverview {
      id
      name
      students
      teachers
      image
      classGroup
      createdAt
    }
  }
`;

export const SCHOOLS_COUNT = gql`
  query {
    schoolsCount
  }
`;

export const SCHOOLS_COUNT_BY_ID_AND_ROLE = gql`
  query($school_id: ID!, $roleId: Int!) {
    userCountBySchoolId(school_id: $school_id, roleId: $roleId)
  }
`;

export const STUDENTS_COUNT = gql`
  query {
    students: userCount(roleId: 3)
  }
`;

export const TEACHERS_COUNT = gql`
  query {
    teachers: userCount(roleId: 2)
  }
`;

export const PUBLIC_COUNT = gql`
  query {
    public: userCount(roleId: 4)
  }
`;

export const LEVELS = gql`
  query {
    levels {
      id
      name
      description
      pieces
      points
      gems
      createdAt
    }
  }
`;

export const LEVEL_SHOW = gql`
  query($id: ID!) {
    level(id: $id) {
      id
      name
      description
      pieces
      points
      gems
      createdAt
    }
  }
`;

export const GRADES = gql`
  query {
    grades {
      id
      name
      gradeId
      createdAt
    }
  }
`;

export const GRADE_SHOW = gql`
  query($id: ID!) {
    grade(id: $id) {
      id
      name
      gradeId
    }
  }
`;

export const GROUPS = gql`
  query {
    groups {
      id
      name
      groupId
      createdAt
    }
  }
`;

export const GROUP_SHOW = gql`
  query($id: ID!) {
    group(id: $id) {
      id
      name
      groupId
    }
  }
`;

export const ACADEMIC_LEVELS = gql`
  query {
    AcademicLevels {
      id
      level
      detail
      createdAt
    }
  }
`;

export const ACADEMIC_LEVEL_SHOW = gql`
  query($id: ID!) {
    AcademicLevel(id: $id) {
      id
      level
      detail
      createdAt
    }
  }
`;

export const ADMIN_USERS = gql`
  query {
    usersAdmin {
      id
      email
      first_name
      last_name
      age
      createdAt
      updatedAt
      addedBy
    }
  }
`;

export const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      first_name
      last_name
      email
      id
      age
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query($id: ID!) {
    user(id: $id) {
      first_name
      last_name
      email
      id
    }
  }
`;

export const GET_TECHNIQUES = gql`
  query {
    techniques {
      id
      name
      description
      artworks_count
      createdAt
    }
  }
`;

export const GET_TECHNIQUE = gql`
  query($id: ID!) {
    technique(id: $id) {
      id
      name
      description
      createdAt
    }
  }
`;

export const GET_STYLES = gql`
  query {
    styles {
      id
      name
      description
      createdAt
      artworks_count
    }
  }
`;

export const GET_STYLE = gql`
  query($id: ID!) {
    style(id: $id) {
      id
      name
      description
      createdAt
    }
  }
`;

export const GET_ARTISTS = gql`
  query {
    artists {
      bio
      id
      first_name
      last_name
      countries_id
      country
      image
      createdAt
    }
  }
`;

export const GET_ARTIST = gql`
  query($id: ID!) {
    artist(id: $id) {
      bio
      first_name
      last_name
      countries_id
      image
      createdAt
      id
    }
  }
`;

export const GET_ARTWORKS = gql`
  query {
    artworks {
      id
      artists_id
      styles_id
      techniques_id
      name
      year
      description
      story
      image
      thumbnail
      audio
      priority
      createdAt
    }
  }
`;

export const GET_ARTWORK_SHOP = gql`
  query($id: ID!) {
    shopArtwork(id: $id) {
      id
      artists_id
      styles_id
      techniques_id
      name
      year
      description
      story
      image
      thumbnail
      audio
      priority
      shop
      gems
      available
      createdAt
    }
  }
`;

export const ALL_ARTWORKS = gql`
  query {
    allArtworks {
      id
      artists_id
      styles_id
      techniques_id
      name
      year
      description
      story
      image
      thumbnail
      audio
      priority
      shop
      gems
      available
      createdAt
    }
  }
`;

export const GET_SHOP_ARTWORKS = gql`
  query {
    shopArtworks {
      id
      artists_id
      styles_id
      techniques_id
      name
      year
      description
      story
      image
      thumbnail
      audio
      priority
      createdAt
      gems
    }
  }
`;

export const GET_SETTINGS = gql`
  query {
    artworks: settings_artworks {
      id
      name
    }

    store: settings_store {
      id
      name
    }
  }
`;

export const GET_ADS = gql`
  query {
    ads {
      id
      title
      start_date
      end_date
      image
      mobile_image
      createdAt
      url
      weight
    }
  }
`;

export const GET_AD = gql`
  query($id: ID!) {
    ad(id: $id) {
      id
      title
      start_date
      end_date
      image
      mobile_image
      createdAt
      url
      weight
      position
    }
  }
`;

export const GET_AD_IMPRESSIONS = gql`
  query {
    adsImpressions {
      id
      ads_id
      user_ip
    }
  }
`;

export const GET_ADS_CLICK = gql`
  query {
    adsClick {
      id
      ads_id
      user_ip
    }
  }
`;

export const GET_SCHOOL_TEACHER_LIST = gql`
  query($school_id: ID!) {
    schoolTeachersList(school_id: $school_id) {
      id
      first_name
      last_name
      classGroup
      students
      assignments
    }
  }
`;

export const GET_TEACHERS = gql`
  query {
    teachers {
      id
      first_name
      last_name
      email
    }
  }
`;

export const GET_SCHOOL_ACADEMIC_LEVELS = gql`
  query {
    schoolLevels {
      school_id
      academic_level_id
      createdAt
    }
  }
`;

export const GET_SCHOOL_ACADEMIC_LEVEL = gql`
  query($school_id: ID!) {
    schoolLevel(school_id: $school_id) {
      school_id
      academic_level_id
      createdAt
    }
  }
`;

export const GET_SCHOOL_GRADES = gql`
  query($school_id: ID!) {
    schoolGrades(school_id: $school_id) {
      school_id
      grade_id
      academic_level_id
      createdAt
    }
  }
`;

export const GET_SCHOOL_GROUPS = gql`
  query($school_id: ID!) {
    schoolGroups(school_id: $school_id) {
      school_id
      grade_id
      group_id
      createdAt
      name
    }
  }
`;

export const GET_SCHOOL_GRADES_NAMES = gql`
  query($school_id: ID!) {
    schoolGradesNames(school_id: $school_id) {
      school_id
      grade_id
      academic_level_id
      name
      createdAt
    }
  }
`;

export const GET_SCHOOL_ACADEMIC_LEVEL_WITH_GRADES = gql`
  query($school_id: ID!) {
    schoolLevel_withGrades(school_id: $school_id) {
      id
      level
      school_id
    }
  }
`;

export const GET_SCHOOL_ACADEMIC_LEVELS_BY_SCHOOL = gql`
  query($school_id: ID!) {
    schoolAcademicLevels(school_id: $school_id) {
      school_id
      academic_level_id
      level_id
      createdAt
    }
  }
`;

export const GET_SCHOOL_CLASS_GROUPS = gql`
  query($school_id: ID!) {
    schoolClassGroups(school_id: $school_id) {
      id
      userId
      group_name
      grade_name
      school_id
      grade_id
      group_id
      students
      assignments
      createdAt
    }
  }
`;

export const GET_SCHOOL_CLASS_GROUPS_BY_ID = gql`
  query($id: ID!) {
    schoolClassGroupsById(id: $id) {
      id
      userId
      group_name
      grade_name
      school_id
      grade_id
      group_id
      createdAt
    }
  }
`;

export const GET_PUBLIC_USERS_LIST = gql`
  query {
    publicUsersDashboardList {
      id
      first_name
      last_name
      age
      gems
      points
      ranking
      email
      createdAt
    }
  }
`;

export const GET_SAVE_SETTINGS = gql`
  query {
    settings {
      id
      artworks_id
      store_id
      createdAt
    }
  }
`;

export const GET_ARTWORK = gql`
  query($id: ID!) {
    artwork(id: $id) {
      id
      artists_id
      styles_id
      techniques_id
      name
      year
      description
      story
      image
      thumbnail
      audio
      priority
      createdAt
    }
  }
`;

export const GET_CURRENT_QUESTION = gql`
  query($artboard_id: ID!) {
    question: admin_question(artboard_id: $artboard_id) {
      id
      artboard_id
      question
      type
      question1
      question2
      question3
      answer
      order
      createdAt
    }
  }
`;

export const COUNTRIES = gql`
  query {
    countries {
      id
      code
      name
    }
  }
`;

export const GET_AVATARS = gql`
  query {
    avatars {
      id
      title
      gems
      available
      priority
      createdAt
      image
    }
  }
`;

export const GET_AVATAR = gql`
  query($id: ID!) {
    avatar(id: $id) {
      id
      title
      gems
      available
      priority
      createdAt
      image
    }
  }
`;

export const ME = gql`
  query {
    me {
      id
      first_name
      last_name
      role {
        name
      }
    }
  }
`;

export const STUDENTS_BY_SCHOOL = gql`
  query($school_id: ID!) {
    allStudentsBySchool(school_id: $school_id) {
      id
      first_name
      last_name
      age
      assignment
      delivery
      pending
      overdue
      overdue
      points
      gems
    }
  }
`;

export const COUNT_STUDENTS_BY_TEACHER_AND_SCHOOL = gql`
  query($school_id: ID!, $userId: ID!) {
    students: countClassGroupByTeacherAndSchool(
      school_id: $school_id
      userId: $userId
    )
  }
`;

export const GET_LIST_CLASS_GROUP_BY_TEACHER_AND_SCHOOL = gql`
  query($school_id: ID!, $userId: ID!) {
    classGroup: listClassGroupByTeacherAndSchool(
      school_id: $school_id
      userId: $userId
    ) {
      id
      name
      school_id
      school_id
      students
      assignments
      createdAt
    }
  }
`;

export const COUNT_STUDENTS_BY_SCHOOL_AND_CLASSGROUP = gql`
  query($school_id: ID!, $classGroup_id: ID!) {
    countStudents: countStudentsBySchoolAndClassGroup(
      school_id: $school_id
      classGroup_id: $classGroup_id
    )
  }
`;

export const LIST_STUDENTS_BY_SCHOOL_AND_CLASSGROUP = gql`
  query($school_id: ID!, $classGroup_id: ID!) {
    listStudents: listStudentsBySchoolAndClassGroup(
      school_id: $school_id
      classGroup_id: $classGroup_id
    ) {
      id
      first_name
      last_name
      assignments
      points
      gems
    }
  }
`;

export const LIST_ASSIGNMENT_BY_SCHOOL_AND_CLASSGROUP = gql`
  query($school_id: ID!, $classGroup_id: ID!) {
    listAssignments: listAssignmentsBySchoolAndClassGroup(
      school_id: $school_id
      classGroup_id: $classGroup_id
    ) {
      id
      name
      delivery_date
      points
      gems
      image
      thumbnail
    }
  }
`;

export const LIST_ARTBOARDS_BY_SCHOOL_AND_STUDENT = gql`
  query($school_id: ID, $student_id: ID!) {
    listArtboards: listArtboardsBySchoolAndStudent(
      school_id: $school_id
      student_id: $student_id
    ) {
      id
      name
      time
      quiz
      points
      gems
      questions
    }
  }
`;

export const GET_USER_AVATAR = gql`
  query($userId: Int!) {
    getAvatarById(userId: $userId) {
      id
      userId
      image
      active
      createdAt
      info
    }
  }
`;

export const GET_ALL_PG = gql`
  query {
    getAllPG {
      id
      userId
      points
      gems
      createdAt
    }
  }
`;

export const GET_PG = gql`
  query($userId: ID!) {
    getPGByID(userId: $userId) {
      id
      userId
      points
      gems
      createdAt
    }
  }
`;

export const GET_STUDENT_ASSIGNMENT_PERCENT = gql`
  query($school_id: ID!, $student_id: ID!) {
    total_student_assignment_percent(
      school_id: $school_id
      student_id: $student_id
    ) {
      total
      total_completed
      percent
    }
  }
`;

export const GET_CG_ASSIGNMENT_PERCENT = gql`
  query($school_id: ID!, $class_group_id: ID!) {
    total_classgroup_assignment_percent(
      school_id: $school_id
      class_group_id: $class_group_id
    ) {
      total
      total_completed
      percent
    }
  }
`;

export const GET_TOTAL_POINTS_BY_CG = gql`
  query($classGroup_id: ID!) {
    getPointsByClassGroup(classGroup_id: $classGroup_id)
  }
`;

export const GET_ARTWORKS_BY_TECHNIQUE = gql`
  query($id: ID!) {
    artworks_by_technique(id: $id) {
      id
      image
      thumbnail
      name
      first_name
      last_name
      year
      questions
      createdAt
    }
  }
`;

export const GET_ARTWORKS_BY_STYLE = gql`
  query($id: ID!) {
    artworks_by_style(id: $id) {
      id
      image
      thumbnail
      name
      first_name
      last_name
      year
      questions
      createdAt
    }
  }
`;

export const GET_ARTWORKS_BY_ARTIST = gql`
  query($id: ID!) {
    artworks_by_artist(id: $id) {
      id
      image
      thumbnail
      name
      first_name
      last_name
      year
      questions
      createdAt
    }
  }
`;

export const GET_ALL_ARTWORKS = gql`
  query {
    getAllArtworks: artworks {
      id
      image
      thumbnail
      name
      first_name
      last_name
      year
      questions
      createdAt
    }
  }
`;

export const GET_COUNT_ADS = gql`
  query {
    impressions: adsCountImpressions
    clicks: adsCountClick
  }
`;

export const GET_TEACHER = gql`
  query($id: ID!) {
    getTeacher(id: $id) {
      id
      first_name
      last_name
      email
      createdAt
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query {
    notifications {
      id
      userId
      readIt
      message
      link
      createdAt
    }
  }
`;
