import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography
} from "@material-ui/core";
import Page from "src/components/Page";
import EditSchoolDifficulty from "./EditSchoolDifficulty";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const DashboardEditSchoolDifficulty = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Detalle de la dificultad">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.text} to={"/admin/system"}>
              Sistema
            </Link>
            <Link className={classes.text} to={"/admin/system/3"}>
              Dificultades
            </Link>
            <Typography color="textPrimary">Editar Dificultad</Typography>
          </Breadcrumbs>
        </Box>
        <Box mb={2}>
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            Editar Dificultad
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <EditSchoolDifficulty />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardEditSchoolDifficulty;
