import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Box,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import Utils from "src/utils/utils";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    borderRadius: "10px"
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  }
}));

const PATH_URL = API_URL;

const AssignmentsList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [assignments, setAssignments] = useState([]);
  const [totalAssignments, setTotalAssignments] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const moreRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  /* Id classroom */
  let { classroomId } = useParams();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, assignments.length - page * rowsPerPage);

  const handleClickMenuActions = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenueActionsClose = () => {
    setAnchorEl(null);
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchAssignments = () => {
      ApiService.getAssignmentsByGroupId(classroomId)
        .then(response => {
          if (isLoaded) {
            setAssignments(response.data);
            setTotalAssignments(response.data.length);
          }
        })
        .catch(e => {});
    };

    fetchAssignments();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * deleteAssignment
   */
  const deleteAssignment = id => {
    ApiService.deleteAssignment(id)
      .then(response => {
        if (response) {
          setOpenAlert(true);
          setAssignments(
            assignments.filter(item => item.id !== itemSelected.id)
          );
          openCloseModalDelete();
        }
      })
      .catch(err => {
        setOpenAlert(false);
        openCloseModalDelete();
      });
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteAssignment(itemSelected && itemSelected.id)}
            variant="contained"
            color="primary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {/* Modal Delete */}
      <Modal open={modalEliminar} onClose={openCloseModalDelete}>
        {bodyModalDialogConfirmDelete}
      </Modal>
      <CardHeader
        title={`Asignaciones ${totalAssignments}`}
        subheader="total"
      />
      {/* Alert Success Delete | default hide */}
      <CardContent>
        <Collapse in={openAlert}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            La asignación se ha eliminado correctamente.
          </Alert>
          <Box p={1}></Box>
        </Collapse>
      </CardContent>
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Asignación</TableCell>
                <TableCell>Fecha de entrega</TableCell>
                <TableCell>Asignadas</TableCell>
                <TableCell>Entregadas</TableCell>
                <TableCell>Pendientes</TableCell>
                <TableCell>Vencidas</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar className={classes.avatar_group}>
                          {item.avatar ? (
                            <img
                              className={classes.avatarItem}
                              src={`${PATH_URL}` + item.avatar}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                  process.env.PUBLIC_URL +
                                  "/static/images/marilyn.png";
                              }}
                            />
                          ) : (
                            <img
                              className={classes.avatarItem}
                              src={
                                process.env.PUBLIC_URL +
                                "/static/images/marilyn.png"
                              }
                              alt=""
                            />
                          )}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          <Link
                            to={
                              "/teacher/classroom/" +
                              `${classroomId}` +
                              "/assignment/detail/" +
                              `${item.id}`
                            }
                          >
                            {item.nombre}
                          </Link>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {Utils.formatDateShort(item.fecha_entrega)}
                    </TableCell>
                    <TableCell>
                      {item.total_asignaciones ? item.total_asignaciones : 0}
                    </TableCell>
                    <TableCell>
                      {item.entregadas ? item.entregadas : 0}
                    </TableCell>
                    <TableCell>
                      {item.pendientes
                        ? item.pendientes
                        : item.total_asignaciones}
                    </TableCell>
                    <TableCell>{item.vencidas ? item.vencidas : 0}</TableCell>
                    <TableCell>
                      <Link
                        to={
                          "/teacher/classroom/" +
                          `${classroomId}` +
                          "/assignment/edit/" +
                          `${item.id}`
                        }
                      >
                        <IconButton edge="end" size="small">
                          <Edit />
                        </IconButton>
                      </Link>
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => selectRecordToDelete(item, "Eliminar")}
                      >
                        <Delete />
                      </IconButton>
                      {/* <Tooltip title="Más opciones">
                      <IconButton
                        aria-controls="menu-actions"
                        aria-haspopup="true"
                        edge="end"
                        size="small"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip> */}
                      <Link
                        to={
                          "/teacher/classroom/" +
                          `${classroomId}` +
                          "/assignment/detail/" +
                          `${item.id}`
                        }
                      >
                        <IconButton edge="end" size="small">
                          <ArrowForwardIcon />
                        </IconButton>
                      </Link>
                      <Menu
                        id="menu-actions"
                        key={item.id}
                        anchorEl={anchorEl}
                        keepMounted
                        onClose={handleMenueActionsClose}
                        open={Boolean(anchorEl)}
                      >
                        <MenuItem>Editar</MenuItem>
                        <MenuItem>Eliminar</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={assignments.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

AssignmentsList.propTypes = {
  className: PropTypes.string,
  assignments: PropTypes.array
};

AssignmentsList.defaultProps = {
  assignments: []
};

export default AssignmentsList;
