import { gql } from "@apollo/client";

export const CREATE_SCHOOL = gql`
  mutation(
    $email: String!
    $name: String!
    $address: String!
    $countries_id: Int!
    $city: String!
    $phone: String!
    $image: FileUpload
  ) {
    createSchool(
      email: $email
      name: $name
      address: $address
      countries_id: $countries_id
      city: $city
      phone: $phone
      image: $image
    ) {
      id
      name
      email
      address
      countries_id
      city
      phone
      school_code
      image
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  mutation(
    $email: String!
    $name: String!
    $address: String!
    $countries_id: Int!
    $city: String!
    $phone: String!
    $image: FileUpload
    $id: ID!
    $currentImage: String!
  ) {
    updateSchool(
      email: $email
      name: $name
      address: $address
      countries_id: $countries_id
      city: $city
      phone: $phone
      id: $id
      image: $image
      currentImage: $currentImage
    ) {
      id
      name
      email
      address
      countries_id
      city
      phone
      school_code
      image
    }
  }
`;

export const DELETE_SCHOOL = gql`
  mutation($id: ID!) {
    deleteSchool(id: $id)
  }
`;

export const CREATE_LEVEL = gql`
  mutation(
    $name: String!
    $description: String
    $pieces: Int
    $points: Int
    $gems: Int
  ) {
    createLevel(
      name: $name
      description: $description
      pieces: $pieces
      points: $points
      gems: $gems
    ) {
      id
      name
      description
      pieces
      points
      gems
      createdAt
    }
  }
`;

export const DELETE_LEVEL = gql`
  mutation($id: ID!) {
    deleteLevel(id: $id)
  }
`;

export const UPDATE_LEVEL = gql`
  mutation(
    $id: ID!
    $name: String!
    $description: String
    $pieces: Int
    $points: Int
    $gems: Int
  ) {
    updateLevel(
      id: $id
      name: $name
      description: $description
      pieces: $pieces
      points: $points
      gems: $gems
    ) {
      id
      name
      description
      pieces
      points
      gems
    }
  }
`;

export const CREATE_GRADE = gql`
  mutation($name: String!) {
    createGrade(name: $name) {
      id
      name
      gradeId
    }
  }
`;

export const DELETE_GRADE = gql`
  mutation($id: ID!) {
    deleteGrade(id: $id)
  }
`;

export const UPDATE_GRADE = gql`
  mutation($name: String!, $id: ID!) {
    updateGrade(name: $name, id: $id) {
      id
      name
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation($name: String!) {
    createGroup(name: $name) {
      id
      name
      groupId
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation($id: ID!) {
    deleteGroup(id: $id)
  }
`;

export const UPDATE_GROUP = gql`
  mutation($name: String!, $id: ID!) {
    updateGroup(name: $name, id: $id) {
      id
      name
    }
  }
`;

export const CREATE_ACADEMIC_LEVEL = gql`
  mutation($level: String!, $detail: String) {
    createAcademicLevel(level: $level, detail: $detail) {
      id
      level
      detail
    }
  }
`;

export const DELETE_ACADEMIC_LEVEL = gql`
  mutation($id: ID!) {
    deleteAcademicLevel(id: $id)
  }
`;

export const UPDATE_ACADEMIC_LEVEL = gql`
  mutation($id: ID!, $level: String!, $detail: String) {
    updateAcademicLevel(id: $id, level: $level, detail: $detail) {
      id
      level
      detail
    }
  }
`;

export const CREATE_ADMIN_USER = gql`
  mutation(
    $email: String!
    $password: String!
    $role: Int!
    $first_name: String
    $last_name: String
  ) {
    createAdmin(
      email: $email
      password: $password
      role: $role
      first_name: $first_name
      last_name: $last_name
    ) {
      token
      message
    }
  }
`;

export const UPDATE_ADMIN_USER = gql`
  mutation(
    $id: ID!
    $email: String!
    $password: String!
    $first_name: String
    $last_name: String
  ) {
    updateAdmin(
      id: $id
      email: $email
      password: $password
      first_name: $first_name
      last_name: $last_name
    )
  }
`;

export const DELETE_USER = gql`
  mutation($id: ID!) {
    deleteAdminUser(id: $id)
  }
`;

export const CREATE_TECHNIQUE = gql`
  mutation($name: String!, $description: String!) {
    createTechnique(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const UPDATE_TECHNIQUE = gql`
  mutation($id: ID!, $name: String!, $description: String!) {
    updateTechnique(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const DELETE_TECHNIQUE = gql`
  mutation($id: ID!) {
    deleteTechnique(id: $id)
  }
`;

export const CREATE_STYLE = gql`
  mutation($name: String!, $description: String!) {
    createStyle(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const UPDATE_STYLE = gql`
  mutation($id: ID!, $name: String!, $description: String!) {
    updateStyle(id: $id, name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const DELETE_STYLE = gql`
  mutation($id: ID!) {
    deleteStyle(id: $id)
  }
`;

export const CREATE_ARTIST = gql`
  mutation(
    $first_name: String!
    $last_name: String
    $countries_id: Int!
    $bio: String
    $image: FileUpload
  ) {
    createArtist(
      first_name: $first_name
      last_name: $last_name
      countries_id: $countries_id
      bio: $bio
      image: $image
    ) {
      first_name
      last_name
      countries_id
      bio
      image
      createdAt
    }
  }
`;

export const UPDATE_ARTIST = gql`
  mutation(
    $first_name: String!
    $last_name: String
    $countries_id: Int!
    $bio: String
    $currentImage: String
    $image: FileUpload
    $id: ID!
  ) {
    updateArtist(
      id: $id
      first_name: $first_name
      last_name: $last_name
      countries_id: $countries_id
      bio: $bio
      currentImage: $currentImage
      image: $image
    ) {
      id
      first_name
      last_name
      countries_id
      bio
      image
      createdAt
    }
  }
`;

export const DELETE_ARTIST = gql`
  mutation($id: ID!) {
    deleteArtist(id: $id)
  }
`;

export const CREATE_ARTWORK = gql`
  mutation(
    $artists_id: Int!
    $styles_id: Int!
    $techniques_id: Int!
    $name: String!
    $year: Int
    $description: String
    $story: String
    $image: FileUpload
    $thumbnail: FileUpload
    $audio: FileUpload
    $priority: Int
    $gems: Int
    $available: Int
  ) {
    createArtworks(
      artists_id: $artists_id
      styles_id: $styles_id
      techniques_id: $techniques_id
      name: $name
      year: $year
      description: $description
      story: $story
      image: $image
      thumbnail: $thumbnail
      audio: $audio
      priority: $priority
      gems: $gems
      available: $available
    ) {
      id
      artists_id
      styles_id
      techniques_id
      name
      year
      description
      story
      image
      thumbnail
      audio
      priority
      gems
      available
      createdAt
    }
  }
`;

export const UPDATE_ARTWORK = gql`
  mutation(
    $artists_id: Int!
    $styles_id: Int!
    $techniques_id: Int!
    $name: String!
    $year: Int
    $description: String
    $story: String
    $image: FileUpload
    $thumbnail: FileUpload
    $audio: FileUpload
    $priority: Int
    $id: ID!
    $currentImage: String
    $currentThumb: String
    $currentAudio: String
    $gems: Int
    $available: Int
  ) {
    updateArtworks(
      id: $id
      artists_id: $artists_id
      styles_id: $styles_id
      techniques_id: $techniques_id
      name: $name
      year: $year
      description: $description
      story: $story
      image: $image
      thumbnail: $thumbnail
      audio: $audio
      priority: $priority
      currentImage: $currentImage
      currentThumb: $currentThumb
      currentAudio: $currentAudio
      gems: $gems
      available: $available
    ) {
      id
      artists_id
      styles_id
      techniques_id
      name
      year
      description
      story
      image
      thumbnail
      audio
      priority
      gems
      available
      createdAt
    }
  }
`;

export const CREATE_ARTWORKS_FOR_SHOP = gql`
  mutation($id: ID!, $available: Int!, $gems: Int!, $priority: Int!) {
    createArtworkFromShop(
      id: $id
      available: $available
      gems: $gems
      priority: $priority
    ) {
      id
      artists_id
      styles_id
      techniques_id
      name
      year
      description
      story
      image
      thumbnail
      audio
      priority
      shop
      gems
      available
      createdAt
    }
  }
`;

export const DELETE_ARTWORK = gql`
  mutation($id: ID!) {
    deleteArtworks(id: $id)
  }
`;

export const SIGNIN = gql`
  mutation($login: String!, $password: String!, $roleId: Int) {
    signIn(login: $login, password: $password, roleId: $roleId) {
      token
    }
  }
`;

export const DELETE_AD = gql`
  mutation($id: ID!) {
    deleteAd(id: $id)
  }
`;

export const CREATE_AD = gql`
  mutation(
    $title: String!
    $start_date: DateTime!
    $end_date: DateTime!
    $image: FileUpload
    $mobile_image: FileUpload
    $url: String
    $weight: Int
    $position: Int
  ) {
    createAd(
      title: $title
      start_date: $start_date
      end_date: $end_date
      url: $url
      image: $image
      mobile_image: $mobile_image
      weight: $weight
      position: $position
    ) {
      id
      title
      start_date
      end_date
      image
      mobile_image
      createdAt
      url
      weight
      position
    }
  }
`;

export const UPDATE_AD = gql`
  mutation(
    $id: ID!
    $title: String!
    $start_date: DateTime
    $end_date: DateTime
    $image: FileUpload
    $mobile_image: FileUpload
    $url: String
    $currentImage: String
    $currentMobileImage: String
    $weight: Int
    $position: Int
  ) {
    updateAd(
      id: $id
      title: $title
      start_date: $start_date
      end_date: $end_date
      url: $url
      image: $image
      mobile_image: $mobile_image
      currentImage: $currentImage
      currentMobileImage: $currentMobileImage
      weight: $weight
      position: $position
    ) {
      id
      title
      start_date
      end_date
      image
      mobile_image
      createdAt
      url
      weight
      position
    }
  }
`;

export const CREATE_AD_IMPRESSIONS = gql`
  mutation($ads_id: Int!, $user_ip: String!) {
    createAdImpressions(ads_id: $ads_id, user_ip: $user_ip) {
      id
      user_ip
      ads_id
    }
  }
`;

export const DELETE_AD_IMPRESSIONS = gql`
  mutation($id: ID!) {
    deleteAdImpressions(id: $id)
  }
`;

export const CREATE_AD_CLICK = gql`
  mutation($ads_id: Int!, $user_ip: String!) {
    createAdClick(ads_id: $ads_id, user_ip: $user_ip) {
      id
      user_ip
      ads_id
    }
  }
`;

export const DELETE_AD_CLICK = gql`
  mutation($id: ID!) {
    deleteAdClick(id: $id)
  }
`;

export const CREATE_CLASS_GROUP = gql`
  mutation($userId: Int!, $groupId: Int!, $gradeId: Int!, $schoolId: Int!) {
    addClassGroup(
      userId: $userId
      groupId: $groupId
      gradeId: $gradeId
      schoolId: $schoolId
    ) {
      result
      message
    }
  }
`;

export const UPDATE_CLASS_GROUP = gql`
  mutation(
    $id: ID!
    $userId: Int!
    $groupId: Int!
    $gradeId: Int!
    $schoolId: Int!
  ) {
    updateClassGroup(
      id: $id
      userId: $userId
      groupId: $groupId
      gradeId: $gradeId
      schoolId: $schoolId
    ) {
      result
      message
    }
  }
`;

export const CREATE_TEACHER = gql`
  mutation(
    $first_name: String!
    $last_name: String
    $email: String!
    $school_id: Int!
  ) {
    signUpTeacher(
      first_name: $first_name
      last_name: $last_name
      email: $email
      school_id: $school_id
    ) {
      token
      message
    }
  }
`;

export const ADD_SCHOOL_LEVELS = gql`
  mutation($schoolId: ID!, $levels: [inputSchoolLevels!]!) {
    saveSchoolLevel(schoolId: $schoolId, levels: $levels)
  }
`;

export const ADD_SCHOOL_ACADEMIC_LEVELS = gql`
  mutation($schoolId: ID!, $levels: [inputSchoolAcedemicLevel!]!) {
    saveSchoolAcedemicLevel(schoolId: $schoolId, levels: $levels)
  }
`;

export const ADD_SCHOOL_GRADE = gql`
  mutation($schoolId: ID!, $grades: [inputSchoolGrades!]!) {
    saveSchoolGrade(schoolId: $schoolId, grades: $grades)
  }
`;

export const ADD_SCHOOL_GROUP = gql`
  mutation($schoolId: ID!, $groups: [inputSchoolGroup!]!) {
    saveSchoolGroup(schoolId: $schoolId, groups: $groups)
  }
`;

export const DELETE_CLASS_GROUP = gql`
  mutation($id: ID!) {
    deleteClassGroup(id: $id)
  }
`;

export const DELETE_TEACHER = gql`
  mutation($id: ID!) {
    deleteTeacher(id: $id)
  }
`;

export const SAVE_SETTINGS_ARTWORKS = gql`
  mutation($artworks_id: Int!) {
    saveSettingsArtworks(artworks_id: $artworks_id)
  }
`;

export const SAVE_SETTINGS_STORE = gql`
  mutation($store_id: Int!) {
    saveSettingsStore(store_id: $store_id)
  }
`;

export const SAVE_QUESTION = gql`
  mutation(
    $id: ID
    $artboard_id: Int!
    $question: String!
    $type: Int!
    $questionImage1: FileUpload
    $questionImage2: FileUpload
    $questionImage3: FileUpload
    $question1: String
    $question2: String
    $question3: String
    $answer: Int!
    $order: Int!
  ) {
    createQuestion(
      id: $id
      artboard_id: $artboard_id
      question: $question
      type: $type
      questionImage1: $questionImage1
      questionImage2: $questionImage2
      questionImage3: $questionImage3
      question1: $question1
      question2: $question2
      question3: $question3
      answer: $answer
      order: $order
    ) {
      id
      artboard_id
      question
      type
      question1
      question2
      question3
      answer
      order
      createdAt
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation($id: ID!) {
    deleteQuestion(id: $id)
  }
`;

export const DELETE_AVATAR = gql`
  mutation($id: ID!) {
    deleteAvatar(id: $id)
  }
`;

export const ADD_AVATAR = gql`
  mutation(
    $title: String!
    $gems: Int
    $priority: Int
    $available: Int
    $image: FileUpload
  ) {
    createAvatar(
      title: $title
      gems: $gems
      priority: $priority
      available: $available
      image: $image
    ) {
      id
      title
      gems
      priority
      available
      image
      createdAt
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation(
    $title: String!
    $gems: Int
    $priority: Int
    $available: Int
    $image: FileUpload
    $id: ID!
    $currentImage: String
  ) {
    updateAvatar(
      id: $id
      title: $title
      gems: $gems
      priority: $priority
      available: $available
      image: $image
      currentImage: $currentImage
    ) {
      id
      title
      gems
      priority
      available
      image
      createdAt
    }
  }
`;

export const DELETE_SCHOOL_STUDENT = gql`
  mutation($id: ID!) {
    deleteSchoolStudent(id: $id)
  }
`;

export const EDIT_TEACHER = gql`
  mutation(
    $teacherId: ID!
    $email: String!
    $first_name: String!
    $last_name: String!
  ) {
    updateTeacherSchool(
      teacherId: $teacherId
      email: $email
      first_name: $first_name
      last_name: $last_name
    ) {
      id
      email
      first_name
      last_name
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation($image: FileUpload!, $userId: ID) {
    uploadUserAvatarImageByID(image: $image, userId: $userId)
  }
`;

export const READ_NOTIFICATION = gql`
  mutation($id: ID!) {
    readNotification(id: $id) {
      id
      userId
      readIt
      message
      link
      createdAt
    }
  }
`;
