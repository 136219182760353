import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Box,
  Collapse,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Link,
  Menu,
  MenuItem,
  ListItemText
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
//import { DataGrid } from '@material-ui/data-grid';

/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  }
}));

const PATH_URL = API_URL;

const mock = [
  {
    id: 1,
    fecha: "24/04/2021",
    source_ip: "192.0.0.1",
    clic: "Si"
  },
  {
    id: 2,
    fecha: "25/04/2021",
    source_ip: "192.0.0.2",
    clic: "Si"
  },
  {
    id: 3,
    fecha: "26/04/2021",
    source_ip: "192.0.0.3",
    clic: "Si"
  },
  {
    id: 4,
    fecha: "27/04/2021",
    source_ip: "192.0.0.4",
    clic: "Si"
  },
  {
    id: 1,
    fecha: "24/04/2021",
    source_ip: "192.0.0.1",
    clic: "Si"
  },
  {
    id: 2,
    fecha: "25/04/2021",
    source_ip: "192.0.0.2",
    clic: "Si"
  },
  {
    id: 3,
    fecha: "26/04/2021",
    source_ip: "192.0.0.3",
    clic: "Si"
  },
  {
    id: 4,
    fecha: "27/04/2021",
    source_ip: "192.0.0.4",
    clic: "Si"
  },
  {
    id: 1,
    fecha: "24/04/2021",
    source_ip: "192.0.0.1",
    clic: "Si"
  },
  {
    id: 2,
    fecha: "25/04/2021",
    source_ip: "192.0.0.2",
    clic: "Si"
  },
  {
    id: 3,
    fecha: "26/04/2021",
    source_ip: "192.0.0.3",
    clic: "Si"
  },
  {
    id: 4,
    fecha: "27/04/2021",
    source_ip: "192.0.0.4",
    clic: "Si"
  }
];

const columns = [
  {
    field: "fecha",
    headerName: "Fecha",
    width: 150,
    sortable: false,
    editable: false
  },
  {
    field: "ip",
    headerName: "Dirección IP",
    width: 150,
    sortable: false,
    editable: false
  },
  {
    field: "clic",
    headerName: "Clic",
    width: 150,
    sortable: false,
    editable: false
  }
];

const ListClicksAds = ({ className, ...rest }) => {
  const classes = useStyles();
  const [clics, setClics] = useState(mock);
  const [totalClics, setTotalClics] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  /* ad id */
  let { adsId } = useParams();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClose()}>
            <a style={{ textDecoration: "none", color: "#000000" }}>Menu</a>
          </MenuItem>
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  const selectRecordToDelete = (item, action) => {
    console.log("Delete");
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, clics.length - page * rowsPerPage);

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchAllImpressions = () => {
      ApiService.getClicksByAdId(adsId)
        .then(response => {
          if (response.data.length > 0) {
            setClics(response.data);
            setTotalClics(response.data.length);
          } else {
            setClics([]);
            setTotalClics([]);
          }
        })
        .catch(e => {
          setClics([]);
          setTotalClics([]);
        });
    };

    //fetchAllImpressions();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Clics &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalClics ? totalClics : 0} total
            </Typography>
          </Box>
        }
      />
      <Divider />

      <div style={{ height: 400, width: "100%" }}>
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Dirección IP</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clics
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => (
                    <TableRow hover key={item.id}>
                      <TableCell>{item.fecha ? item.fecha : null}</TableCell>
                      <TableCell>
                        {item.source_ip ? item.source_ip : 0}
                      </TableCell>
                      <TableCell align="right">
                        <MenuMoreActions data={item} />
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={1} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </div>

      <Divider />

      <TablePagination
        component="div"
        count={clics.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
    </Card>
  );
};

ListClicksAds.propTypes = {
  className: PropTypes.string,
  clics: PropTypes.array
};

ListClicksAds.defaultProps = {
  clics: []
};

export default ListClicksAds;
