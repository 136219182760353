import React from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const ToolbarStudent = ({ className, ...rest }) => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          color="primary"
          variant="contained"
          href={"/teacher/classroom/"+classroomId+"/student/invite"}
        >
          <AddIcon/> INVITAR ESTUDIANTE
        </Button>
      </Box>
      <Box mt={3}>
      </Box>
    </div>
  );
};

ToolbarStudent.propTypes = {
  className: PropTypes.string
};

export default ToolbarStudent;
