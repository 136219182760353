import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  IconButton,
  Typography,
  Checkbox,
  LinearProgress,
  CardHeader,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SCHOOL_GRADES_NAMES,
  GET_SCHOOL_GROUPS,
  GROUPS
} from "src/services/queries";
import { ADD_SCHOOL_GROUP } from "src/services/mutations";

const useStyles = makeStyles(() => ({
  root: {},
  titleSection: {
    color: "#263238",
    fontWeight: "500",
    marginBottom: "10px"
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 35,
    marginTop: 35
  }
}));

const SchoolGroups = ({ className, ...rest }) => {
  const classes = useStyles();
  /* school id */
  let { schoolId } = useParams();
  const [grados, setGrados] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    nivel_academico: false
  };
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSchoolGroups, setSelectedSchoolGroups] = useState([]);
  const [schoolgroups, setSchoolGroups] = useState([]);
  const [schoolgrades, setSchoolGrades] = useState([]);
  const [totalGroups, setTotalGroups] = useState(0);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectGroup, setSelectGroup] = useState([]);

  const [addMutation, resultMutation] = useMutation(ADD_SCHOOL_GROUP, {
    onCompleted(data) {
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: "El grupo de clase se ha actualizado correctamente"
      });
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(false);
    }
  });

  const [getGrades, resultGrades] = useLazyQuery(GET_SCHOOL_GRADES_NAMES, {
    fetchPolicy: "no-cache",
    onCompleted: resultG => {
      if (resultG) {
        console.log(resultG.schoolGradesNames);
        setSchoolGrades(resultG.schoolGradesNames);
      }
    }
  });

  const [getSelectedSchoolGroups, resultSelectedSchoolGroups] = useLazyQuery(
    GET_SCHOOL_GROUPS,
    {
      fetchPolicy: "no-cache",
      onCompleted: resultSSG => {
        if (resultSSG) {
          console.log(resultSSG.schoolGroups);
          setSelectedSchoolGroups(resultSSG.schoolGroups);
        }
      }
    }
  );

  useEffect(() => {
    if (schoolgrades) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [schoolgrades]);

  useEffect(() => {
    if (!selectedSchoolGroups) return false;
    let result = [];
    selectedSchoolGroups.map(group => {
      result.push(`${group.group_id}-${group.grade_id}`);
    });
    if (!result) return false;

    setValues(result);
  }, [selectedSchoolGroups]);

  const [getGroups, resultGroups] = useLazyQuery(GROUPS);
  useEffect(() => {
    if (resultGroups.data) {
      console.log(resultGroups.data.groups);
      setSchoolGroups(resultGroups.data.groups);
      //setTotalGroups(resultGroups.data.groups.length);
    }
  }, [resultGroups]);

  const handleChange = event => {
    let findValue = null;
    if (values)
      findValue = values.find((item, index) => item === event.target.value);

    if (event.target.checked) {
      console.log("CHECKED");

      if (!findValue) {
        setValues([...values, event.target.value]);
      }
    } else {
      console.log("UNCHECKED");
      if (findValue) {
        setValues(values.filter(val => val !== event.target.value));
      }
    }
    console.log(event.target.value);
  };

  useEffect(() => {
    console.log("valores - - - - - - -");
    console.log(values);
  }, [values]);

  /**
   * saveNewGroup
   */
  const saveNewGroup = () => {
    if (!values) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      console.log(values);

      let result = [];
      let divide;
      Object.values(values).map(function(item, index) {
        divide = item.split("-");
        result.push({
          school_group_id: parseInt(divide[0]),
          school_grade_id: parseInt(divide[1])
        });
      });

      console.log({
        schoolId: parseInt(schoolId),
        groups: result
      });

      addMutation({
        variables: {
          schoolId: parseInt(schoolId),
          groups: result
        }
      });
    }
  };

  /* Get groups */
  const fetchAllGroups = () => {};

  const fetchSystemGroups = () => {
    getGroups();
    getGrades({
      variables: {
        school_id: schoolId
      }
    });

    getSelectedSchoolGroups({
      variables: {
        school_id: schoolId
      }
    });
  };

  /**
   * alertMessage
   * @param boolean isOpen
   * @param string message
   * @returns
   */
  const alertMessage = (isOpen, message) => (
    <Collapse in={isOpen}>
      <Alert severity="error">{message}</Alert>
      <Box p={1}></Box>
    </Collapse>
  );

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchAllGroups();
    fetchSystemGroups();
  }, []);

  if (!schoolgrades || !schoolgroups) return false;

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }

  return schoolgrades && schoolgrades.length > 0 ? (
    <Card>
      <CardHeader title="Grupos" />
      {resultMutation.loading && <LinearProgress />}
      <Divider />
      <CardContent>
        <Collapse in={openAlert}>
          <Alert
            severity={errorMsg.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMsg.message}
          </Alert>
          <Box p={1}></Box>
        </Collapse>
        <form
          autoComplete="off"
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={8}>
              {/* {totalGroups && totalGroups > 0 ? */}
              {schoolgrades &&
                schoolgrades.map((grade, index) => (
                  <div>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      className={classes.titleSection}
                      m={1}
                    >
                      {grade.name}
                    </Typography>
                    {schoolgroups.map((group, i) => (
                      <Box alignItems="center" display="flex" ml={-1}>
                        <Checkbox
                          checked={
                            values.find(
                              item => item === group.id + "-" + grade.grade_id
                            )
                              ? true
                              : false
                          }
                          color="secondary"
                          name="nivel_academico"
                          onChange={event => handleChange(event)}
                          value={`${group.id}-${grade.grade_id}`}
                        />
                        <Typography
                          color="textSecondary"
                          variant="body1"
                          className={classes.textCheckbox}
                        >
                          {group.name}
                        </Typography>
                      </Box>
                    ))}
                  </div>
                ))}
            </Grid>
          </Grid>
          <Box my={2}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="flex-end" p={1}>
            <Button
              color="secondary"
              variant="contained"
              onClick={saveNewGroup}
            >
              GUARDAR
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  ) : (
    alertMessage(true, "Configure los grados para visualizar los grupos")
  );
};

SchoolGroups.propTypes = {
  className: PropTypes.string
};

export default SchoolGroups;
