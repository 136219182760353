/**
 * Kulture4Kids
 * Boombit - https://boombit.agency
 * Created at: 22/04/2021
 */
import axios from "axios";
import API_URL from "src/config/config";

/*  */
const configHeaders = {
  headers: {
    "Content-Type": "multipart/form-data"
  }
};

/**
 * teacherHomeDashboard
 * @returns JSON Array
 */
function teacherHomeDashboard() {
  return axios.get(`${API_URL}` + "/api/teacher/dashboard/");
}

/**
 * getGroupsDefaultDashboard
 * @returns JSON Array
 */
function getGroupsDefaultDashboard() {
  return axios.get(`${API_URL}` + "/api/teacher/classrooms/");
}

/**
 * CLASSROOMS REQUEST
 */

/**
 * newClassRoom
 * [Create a new classroom]
 * @returns message create
 */
function newClassRoom(data) {
  return axios.post(`${API_URL}` + "/api/teacher/classrooms/", data);
}

/**
 * getGroupById
 * [Get a single group by id]
 * @param int id
 * @returns JSON Array
 */
function getGroupById(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/`);
}

/**
 * editClassRoom
 * [Edit data a classroom]
 * @returns JSON Array
 */
function editClassRoom(id, data) {
  return axios.put(`${API_URL}/api/teacher/classroom/${id}/`, data);
}

/**
 * deleteClassRoom
 * [Delete data classroom]
 * @returns message delete
 */
function deleteClassRoom(id) {
  return axios.delete(`${API_URL}/api/teacher/classroom/${id}`);
}

/**
 * getDashboardByGroupId
 * [Get dashboard a single group by id]
 * @param int id
 * @returns JSON Array
 */
function getDashboardByGroupId(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/dashboard`);
}

/**
 * STUDENTS REQUEST
 * [Classroom Students]
 */

/**
 * getAssignmentsByGroupId
 * [Get all assignements by group id]
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsByGroupId(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/assignments/`);
}

/**
 * getStudentsByGroupId
 * @param int id
 * @returns JSON Array
 */
function getStudentsByGroupId(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/students`);
}

/**
 * getDashboardAssignmentById
 * @param int id
 * @returns JSON Array
 */
function getDashboardAssignmentById(id) {
  return axios.get(`${API_URL}/api/teacher/assignment/${id}/dashboard`);
}

/**
 * getStudentsByAssignmentId
 * @param int id
 * @returns JSON Array
 */
function getStudentsByAssignmentId(id) {
  return axios.get(`${API_URL}/api/teacher/assignment/${id}/students`);
}

/**
 * newAssignment
 * [Create a new assignment]
 * @returns message create
 */
function newAssignment(data) {
  return axios.post(`${API_URL}` + "/api/teacher/assignments/", data);
}

/**
 * getAssignmentsById
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsById(id) {
  return axios.get(`${API_URL}/api/teacher/assignment/${id}`);
}

/**
 * editAssignment
 * [Edit data assignment]
 * @returns message update
 */
function editAssignment(id, data) {
  return axios.put(`${API_URL}/api/teacher/assignment/${id}/`, data);
}

/**
 * deleteAssignment
 * [Delete data assignment]
 * @returns message delete
 */
function deleteAssignment(id) {
  return axios.delete(`${API_URL}/api/teacher/assignment/${id}`);
}

/**
 * newStudent
 * [Create a new student]
 * @returns message create
 */
function newStudent(data) {
  return axios.post(`${API_URL}` + "/api/teacher/students/", data);
}

/**
 * getStudentById
 * @param int id
 * @returns JSON Array
 */
function getStudentById(id) {
  return axios.get(`${API_URL}/api/teacher/student/${id}`);
}

/**
 * editStudent
 * [Edit data student]
 * @returns message update
 */
function editStudent(id, data) {
  return axios.put(`${API_URL}/api/teacher/student/${id}/`, data);
}

/**
 * deleteStudent
 * [Delete data student]
 * @returns message delete
 */
function deleteStudent(id, data) {
  return axios.delete(`${API_URL}/api/teacher/student/${id}`);
}

/**
 * getAssignmentsByStudentId
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsByStudentId(id) {
  return axios.get(`${API_URL}/api/teacher/student/${id}/assignments/`);
}

/**
 * getAssignmentsGroup
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsGroup(id) {
  return axios.get(`${API_URL}/api/teacher/classroom/${id}/assignments`);
}

/**
 * PROFILE TEACHER REQUEST
 */

/**
 * getTeacherProfile
 * @returns JSON Array
 */
function getTeacherProfile(id) {
  return axios.get(`${API_URL}/api/teacher/${id}/profile/`);
}

/**
 * editTeacherProfile
 * [Teacher Update Profile]
 * @returns message update
 */
function editTeacherProfile(id, data) {
  return axios.put(`${API_URL}/api/teacher/${id}/profile/`, data);
}

/**
 * editTeacherPassword
 * [Teacher Update Password]
 * @returns message update
 */
function editTeacherPassword(id, data) {
  return axios.put(`${API_URL}/api/teacher/${id}/password/`, data);
}

/**
 * getGrados
 * @returns JSON Array
 */
function getGrados() {
  return axios.get(`${API_URL}/api/teacher/school/grades`);
}

/**
 * getGrupos
 * @returns JSON Array
 */
function getGrupos() {
  return axios.get(`${API_URL}/api/teacher/school/groups`);
}

/**
 * LISTINGS REQUEST
 */

/**
 * getTurnos
 * @returns JSON Array
 */
function getTurnos() {
  return axios.get(`${API_URL}/api/teacher/school/shifts`);
}

/**
 * getDifficulties
 * @returns JSON Array
 */
function getDifficulties() {
  return axios.get(`${API_URL}/api/teacher/school/difficulties`);
}

/**
 * getArtworks
 * @returns JSON Array
 */
function getArtworks() {
  return axios.get(`${API_URL}/api/teacher/art/artworks`);
}

/**
 * USER SIGN IN
 */

function login(data) {
  return axios.post(`${API_URL}` + "/auth/token", data);
}

/**
 * USER TOKEN
 * SingIn
 */

/**
 * setToken
 * @param {*} userToken
 */
function setToken(userToken) {
  sessionStorage.setItem("token", JSON.stringify(userToken));
}

function getToken() {
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);

  return userToken?.token;
}

/**
 * setUserData
 * @param {*} userToken
 */
function setUserData(userToken) {
  sessionStorage.setItem("session", userToken);
}

/**
 * getUserID
 * [Get user id from storage]
 */
function getUserID() {
  const tokenString = sessionStorage.getItem("session");
  const userToken = JSON.parse(tokenString);

  return userToken?.id;
}

/**
 * System Notifications
 */

/**
 * getAllSystemNotifications
 * @returns JSON Array
 */
function getAllSystemNotifications() {
  return axios.get(`${API_URL}/api/admin/notifications`);
}

/**
 * getCheckUserSession
 * @returns JSON Array
 */
function getCheckUserSession(data) {
  return axios.post(`${API_URL}/api/check-user-session/`, data);
}

/**
 * Dashboard Home Principal
 */

/**
 * getDashboardAdminHome
 * @returns JSON Array
 */
function getDashboardAdminHome() {
  return axios.get(`${API_URL}/api/admin/home`);
}

/**
 * Module Schools
 */

/**
 * newSchool
 * [Create a new school]
 * @param data
 */
function newSchool(data) {
  return axios.post(`${API_URL}/api/admin/schools/`, data, configHeaders);
}

/**
 * getAllSchools
 * @returns JSON Array
 */
function getAllSchools() {
  return axios.get(`${API_URL}/api/admin/schools`);
}

/**
 * getSchoolDetailById
 * @returns JSON Array
 */
function getSchoolDetailById(id) {
  return axios.get(`${API_URL}/api/admin/school/${id}`);
}

/**
 * updateSchool
 * [Update school]
 * @returns message update
 */
function updateSchool(id, data) {
  return axios.put(`${API_URL}/api/admin/school/${id}/`, data, configHeaders);
}

/**
 * deleteSchool
 * [Delete school]
 * @returns message delete
 */
function deleteSchool(id) {
  return axios.delete(`${API_URL}/api/admin/school/${id}`);
}

/**
 * newTeacher
 * [Create a new teacher]
 * @param int id
 * @param data
 */
function newTeacher(id, data) {
  return axios.post(`${API_URL}/api/admin/school/${id}/teachers/`, data);
}

/**
 * getAllTeachersBySchool
 * @param int id
 * @returns JSON Array
 */
function getAllTeachersBySchool(id) {
  return axios.get(`${API_URL}/api/admin/school/${id}/teachers`);
}

/**
 * getTeacherDetailById
 * @param int schoolId
 * @param int id
 * @returns JSON Array
 */
function getTeacherDetailById(schoolId, id) {
  return axios.get(`${API_URL}/api/admin/school/${schoolId}/teacher/${id}/`);
}

/**
 * getClassGroupsByTeacherId
 * [returns all of the teacher's class groups]
 * @param int schoolId
 * @param int id
 * @returns JSON Array
 */
function getClassGroupsByTeacherId(schoolId, id) {
  return axios.get(
    `${API_URL}/api/admin/school/${schoolId}/teacher/${id}/classrooms/`
  );
}

/**
 * updateTeacherBySchool
 * [Update teacher]
 * @returns message update
 */
function updateTeacherBySchool(schoolId, id, data) {
  return axios.put(
    `${API_URL}/api/admin/school/${schoolId}/teacher/${id}/`,
    data,
    configHeaders
  );
}

/**
 * deleteTeacher
 * [Delete teacher]
 * @returns message delete
 */
function deleteTeacher(schoolId, id) {
  return axios.delete(`${API_URL}/api/admin/school/${schoolId}/teacher/${id}`);
}

/**
 * getAllStudentsBySchool
 * @param int id
 * @returns JSON Array
 */
function getAllStudentsBySchool(id) {
  return axios.get(`${API_URL}/api/admin/school/${id}/students`);
}

/**
 * getStudentDetailById
 * @param int schoolId
 * @param int id
 * @returns JSON Array
 */
function getStudentDetailById(schoolId, id) {
  return axios.get(`${API_URL}/api/admin/school/${schoolId}/student/${id}/`);
}

/**
 * newClassGroupBySchool
 * [Create a new class group]
 * @param int id
 * @param data
 */
function newClassGroupBySchool(schoolId, data) {
  return axios.post(
    `${API_URL}/api/admin/school/${schoolId}/classrooms/`,
    data
  );
}

/**
 * getAllClassGroupsBySchool
 * @param int schoolId
 * @returns JSON Array
 */
function getAllClassGroupsBySchool(schoolId) {
  return axios.get(`${API_URL}/api/admin/school/${schoolId}/classrooms`);
}

/**
 * getClassGroupDetailById
 * @param int schoolId
 * @param int id
 * @returns JSON Array
 */
function getClassGroupDetailById(schoolId, id) {
  return axios.get(`${API_URL}/api/admin/school/${schoolId}/classroom/${id}/`);
}

/**
 * getStudentsByClassGroupById
 * @param int schoolId
 * @param int id
 * @returns JSON Array
 */
function getStudentsByClassGroupById(schoolId, id) {
  return axios.get(
    `${API_URL}/api/admin/school/${schoolId}/classroom/${id}/students`
  );
}

/**
 * getAssignmentsByClassGroupById
 * @param int schoolId
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsByClassGroupById(schoolId, id) {
  return axios.get(
    `${API_URL}/api/admin/school/${schoolId}/classroom/${id}/assignments`
  );
}

/**
 * deleteClassGroupBySchool
 * [Delete class group]
 * @returns message delete
 */
function deleteClassGroupBySchool(schoolId, id) {
  return axios.delete(
    `${API_URL}/api/admin/school/${schoolId}/classroom/${id}`
  );
}

/**
 * getSchoolLevelsBySchoolId
 * @param int schoolId
 * @returns JSON Array
 */
function getSchoolLevelsBySchoolId(schoolId) {
  return axios.get(`${API_URL}/api/admin/school/${schoolId}/levels/`);
}

/**
 * getSchoolGradesBySchoolId
 * @param int schoolId
 * @returns JSON Array
 */
function getSchoolGradesBySchoolId(schoolId) {
  return axios.get(`${API_URL}/api/admin/school/${schoolId}/grades/`);
}

/**
 * getSchoolGroupsBySchoolId
 * @param int schoolId
 * @returns JSON Array
 */
function getSchoolGroupsBySchoolId(schoolId) {
  return axios.get(`${API_URL}/api/admin/school/${schoolId}/groups/`);
}

/**
 * Module Arts
 */

/**
 * newArtworks
 * [Create a new artwork]
 * @param data
 */
function newArtworks(data) {
  return axios.post(`${API_URL}/api/admin/art/artworks/`, data);
}

/**
 * editArtworks
 * [update artwork]
 * @param id
 * @param data
 */
function editArtworks(id, data) {
  console.log("editArtworks: ", id, data);
  return axios.put(
    `${API_URL}/api/admin/art/artwork/${id}/`,
    data,
    configHeaders
  );
}

/**
 * editArtworkQuiz
 * [update the quiz for the artwork]
 * @param id
 * @param data
 */
function editArtworkQuiz(id, data) {
  console.log("axios: ", data);
  return axios.put(
    `${API_URL}/api/admin/art/artwork/${id}/quiz/`,
    data,
    configHeaders
  );
}

/**
 * getQuizByArtwork
 * [get quiz by artwork]
 * @param id
 */
function getQuizByArtwork(id) {
  return axios.get(`${API_URL}/api/admin/art/artwork/${id}/quiz/`);
}

/**
 * getAllArtworksList
 * @returns JSON Array
 */
function getAllArtworksList() {
  return axios.get(`${API_URL}/api/admin/art/artworks`);
}

/**
 * getArtArtworkDetailById
 * @returns JSON Array
 */
function getArtArtworkDetailById(id) {
  return axios.get(`${API_URL}/api/admin/art/artwork/${id}`);
}

/**
 * deleteArtworkById
 * [Delete artwork]
 * @returns message delete
 */
function deleteArtworkById(id) {
  return axios.delete(`${API_URL}/api/admin/art/artwork/${id}`);
}

/**
 * getAllArtists
 * @returns JSON Array
 */
function getAllArtists() {
  return axios.get(`${API_URL}/api/admin/art/artists`);
}

/**
 * newArtist
 * [Create a new artist]
 * @param data
 */
function newArtist(data) {
  return axios.post(`${API_URL}/api/admin/art/artists/`, data, configHeaders);
}

/**
 * getArtistDetailById
 * @returns JSON Array
 */
function getArtistDetailById(id) {
  return axios.get(`${API_URL}/api/admin/art/artist/${id}`);
}

/**
 * getArtworksByArtistId
 * @returns JSON Array
 */
function getArtworksByArtistId(id) {
  return axios.get(`${API_URL}/api/admin/art/artist/${id}/artworks/`);
}

/**
 * updateArtist
 * [Update artist]
 * @returns message update
 */
function updateArtist(id, data) {
  return axios.put(
    `${API_URL}/api/admin/art/artist/${id}/`,
    data,
    configHeaders
  );
}

/**
 * deleteArtist
 * [Delete artist]
 * @returns message delete
 */
function deleteArtist(id) {
  return axios.delete(`${API_URL}/api/admin/art/artist/${id}`);
}

/**
 * newStyle
 * [Create a new style]
 * @returns message create
 */
function newStyle(data) {
  return axios.post(`${API_URL}` + "/api/admin/art/styles/", data);
}

/**
 * getAllStyles
 * @returns JSON Array
 */
function getAllStyles() {
  return axios.get(`${API_URL}/api/admin/art/styles/`);
}

/**
 * getStyleDetailById
 * @param int id
 * @returns JSON Array
 */
function getStyleDetailById(id) {
  return axios.get(`${API_URL}/api/admin/art/style/${id}`);
}

/**
 * getArtworksByStyleId
 * [returns all the works that have the selected style]
 * @param int id
 * @returns JSON Array
 */
function getArtworksByStyleId(id) {
  return axios.get(`${API_URL}/api/admin/art/style/${id}/artworks/`);
}

/**
 * updateStyle
 * [Update style]
 * @returns message update
 */
function updateStyle(id, data) {
  return axios.put(`${API_URL}/api/admin/art/style/${id}/`, data);
}

/**
 * deleteStyle
 * [Delete data style]
 * @returns message delete
 */
function deleteStyle(id) {
  return axios.delete(`${API_URL}/api/admin/art/style/${id}`);
}

/**
 * newTechnique
 * [Create a new style]
 * @returns message create
 */
function newTechnique(data) {
  return axios.post(`${API_URL}` + "/api/admin/art/techniques/", data);
}

/**
 * getAllTechniques
 * @returns JSON Array
 */
function getAllTechniques() {
  return axios.get(`${API_URL}/api/admin/art/techniques/`);
}

/**
 * getTechniquesDetailById
 * @param int id
 * @returns JSON Array
 */
function getTechniquesDetailById(id) {
  return axios.get(`${API_URL}/api/admin/art/technique/${id}`);
}

/**
 * getArtworksByTechniqueId
 * [returns all works with the selected technique]
 * @param int id
 * @returns JSON Array
 */
function getArtworksByTechniqueId(id) {
  return axios.get(`${API_URL}/api/admin/art/technique/${id}/artworks/`);
}

/**
 * updateTechnique
 * [Update technique]
 * @returns message update
 */
function updateTechnique(id, data) {
  return axios.put(`${API_URL}/api/admin/art/technique/${id}/`, data);
}

/**
 * deleteTechnique
 * [Delete data technique]
 * @returns message delete
 */
function deleteTechnique(id) {
  return axios.delete(`${API_URL}/api/admin/art/technique/${id}`);
}

/**
 * Module Public Users
 */

/**
 * getAllPublicUsers
 * @returns JSON Array
 */
function getAllPublicUsers() {
  return axios.get(`${API_URL}/api/admin/users/public/`);
}

/**
 * getPublicUserDetailById
 * @param int id
 * @returns JSON Array
 */
function getPublicUserDetailById(id) {
  return axios.get(`${API_URL}/api/admin/user/public/${id}`);
}

/**
 * getPuzzlesDoneByPublicUserId
 * [Get all puzzles done by public user]
 * @param int id
 * @returns JSON Array
 */
function getPuzzlesDoneByPublicUserId(id) {
  return axios.get(`${API_URL}/api/admin/user/public/${id}/puzzles/done/`);
}

/**
 * getAssignmentsByClassGroupId
 * @param int id
 * @returns JSON Array
 */
function getAssignmentsByClassGroupId(id) {
  return axios.get(`${API_URL}/api/admin/classgroup/${id}/assignments/`);
}

/**
 * Module Admin System
 */

/**
 * newAcademicLevels
 * [Create a new academic levels]
 * @returns message create
 */
function newAcademicLevels(data) {
  return axios.post(`${API_URL}` + "/api/admin/system/levels/", data);
}

/**
 * getAllAcademicLevels
 * @returns JSON Array
 */
function getAllAcademicLevels() {
  return axios.get(`${API_URL}/api/admin/system/levels/`);
}

/**
 * getAcademicLlevelDetailById
 * @returns JSON Array
 */
function getAcademicLlevelDetailById(id) {
  return axios.get(`${API_URL}/api/admin/system/level/${id}`);
}

/**
 * updateAcademicLevels
 * [Update academic levels]
 * @returns message create
 */
function updateAcademicLevels(id, data) {
  return axios.put(`${API_URL}/api/admin/system/level/${id}/`, data);
}

/**
 * deleteAcademicLevel
 * [Delete academic level]
 * @returns message delete
 */
function deleteAcademicLevel(id) {
  return axios.delete(`${API_URL}/api/admin/system/level/${id}`);
}

/**
 * newSchoolGrade
 * [Create a new school grade]
 * @returns message create
 */
function newSchoolGrade(data) {
  return axios.post(`${API_URL}` + "/api/admin/system/grades/", data);
}

/**
 * getAllSchoolGrades
 * @returns JSON Array
 */
function getAllSchoolGrades() {
  return axios.get(`${API_URL}/api/admin/system/grades/`);
}

/**
 * getSchoolGradeDetailById
 * @returns JSON Array
 */
function getSchoolGradeDetailById(id) {
  return axios.get(`${API_URL}/api/admin/system/grade/${id}`);
}

/**
 * updateSchoolGrade
 * [Update school grade]
 * @returns message create
 */
function updateSchoolGrade(id, data) {
  return axios.put(`${API_URL}/api/admin/system/grade/${id}/`, data);
}

/**
 * deleteSchoolGrade
 * [Delete school grade]
 * @returns message delete
 */
function deleteSchoolGrade(id) {
  return axios.delete(`${API_URL}/api/admin/system/grade/${id}`);
}

/**
 * newSchoolGroup
 * [Create a new school group]
 * @returns message create
 */
function newSchoolGroup(data) {
  return axios.post(`${API_URL}` + "/api/admin/system/groups/", data);
}

/**
 * getAllSchoolGroups
 * @returns JSON Array
 */
function getAllSchoolGroups() {
  return axios.get(`${API_URL}/api/admin/system/groups/`);
}

/**
 * getSchoolGroupDetailById
 * @returns JSON Array
 */
function getSchoolGroupDetailById(id) {
  return axios.get(`${API_URL}/api/admin/system/group/${id}`);
}

/**
 * updateSchoolGroup
 * [Update school group]
 * @returns message create
 */
function updateSchoolGroup(id, data) {
  return axios.put(`${API_URL}/api/admin/system/group/${id}/`, data);
}

/**
 * deleteSchoolGroup
 * [Delete school group]
 * @returns message delete
 */
function deleteSchoolGroup(id) {
  return axios.delete(`${API_URL}/api/admin/system/group/${id}`);
}

/**
 * newSchoolDifficulty
 * [Create a new school difficulty]
 * @returns message create
 */
function newSchoolDifficulty(data) {
  return axios.post(`${API_URL}` + "/api/admin/system/difficulties/", data);
}

/**
 * getAllSchoolDifficulties
 * @returns JSON Array
 */
function getAllSchoolDifficulties() {
  return axios.get(`${API_URL}/api/admin/system/difficulties/`);
}

/**
 * getSchoolDiffucultyDetailById
 * @returns JSON Array
 */
function getSchoolDiffucultyDetailById(id) {
  return axios.get(`${API_URL}/api/admin/system/difficulty/${id}`);
}

/**
 * updateSchoolDifficulty
 * [Update school difficulty]
 * @returns message create
 */
function updateSchoolDifficulty(id, data) {
  return axios.put(`${API_URL}/api/admin/system/difficulty/${id}/`, data);
}

/**
 * deleteSchoolDifficulty
 * [Delete school difficulty]
 * @returns message delete
 */
function deleteSchoolDifficulty(id) {
  return axios.delete(`${API_URL}/api/admin/system/difficulty/${id}`);
}

/**
 * Module Store
 */

/**
 * newArtworkStore
 * [Create a new artwork in the store]
 * @returns message create
 */
function newArtworkStore(data) {
  return axios.post(`${API_URL}` + "/api/admin/store/artworks/", data);
}

/**
 * getAllArtworks
 * @returns JSON Array
 */
function getAllArtworks() {
  return axios.get(`${API_URL}/api/admin/store/artworks`);
}

/**
 * getArtworkDetailById
 * @returns JSON Array
 */
function getArtworkDetailById(id) {
  return axios.get(`${API_URL}/api/admin/store/artwork/${id}`);
}

/**
 * editArtworkStore
 * [Update artwork from store]
 * @returns message update
 */
function editArtworkStore(id, data) {
  return axios.put(`${API_URL}/api/admin/store/artwork/${id}/`, data);
}

/**
 * deleteArtworkStoreById
 * [Delete artwork from store]
 * @returns message delete
 */
function deleteArtworkStoreById(id) {
  return axios.delete(`${API_URL}/api/admin/store/artwork/${id}`);
}

/**
 * newAvatarStore
 * [Create a new avatar in the store]
 * @returns message create
 */
function newAvatarStore(data) {
  return axios.post(
    `${API_URL}` + "/api/admin/store/avatars/",
    data,
    configHeaders
  );
}

/**
 * getAllAvatars
 * @returns JSON Array
 */
function getAllAvatars() {
  return axios.get(`${API_URL}/api/admin/store/avatars`);
}

/**
 * getAvatarDetailById
 * @returns JSON Array
 */
function getAvatarDetailById(id) {
  return axios.get(`${API_URL}/api/admin/store/avatar/${id}`);
}

/**
 * editAvatarStore
 * [Update avatar from store]
 * @returns message update
 */
function editAvatarStore(id, data) {
  return axios.put(
    `${API_URL}/api/admin/store/avatar/${id}/`,
    data,
    configHeaders
  );
}

/**
 * deleteAvatarStoreById
 * [Delete avatar from store]
 * @returns message delete
 */
function deleteAvatarStoreById(id) {
  return axios.delete(`${API_URL}/api/admin/store/avatar/${id}`);
}

/**
 * Module Ads
 */

/**
 * newAd
 * [Create a new ad]
 * @returns message create
 */
function newAd(data) {
  return axios.post(`${API_URL}` + "/api/admin/ads/", data, configHeaders);
}

/**
 * getAllAds
 * @returns JSON Array
 */
function getAllAds() {
  return axios.get(`${API_URL}/api/admin/ads/`);
}

/**
 * getDetailAdById
 * @returns JSON Array
 */
function getDetailAdById(id) {
  return axios.get(`${API_URL}/api/admin/ad/${id}`);
}

/**
 * editAd
 * [Update edit ad]
 * @returns message create
 */
function editAd(id, data) {
  return axios.put(`${API_URL}/api/admin/ad/${id}/`, data, configHeaders);
}

/**
 * getClicksByAdId
 * @returns JSON Array
 */
function getClicksByAdId(id) {
  return axios.get(`${API_URL}/api/admin/ad/${id}/clicks/`);
}

/**
 * getImpressionsByAdId
 * @returns JSON Array
 */
function getImpressionsByAdId(id) {
  return axios.get(`${API_URL}/api/admin/ad/${id}/impressions/`);
}

/**
 * deleteAdById
 * [Delete ads from ads]
 * @returns message delete
 */
function deleteAdById(id) {
  return axios.delete(`${API_URL}/api/admin/ad/${id}`);
}

/**
 * Module Admin Users
 */

/**
 * getAllAdministrators
 * @returns JSON Array
 */
function getAllAdministrators() {
  return axios.get(`${API_URL}/api/admin/admins/`);
}

/**
 * newAdministrator
 * [Create a new admin user]
 * @returns message create
 */
function newAdministrator(data) {
  return axios.post(`${API_URL}` + "/api/admin/admins/", data);
}

/**
 * getAdministratorById
 * [Admininstrator Details]
 * @param int id
 * @returns JSON Array
 */
function getAdministratorById(id) {
  return axios.get(`${API_URL}/api/admin/admin/${id}/`);
}

/**
 * editAdministrator
 * [Update Administrator]
 * @returns message update
 */
function editAdministrator(id, data) {
  return axios.put(`${API_URL}/api/admin/admin/${id}/`, data);
}

/**
 * deleteAdministrator
 * [Delete Administrator]
 * @returns message delete
 */
function deleteAdministrator(id) {
  return axios.delete(`${API_URL}/api/admin/admin/${id}`);
}

/**
 * getAllOrderArtworksOptions
 * @returns JSON Array
 */
function getAllOrderArtworksOptions() {
  return axios.get(`${API_URL}/api/admin/settings/order/art/options`);
}

/**
 * editOrderArtworkOption
 * [Update settings order store artworks]
 * @returns message update
 */
function editOrderArtworkOption(id, data) {
  return axios.put(`${API_URL}/api/admin/settings/order/art/`, data);
}

/**
 * getAllOrderStoreOptions
 * @returns JSON Array
 */
function getAllOrderStoreOptions() {
  return axios.get(`${API_URL}/api/admin/settings/order/store/options`);
}

/**
 * editOrderStoreOption
 * [Update settings order store]
 * @returns message update
 */
function editOrderStoreOption(id, data) {
  return axios.put(`${API_URL}/api/admin/settings/order/store/`, data);
}

/**
 * getCountries
 * @returns JSON Array
 */
function getCountries() {
  return axios.get(`${API_URL}/api/countries`);
}

const ApiService = {
  teacherHomeDashboard,
  getGroupsDefaultDashboard,
  newClassRoom,
  getGroupById,
  editClassRoom,
  deleteClassRoom,
  getDashboardByGroupId,
  getAssignmentsByGroupId,
  getStudentsByGroupId,
  getDashboardAssignmentById,
  getStudentsByAssignmentId,
  newAssignment,
  getAssignmentsById,
  editAssignment,
  deleteAssignment,
  newStudent,
  getStudentById,
  editStudent,
  deleteStudent,
  getAssignmentsByStudentId,
  getTeacherProfile,
  editTeacherProfile,
  editTeacherPassword,
  getGrados,
  getGrupos,
  getTurnos,
  getDifficulties,
  getArtworks,
  login,
  setToken,
  getToken,
  setUserData,
  getUserID,
  getAllSystemNotifications,
  getCheckUserSession,
  getDashboardAdminHome,
  newSchool,
  getAllSchools,
  getSchoolDetailById,
  updateSchool,
  deleteSchool,
  newTeacher,
  getAllTeachersBySchool,
  getTeacherDetailById,
  getClassGroupsByTeacherId,
  updateTeacherBySchool,
  deleteTeacher,
  getAllStudentsBySchool,
  getStudentDetailById,
  newClassGroupBySchool,
  getAllClassGroupsBySchool,
  getClassGroupDetailById,
  getStudentsByClassGroupById,
  getAssignmentsByClassGroupById,
  deleteClassGroupBySchool,
  getSchoolLevelsBySchoolId,
  getSchoolGradesBySchoolId,
  getSchoolGroupsBySchoolId,
  newArtworks,
  editArtworks,
  editArtworkQuiz,
  getQuizByArtwork,
  getAllArtworksList,
  getArtArtworkDetailById,
  deleteArtworkById,
  getAllArtists,
  newArtist,
  getArtistDetailById,
  getArtworksByArtistId,
  updateArtist,
  deleteArtist,
  newStyle, // Art style
  getAllStyles,
  getStyleDetailById,
  getArtworksByStyleId,
  updateStyle,
  deleteStyle,
  newTechnique,
  getAllTechniques,
  getTechniquesDetailById,
  getArtworksByTechniqueId,
  updateTechnique,
  deleteTechnique,
  getAllPublicUsers, //
  getPublicUserDetailById,
  getPuzzlesDoneByPublicUserId,
  getAssignmentsByClassGroupId,
  newAcademicLevels, // Amin System
  getAcademicLlevelDetailById,
  updateAcademicLevels,
  getAllAcademicLevels,
  getAllArtworks,
  getArtworkDetailById,
  deleteAcademicLevel,
  newSchoolGrade,
  getAllSchoolGrades,
  getSchoolGradeDetailById,
  updateSchoolGrade,
  deleteSchoolGrade,
  newSchoolGroup,
  getAllSchoolGroups,
  getSchoolGroupDetailById,
  updateSchoolGroup,
  deleteSchoolGroup,
  newSchoolDifficulty,
  getAllSchoolDifficulties,
  getSchoolDiffucultyDetailById,
  updateSchoolDifficulty,
  deleteSchoolDifficulty,
  getAllArtworks,
  getArtworkDetailById,
  deleteArtworkStoreById,
  newArtworkStore,
  getAllArtworks,
  getArtworkDetailById,
  editArtworkStore,
  deleteArtworkStoreById,
  newAvatarStore,
  getAllAvatars,
  getAvatarDetailById,
  editAvatarStore,
  deleteAvatarStoreById,
  newAd,
  getAllAds,
  getDetailAdById,
  editAd,
  getClicksByAdId,
  getImpressionsByAdId,
  deleteAdById,
  getAllAdministrators, // Admin Users
  newAdministrator,
  getAdministratorById,
  editAdministrator,
  deleteAdministrator,
  getAllOrderArtworksOptions,
  editOrderArtworkOption,
  getAllOrderStoreOptions,
  editOrderStoreOption,
  getCountries
};

export default ApiService;
