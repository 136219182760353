import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Typography,
  Checkbox,
  Link,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ACADEMIC_LEVELS,
  GET_SCHOOL_ACADEMIC_LEVEL
} from "src/services/queries";
import { ADD_SCHOOL_LEVELS } from "src/services/mutations";

const useStyles = makeStyles(() => ({
  root: {},
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 35,
    marginTop: 35
  }
}));

const NewAcademicLevels = ({ className, ...rest }) => {
  const classes = useStyles();
  /* school id */
  let { schoolId } = useParams();
  const [loading, setLoading] = useState(true);
  const [academiclevels, setAcademicLevels] = useState([]);
  const [totalLevels, setTotalLevels] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    nivel: false
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);

  const [getAcademicLevels, resultAcademicLevels] = useLazyQuery(
    ACADEMIC_LEVELS,
    {
      fetchPolicy: "no-cache",
      onCompleted: resultAL => {
        console.log("RESULTALADO: getAcademicLevels");
        console.log(resultAL);
        if (resultAL) {
          setAcademicLevels(resultAL.AcademicLevels);
          setTotalLevels(resultAL.AcademicLevels.length);
        }
      }
    }
  );

  const [getSchoolLevel, resultSchoolLevels] = useLazyQuery(
    GET_SCHOOL_ACADEMIC_LEVEL,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getSchoolLevel");
        console.log(result);

        if (result) {
          if (result.schoolLevel) {
            console.log("schoolLevel");
            console.log(result.schoolLevel);

            let newSelection = [];
            result.schoolLevel.map(item => {
              newSelection.push(String(item.academic_level_id));
            });

            setSelectedOrders(newSelection);
          }
        }
      }
    }
  );

  useEffect(() => {
    if (selectedOrders && academiclevels) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [selectedOrders, academiclevels]);

  const [addMutation, resultMutation] = useMutation(ADD_SCHOOL_LEVELS, {
    onCompleted(data) {
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
      });
      setValues(initialRecordState);
      fetchSchoolAcademicLevels();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
      setValues(initialRecordState);
    }
  });

  /**
   * alertMessage
   * @param boolean isOpen
   * @param string message
   * @returns
   */
  const alertMessage = (isOpen, message) => (
    <Collapse in={isOpen}>
      <Alert severity="error">{message}</Alert>
      <Box p={1}></Box>
    </Collapse>
  );

  const handleChange = (event, data) => {
    console.log("evenet ", event);
    // setValues({
    //   ...values,
    //   [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
    // });

    const selectedOrders = event.target.checked
      ? data.map(order => order.id)
      : [];
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrders.indexOf(id);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders, id);
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1)
      );
    }
    console.log(newSelectedOrders);
    setSelectedOrders(newSelectedOrders);
  };

  /**
   * saveNewAcademic
   */
  const saveNewAcademic = () => {
    let data = {
      nivel: values.nivel,
      user_id: 1
    };

    console.log("newSelectedOrders ", selectedOrders);
    let result = [];
    selectedOrders.map((item, i) => {
      result.push({
        school_academic_level_id: parseInt(item)
      });
    });

    if (result) {
      console.log({
        schoolId,
        levels: result
      });
      addMutation({
        variables: {
          schoolId,
          levels: result
        }
      });
    }
  };

  /* Get academic levels */
  const fetchAllAcademicLevels = () => {};

  const fetchSchoolAcademicLevels = () => {
    getSchoolLevel({
      variables: {
        school_id: schoolId
      }
    });
  };

  const fetchSchoolLevels = () => {
    getAcademicLevels();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    // fetchAllAcademicLevels();
    fetchSchoolAcademicLevels();
    fetchSchoolLevels();
  }, []);

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }

  return academiclevels && academiclevels.length > 0 ? (
    <Card>
      <CardHeader title="Nivel Académico" />
      {resultMutation.loading && <LinearProgress />}
      <Divider />
      <CardContent>
        <Collapse in={openAlert}>
          <Alert
            severity={errorMsg.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMsg.message}
          </Alert>
          <Box p={1}></Box>
        </Collapse>
        <form
          autoComplete="off"
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={8}>
              {academiclevels.map((item, index) => (
                <Box key={index} alignItems="center" display="flex" ml={-1}>
                  <Checkbox
                    id={item.id}
                    checked={selectedOrders.indexOf(item.id) !== -1}
                    color="secondary"
                    name={index}
                    onChange={event => handleSelectOne(event, item.id)}
                    itemID={item.id}
                    value={selectedOrders.indexOf(item.id) !== -1}
                  />
                  <Typography color="textSecondary" variant="body1">
                    {item.level}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Box my={2}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="flex-end" p={1}>
            <Button
              color="secondary"
              variant="contained"
              onClick={saveNewAcademic}
            >
              GUARDAR
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  ) : (
    alertMessage(true, "Agregar niveles académicos en sistema para continuar.")
  );
};

NewAcademicLevels.propTypes = {
  className: PropTypes.string
};

export default NewAcademicLevels;
