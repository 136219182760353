import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors
} from "@material-ui/core";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import ApiService from "src/services/ApiService";
import { PUBLIC_COUNT } from "src/services/queries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#EE403F",
    height: 56,
    width: 56
  }
}));

const TotalPublicUsers = ({ className, ...rest }) => {
  const classes = useStyles();
  const initialRecordState = {
    usuarios_publicos: 0,
    usuarios_publicos_nuevos: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const [getUserCount, resultUserCount] = useLazyQuery(PUBLIC_COUNT);
  const [publicCount, setPublicCount] = useState(0);

  useEffect(() => {
    if (resultUserCount.data) {
      console.log(resultUserCount.data.public);
      setPublicCount(resultUserCount.data.public);
    }
  }, [resultUserCount]);

  /**
   * Fetch API
   */
  useEffect(() => {
    getUserCount();

    let isLoaded = true;

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL DE USUARIOS PÚBLICOS
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {publicCount ? publicCount : 0}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <GroupOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <Typography color="textSecondary" variant="caption"></Typography>
          &nbsp;
          <Typography color="textSecondary" variant="caption">
            {publicCount ? publicCount : 0} Nuevos esta semana
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

TotalPublicUsers.propTypes = {
  className: PropTypes.string
};

export default TotalPublicUsers;
