import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  avatarItem: {
    width: "90px",
    height: "90px",
    backgroundSize: "cover",
    objectFit: "cover"
  }
}));

const PATH_URL = API_URL;

const StudentInfo = ({ className, ...rest }) => {
  const classes = useStyles();
  /* student id */
  let { studentId } = useParams();
  const initialRecordState = {
    avatar: "",
    nombre: "",
    apellido: "",
    correo: "",
    edad: 0,
    asignaciones_completadas: 0,
    total_asignaciones_completadas: 0,
    total_puntos: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchStudentDetail = () => {
      ApiService.getStudentById(studentId)
        .then(response => {
          if (isLoaded) {
            setDashboard(response.data);
          }
        })
        .catch(e => {
          setDashboard(initialRecordState);
        });
    };

    fetchStudentDetail();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h2">
                  {dashboard.nombre} {dashboard.nombre}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  {dashboard.avatar ? (
                    <img
                      className={classes.avatarItem}
                      src={`${PATH_URL}` + dashboard.avatar}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src =
                          process.env.PUBLIC_URL +
                          "/static/images/avatars/neutral_avatar.jpeg";
                      }}
                    />
                  ) : (
                    <img
                      className={classes.avatarItem}
                      src={
                        process.env.PUBLIC_URL +
                        "/static/images/avatars/neutral_avatar.jpeg"
                      }
                    />
                  )}
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.correo}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.edad ? dashboard.edad : 0} años
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ASIGNACIONES COMPLETADAS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {dashboard.asignaciones_completadas
                    ? dashboard.asignaciones_completadas
                    : 0}
                  %
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <ShowChartOutlinedIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Typography color="textSecondary" variant="caption">
                {dashboard.total_asignaciones_completadas
                  ? dashboard.total_asignaciones_completadas
                  : 0}
              </Typography>
              <LinearProgress value={90} variant="determinate" />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  PUNTOS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {dashboard.total_puntos ? dashboard.total_puntos : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatarProgress}>
                  <StarBorderOutlinedIcon />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

StudentInfo.propTypes = {
  className: PropTypes.string
};

export default StudentInfo;
