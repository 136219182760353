import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DashboardInit = () => {
  const _userData = sessionStorage.getItem("session");
  const navigate = useNavigate();

  useEffect(() => {
    console.log("_userData", _userData);
    if (_userData) navigate("/app/dashboard", { replace: true });
    else navigate("/login", { replace: true });
  }, []);

  return <></>;
};

export default DashboardInit;
