import React, { useEffect, useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import WelcomeTeacher from "./WelcomeTeacher";
import Students from "./Students";
import SchoolList from "./Schools";
import TotalTeachers from "./TotalTeachers";
import TotalPublicUsers from "./TotalPublicUsers";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <WelcomeTeacher />
        <Grid container spacing={3}>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <Students />
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <TotalTeachers />
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <TotalPublicUsers />
          </Grid>
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <SchoolList />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
