import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";
import {
  GET_SCHOOL_TEACHER_LIST,
  GET_TEACHERS,
  GRADES,
  GROUPS
} from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_CLASS_GROUP } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(() => ({
  root: {}
}));

const NewClassGroup = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* school id */
  let { schoolId } = useParams();
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [docentes, setDocentes] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectGrado, setGradoSeleccionado] = useState({
    id: 0,
    grado: "Seleccione..."
  });
  const [selectGrupo, setGrupoSeleccionado] = useState({
    id: 0,
    grupo: "Seleccione..."
  });
  const [selectTurno, setTurnoSeleccionado] = useState({
    id: 0,
    turno: "Seleccione..."
  });
  const [selectDocente, setDocenteSeleccionado] = useState({
    id: 0,
    turno: "Seleccione..."
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    grade: "",
    group: "",
    teacher: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const [getGrades, resultGrades] = useLazyQuery(GRADES);
  const [getGroups, resultGroups] = useLazyQuery(GROUPS);
  const [getTeachers, resultTeachers] = useLazyQuery(GET_TEACHERS);

  useEffect(() => {
    if (resultGrades.data) {
      setGrados(resultGrades.data.grades);
    }
  }, [resultGrades]);

  useEffect(() => {
    if (resultGroups.data) {
      setGrupos(resultGroups.data.groups);
    }
  }, [resultGroups]);

  useEffect(() => {
    if (resultTeachers.data) {
      setDocentes(resultTeachers.data.teachers);
    }
  }, [resultTeachers]);

  const [classGroupMutation, resultClassGroup] = useMutation(
    CREATE_CLASS_GROUP,
    {
      onCompleted(data) {
        console.log("CREATE_CLASS_GROUP");
        console.log(data);

        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
        });
        setValues(initialRecordState);

        formRef.current?.resetForm();
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
        });
        setValues(initialRecordState);
      }
    }
  );

  const handleChangeGrado = event => {
    setGradoSeleccionado({ ...selectGrado, id: event.target.value });
  };

  const handleChangeGrupo = event => {
    setGrupoSeleccionado({ ...selectGrupo, id: event.target.value });
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveNewClassroom
   */
  const saveNewClassroom = values => {
    console.log({
      userId: values.teacher,
      groupId: values.group,
      gradeId: values.grade,
      schoolId: schoolId
    });
    classGroupMutation({
      variables: {
        userId: parseInt(values.teacher),
        groupId: parseInt(values.group),
        gradeId: parseInt(values.grade),
        schoolId: parseInt(schoolId)
      }
    });
  };

  /* Get grades */
  const fetchAllGradesSchool = () => {
    getGrades();
  };

  /* Get groups */
  const fetchAllGroups = () => {
    getGroups();
  };

  /* Get teachers */
  const fetchAllTeachers = () => {
    getTeachers();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAllGradesSchool();
    fetchAllGroups();
    fetchAllTeachers();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card>
      <CardHeader title="Grupo" />
      {resultClassGroup.loading && <LinearProgress />}
      <Divider />
      <CardContent>
        <Collapse in={openAlert}>
          <Alert
            severity={errorMsg.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMsg.message}
          </Alert>
          <Box p={1}></Box>
        </Collapse>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialRecordState}
          validationSchema={Yup.object().shape({
            grade: Yup.string().required("El grado es requerido."),
            group: Yup.string().required("El grupo es requerido."),
            teacher: Yup.string().required("El docente es requerido.")
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            saveNewClassroom(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              className={clsx(classes.root, className)}
              {...rest}
              noValidate
            >
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Grado"
                    name="grade"
                    error={Boolean(touched.grade && errors.grade)}
                    helperText={touched.grade && errors.grade}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.grade}
                    variant="outlined"
                  >
                    <option key={""} value={""}></option>
                    {grados.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Grupo"
                    name="group"
                    error={Boolean(touched.group && errors.group)}
                    helperText={touched.group && errors.group}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.group}
                    variant="outlined"
                  >
                    <option key={""} value={""}></option>
                    {grupos.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Docente"
                    name="teacher"
                    error={Boolean(touched.teacher && errors.teacher)}
                    helperText={touched.teacher && errors.teacher}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.teacher}
                    variant="outlined"
                  >
                    <option key={""} value={""}></option>
                    {docentes.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.first_name} {option.last_name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Box my={2}>
                <Divider />
              </Box>
              <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={
                    isSubmitting && resultClassGroup.loading ? true : false
                  }
                >
                  CREAR
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

NewClassGroup.propTypes = {
  className: PropTypes.string
};

export default NewClassGroup;
