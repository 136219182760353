import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  IconButton,
  Typography,
  Checkbox,
  LinearProgress,
  CardHeader,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SCHOOL_ACADEMIC_LEVELS_BY_SCHOOL,
  LEVELS,
  GET_SCHOOL_ACADEMIC_LEVEL_WITH_GRADES
} from "src/services/queries";
import { ADD_SCHOOL_ACADEMIC_LEVELS } from "src/services/mutations";

const useStyles = makeStyles(() => ({
  root: {},
  titleSection: {
    color: "#263238",
    fontWeight: "500",
    marginBottom: "10px"
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 35,
    marginTop: 35
  }
}));

const SchoolDifficulty = ({ className, ...rest }) => {
  const classes = useStyles();
  /* school id */
  let { schoolId } = useParams();
  const [grados, setGrados] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    nivel_academico: false
  };
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nivelesAcademicos, setNivelesAcademicos] = useState([]);
  const [selectedSchoolLevels, setSelectedSchoolLevels] = useState([]);
  const [levels, setLevels] = useState([]);
  const [totalDifficulty, setTotalDifficulty] = useState(0);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const [getSelectedSchoolLevels, resultSelectedSchoolLevels] = useLazyQuery(
    GET_SCHOOL_ACADEMIC_LEVELS_BY_SCHOOL,
    {
      fetchPolicy: "no-cache"
    }
  );

  useEffect(() => {
    if (resultSelectedSchoolLevels.data) {
      console.log(
        "schoolAcademicLevels",
        resultSelectedSchoolLevels.data.schoolAcademicLevels
      );
      setSelectedSchoolLevels(
        resultSelectedSchoolLevels.data.schoolAcademicLevels
      );
    }
  }, [resultSelectedSchoolLevels]);

  useEffect(() => {
    if (!selectedSchoolLevels) return false;
    let result = [];
    selectedSchoolLevels.map(level => {
      result.push(`${level.academic_level_id}-${level.level_id}`);
    });
    if (!result) return false;

    setValues(result);
  }, [selectedSchoolLevels]);

  const [addMutation, resultMutation] = useMutation(
    ADD_SCHOOL_ACADEMIC_LEVELS,
    {
      onCompleted(data) {
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: "El nivel de dificultad se ha actualizado correctamente"
        });
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(false);
      }
    }
  );

  const [getSchoolLevel, resultSchoolLevels] = useLazyQuery(
    GET_SCHOOL_ACADEMIC_LEVEL_WITH_GRADES,
    {
      fetchPolicy: "no-cache"
    }
  );
  useEffect(() => {
    if (resultSchoolLevels.data) {
      console.log(
        "schoolLevel_withGrades",
        resultSchoolLevels.data.schoolLevel_withGrades
      );
      setNivelesAcademicos(resultSchoolLevels.data.schoolLevel_withGrades);
    }
  }, [resultSchoolLevels]);

  useEffect(() => {
    if (nivelesAcademicos) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [nivelesAcademicos]);

  const handleChange = event => {
    let findValue = null;
    if (values)
      findValue = values.find((item, index) => item === event.target.value);

    if (event.target.checked) {
      if (!findValue) {
        setValues([...values, event.target.value]);
      }
    } else {
      if (findValue) {
        setValues(values.filter(val => val !== event.target.value));
      }
    }
    console.log(event.target.value);
  };

  useEffect(() => {
    console.log("valores - - - - - - -", values);
  }, [values]);

  const [getLevels, resultLevels] = useLazyQuery(LEVELS);
  useEffect(() => {
    if (resultLevels.data) {
      console.log(
        "resultLevels.data.levels. . . . . .",
        resultLevels.data.levels
      );
      setLevels(resultLevels.data.levels);
    }
  }, [resultLevels]);

  /**
   * saveNewDifficulty
   */
  const saveNewDifficulty = () => {
    if (!values) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      let result = [];
      let divide;
      Object.values(values).map(function(item, index) {
        divide = item.split("-");
        result.push({
          school_academic_level_id: parseInt(divide[0]),
          level_id: parseInt(divide[1])
        });
      });

      console.log({
        schoolId: parseInt(schoolId),
        levels: result
      });

      addMutation({
        variables: {
          schoolId: parseInt(schoolId),
          levels: result
        }
      });
    }
  };

  /**
   * alertMessage
   * @param boolean isOpen
   * @param string message
   * @returns
   */
  const alertMessage = (isOpen, message) => (
    <Collapse in={isOpen}>
      <Alert severity="error">{message}</Alert>
      <Box p={1}></Box>
    </Collapse>
  );

  /* Get difficulty */
  const fetchAllDifficulty = () => {
    getLevels();
    getSchoolLevel({
      variables: {
        school_id: schoolId
      }
    });

    getSelectedSchoolLevels({
      variables: {
        school_id: schoolId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchAllDifficulty();
  }, []);

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {nivelesAcademicos && nivelesAcademicos.length > 0 ? (
        <Card>
          <CardHeader title="Dificultad" />
          {resultMutation.loading && <LinearProgress />}
          <Divider />
          <CardContent>
            <Collapse in={openAlert}>
              <Alert
                severity={errorMsg.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMsg.message}
              </Alert>
              <Box p={1}></Box>
            </Collapse>
            <form
              autoComplete="off"
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Grid container spacing={3}>
                <Grid item md={6} xs={8}>
                  {nivelesAcademicos.map(nivel => (
                    <>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                        className={classes.titleSection}
                        m={1}
                      >
                        {nivel.level}
                      </Typography>
                      {levels.map(level => (
                        <Box alignItems="center" display="flex" ml={-1}>
                          <Checkbox
                            checked={
                              values.find(
                                item => item === nivel.id + "-" + level.id
                              )
                                ? true
                                : false
                            }
                            color="secondary"
                            name="nivel_academico"
                            onChange={event => handleChange(event)}
                            value={`${nivel.id}-${level.id}`}
                          />
                          <Typography color="textSecondary" variant="body1">
                            {level.name}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  ))}
                </Grid>
              </Grid>
              <Box my={2}>
                <Divider />
              </Box>
              <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={saveNewDifficulty}
                >
                  GUARDAR
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      ) : (
        alertMessage(
          true,
          "Configure los niveles académicos para visualizar los grados"
        )
      )}
    </>
  );
};

SchoolDifficulty.propTypes = {
  className: PropTypes.string
};

export default SchoolDifficulty;
