import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  TextField,
  makeStyles,
  IconButton
} from "@material-ui/core";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";

import {
  GET_TEACHERS,
  GRADES,
  GROUPS,
  GET_SCHOOL_CLASS_GROUPS_BY_ID,
  COUNT_STUDENTS_BY_SCHOOL_AND_CLASSGROUP,
  GET_PG,
  GET_CG_ASSIGNMENT_PERCENT,
  GET_TOTAL_POINTS_BY_CG
} from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_CLASS_GROUP } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  avatar_hat: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  }
}));

const DetailClassGroup = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* school id */
  let { schoolId } = useParams();
  /* class group id */
  let { classgroupId } = useParams();
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [countStudents, setCountStudents] = useState([]);
  const [docentes, setDocentes] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [points, setPoints] = useState([]);
  const [selectGrado, setGradoSeleccionado] = useState({
    id: 0,
    grado: "Seleccione..."
  });
  const [selectGrupo, setGrupoSeleccionado] = useState({
    id: 0,
    grupo: "Seleccione..."
  });
  const [selectDocente, setDocenteSeleccionado] = useState({
    id: 0,
    nombre: "Seleccione..."
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    grade: "",
    group: "",
    teacher: ""
  };
  const initialRecordDashboard = {
    total_estudiantes: 0,
    asignaciones_completadas: 0,
    puntos_acumulados: 0,
    nombre_turno: "Matutino",
    porcentaje_completadas: 0,
    total_asignaciones: 0,
    total_completadas: 0
  };
  const [values, setValues] = useState(initialRecordState);
  const [dashboard, setDashboard] = useState(initialRecordDashboard);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const [getGrades, resultGrades] = useLazyQuery(GRADES, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: GRADES", result);
      if (result && result.grades) {
        setGrados(result.grades);
      }
    }
  });

  const [getGroups, resultGroups] = useLazyQuery(GROUPS, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: GROUPS", result);
      if (result && result.groups) {
        setGrupos(result.groups);
      }
    }
  });

  const [getTeachers, resultTeachers] = useLazyQuery(GET_TEACHERS, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: GET_TEACHERS", result);
      if (result && result.teachers) {
        setDocentes(result.teachers);
      }
    }
  });

  const [percent, setPercent] = useState(0);
  const [pointsClassGroup, setPointsClassGroup] = useState(0);

  const [getPercent, resultGetPercent] = useLazyQuery(
    GET_CG_ASSIGNMENT_PERCENT,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getPercent", result);
        if (result && result.total_classgroup_assignment_percent) {
          setPercent(result.total_classgroup_assignment_percent);
        }
      }
    }
  );

  const [getPointsClassGroup, resultGetPointsClassGroup] = useLazyQuery(
    GET_TOTAL_POINTS_BY_CG,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getPointsClassGroup", result);
        if (result && result.getPointsByClassGroup) {
          setPointsClassGroup(result.getPointsByClassGroup);
        }
      }
    }
  );

  const [getPoints, resultGetPoints] = useLazyQuery(GET_PG, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getPG", result);
      if (result && result.getPGByID) {
        setPoints(result.getPGByID);
      }
    }
  });

  const [classGroupMutation, resultClassGroup] = useMutation(
    UPDATE_CLASS_GROUP,
    {
      onCompleted(data) {
        console.log("UPDATE_CLASS_GROUP");
        console.log(data);

        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
        });
        //setValues(initialRecordState);
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
        });
        //setValues(initialRecordState);
      }
    }
  );

  const [getSchoolClassGroups, resultSchoolClassGroups] = useLazyQuery(
    GET_SCHOOL_CLASS_GROUPS_BY_ID,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: GET_SCHOOL_CLASS_GROUPS_BY_ID", result);
        if (result && result.schoolClassGroupsById) {
          setDashboard(result.schoolClassGroupsById);
        }
      }
    }
  );

  useEffect(() => {
    if (dashboard.length > 0) {
      console.log("aplicando...", dashboard);
      setValues({
        grade: dashboard[0].grade_id,
        group: dashboard[0].group_id,
        teacher: dashboard[0].userId
      });
    }
  }, [dashboard]);

  useEffect(() => {
    console.log("values => ", values);
  }, [values]);

  useEffect(() => {
    if (dashboard && dashboard.userId) {
      getPoints({
        variables: {
          userId: dashboard.userId
        }
      });
    }
  }, [dashboard]);

  const [getCountStudent, resultCountStudent] = useLazyQuery(
    COUNT_STUDENTS_BY_SCHOOL_AND_CLASSGROUP,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getCountStudent", result);
        if (result) {
          if (result.countStudents) {
            setCountStudents(result.countStudents);
          }
        }
      }
    }
  );

  const handleChangeGrado = event => {
    setGradoSeleccionado({ ...selectGrado, id: event.target.value });
  };

  const handleChangeGrupo = event => {
    setGrupoSeleccionado({ ...selectGrupo, id: event.target.value });
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * updateClassGroup
   */
  const updateClassGroup = values => {
    console.log({
      id: classgroupId,
      userId: values.teacher,
      groupId: values.group,
      gradeId: values.grade,
      schoolId: schoolId
    });
    classGroupMutation({
      variables: {
        id: classgroupId,
        userId: parseInt(values.teacher),
        groupId: parseInt(values.group),
        gradeId: parseInt(values.grade),
        schoolId: parseInt(schoolId)
      }
    });
  };

  const fetchDetailClassGroup = () => {
    console.log("id:" + classgroupId);
    getSchoolClassGroups({
      variables: {
        id: classgroupId
      }
    });
  };

  /* Get grades */
  const fetchAllGradesSchool = () => {
    getGrades();
  };

  /* Get groups */
  const fetchAllGroups = () => {
    getGroups();
  };

  /* Get teachers */
  const fetchAllTeachers = () => {
    getTeachers();
  };

  const fetchCountStudents = () => {
    getCountStudent({
      variables: {
        school_id: schoolId,
        classGroup_id: classgroupId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchDetailClassGroup();
    fetchAllGradesSchool();
    fetchAllGroups();
    fetchAllTeachers();
    fetchCountStudents();

    getPercent({
      variables: {
        class_group_id: parseInt(classgroupId),
        school_id: parseInt(schoolId)
      }
    });

    getPointsClassGroup({
      variables: {
        classGroup_id: parseInt(classgroupId)
      }
    });
  }, []);

  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      container
      spacing={3}
    >
      <Grid item lg={4} sm={6} xs={12} mb={1}>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  TOTAL DE ESTUDIANTES
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {countStudents ? countStudents : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar_hat}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/static/images/ic_school.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={1} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.nombre_turno}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Box p={1}></Box>
        <Card>
          {!resultGetPercent && <LinearProgress />}
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ASIGNACIONES COMPLETADAS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {percent.percent ? percent.percent : 0}%
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <ShowChartOutlinedIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Typography color="textSecondary" variant="caption">
                {percent.total_completed}/{percent.total}
              </Typography>
              <LinearProgress
                value={percent.total_completed ? percent.total_completed : 0}
                variant="determinate"
              />
            </Box>
          </CardContent>
        </Card>
        <Box p={1}></Box>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  PUNTOS ACUMULADOS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {pointsClassGroup ? pointsClassGroup : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar_hat}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/ic_star_white.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={1} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption"></Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={8} sm={6} xs={12} mb={1}>
        <Card>
          <CardHeader title="Información del grupo" />
          {resultClassGroup.loading && <LinearProgress />}
          <Divider />
          <CardContent>
            <Collapse in={openAlert}>
              <Alert
                severity={errorMsg.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMsg.message}
              </Alert>
              <Box p={1}></Box>
            </Collapse>
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={values}
              validationSchema={Yup.object().shape({
                grade: Yup.string().required("El grado es requerido."),
                group: Yup.string().required("El grupo es requerido."),
                teacher: Yup.string().required("El docente es requerido.")
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                updateClassGroup(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className={clsx(classes.root, className)}
                  {...rest}
                  noValidate
                >
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Grado"
                        name="grade"
                        error={Boolean(touched.grade && errors.grade)}
                        helperText={touched.grade && errors.grade}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.grade}
                        variant="outlined"
                      >
                        <option key={""} value={""}></option>
                        {grados.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Grupo"
                        name="group"
                        error={Boolean(touched.group && errors.group)}
                        helperText={touched.group && errors.group}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.group}
                        variant="outlined"
                      >
                        <option key={""} value={""}></option>
                        {grupos.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Docente"
                        name="teacher"
                        error={Boolean(touched.teacher && errors.teacher)}
                        helperText={touched.teacher && errors.teacher}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.teacher}
                        variant="outlined"
                      >
                        <option key={""} value={""}></option>
                        {docentes.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.first_name} {option.last_name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Box display="flex" justifyContent="flex-end" p={1}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={
                        isSubmitting && resultClassGroup.loading ? true : false
                      }
                    >
                      GUARDAR
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

DetailClassGroup.propTypes = {
  className: PropTypes.string
};

export default DetailClassGroup;
