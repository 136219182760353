import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";
import { useMutation } from "@apollo/client";
import { CREATE_TEACHER } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddNewTeacher = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();
  /* school id */
  let { schoolId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    first_name: "",
    last_name: "",
    email: "",
    school_id: schoolId
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [createTeacher, resultCreateTeacher] = useMutation(CREATE_TEACHER, {
    onCompleted(data) {
      console.log("CREATE_TEACHER");
      console.log(data);

      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
      });
      setValues(initialRecordState);
      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
      //setValues(initialRecordState);
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveNewTeacher
   */
  const saveNewTeacher = values => {
    if (values.first_name == "" || values.email == "") {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      console.log({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        school_id: parseInt(schoolId)
      });
      createTeacher({
        variables: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          school_id: parseInt(schoolId)
        }
      });
    }
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={values}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required("El nombre es requerido."),
        last_name: Yup.string().required("El apellido es requerido."),
        email: Yup.string()
          .email("Debe ser un formato de correo electrónico válido.")
          .max(255)
          .required("El correo electrónico es requerido.")
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        saveNewTeacher(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Docente" />
            {resultCreateTeacher.loading && <LinearProgress />}
            <Divider />
            <CardContent>
              <Collapse in={openAlert}>
                <Alert
                  severity={errorMsg.type}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {errorMsg.message}
                </Alert>
                <Box p={1}></Box>
              </Collapse>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    name="first_name"
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Apellido"
                    name="last_name"
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Correo del docente"
                    name="email"
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                disabled={
                  isSubmitting && resultCreateTeacher.loading ? true : false
                }
              >
                CREAR
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddNewTeacher.propTypes = {
  className: PropTypes.string
};

export default AddNewTeacher;
