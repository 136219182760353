import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Typography,
  Checkbox,
  LinearProgress,
  CardHeader,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_SCHOOL_ACADEMIC_LEVEL,
  GET_SCHOOL_ACADEMIC_LEVEL_WITH_GRADES,
  GET_SCHOOL_GRADES,
  GRADES
} from "src/services/queries";
import { ADD_SCHOOL_GRADE } from "src/services/mutations";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  textCheckbox: {
    display: "inline-flex"
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 35,
    marginTop: 35
  }
}));

const SchoolGrade = ({ className, ...rest }) => {
  const classes = useStyles();
  /* school id */
  let { schoolId } = useParams();
  const [submitted, setSubmitted] = useState(false);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    nivel_academico: false
  };
  const [values, setValues] = useState(initialRecordState);
  const [loading, setLoading] = useState(true);
  const [nivelesAcademicos, setNivelesAcademicos] = useState([]);
  const [grades, setGrades] = useState([]);
  const [schoolGrades, setSchoolGrades] = useState([]);
  const [totalLevels, setTotalLevels] = useState(0);
  const [selectNivelAcademico, setNivelAcademicoSeleccionado] = useState({
    id: 0,
    grade: "Seleccione..."
  });
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const [getGrades, resultGrades] = useLazyQuery(GRADES);
  useEffect(() => {
    if (resultGrades.data) {
      console.log(resultGrades.data.grades);
      setGrades(resultGrades.data.grades);
      setTotalLevels(resultGrades.data.grades.length);
    }
  }, [resultGrades]);

  const [getSchoolLevel, resultSchoolLevels] = useLazyQuery(
    GET_SCHOOL_ACADEMIC_LEVEL_WITH_GRADES,
    {
      fetchPolicy: "no-cache"
    }
  );

  useEffect(() => {
    if (resultSchoolLevels.data) {
      console.log("resultSchoolLevels.data.schoolLevel_withGrades");
      console.log(resultSchoolLevels.data.schoolLevel_withGrades);
      setNivelesAcademicos(resultSchoolLevels.data.schoolLevel_withGrades);
    }
  }, [resultSchoolLevels]);

  const [getSchoolGrades, resultSchoolGrades] = useLazyQuery(
    GET_SCHOOL_GRADES,
    {
      fetchPolicy: "no-cache"
    }
  );
  useEffect(() => {
    if (resultSchoolGrades.data) {
      console.log("resultSchoolGrades.data.schoolGrades");
      console.log(resultSchoolGrades.data.schoolGrades);
      setSchoolGrades(resultSchoolGrades.data.schoolGrades);
    }
  }, [resultSchoolGrades]);

  const [addMutation, resultMutation] = useMutation(ADD_SCHOOL_GRADE, {
    onCompleted(data) {
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: "El grado escolar se ha actualizado correctamente"
      });
      setValues(initialRecordState);
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(false);
      setValues(initialRecordState);
    }
  });
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);

  useEffect(() => {
    let temp = [];
    grades.map((item, i) => {
      temp.push(false);
    });
  }, [grades]);

  useEffect(() => {
    if (grades.length > 0 && nivelesAcademicos.length > 0) {
      console.log("nivelesAcademicos + + + + + + + grades + + + + + +");
      console.log(nivelesAcademicos);
      console.log(grades);
      let temp = [];
      grades.map((index, i) => {
        temp.push(nivelesAcademicos[0].id);
      });
      setSelectedLevels(temp);
    }
    if (grades && nivelesAcademicos) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [nivelesAcademicos, grades]);

  useEffect(() => {
    console.log("selectedLevels . . . . .");
    console.log(selectedLevels);
  }, [selectedLevels]);

  useEffect(() => {
    console.log("selectedOrders . . . . .");
    console.log(selectedOrders);
  }, [selectedOrders]);

  useEffect(() => {
    let newOrdersResult = [];
    let newLevelsResult = [];

    if (grades.length > 0 && schoolGrades.length > 0) {
      newOrdersResult = Object.assign({}, selectedOrders);
      newLevelsResult = Object.assign({}, selectedLevels);

      grades.map((grade, index) => {
        console.log(index);
        schoolGrades.map((item, i) => {
          if (grade.id == item.grade_id) {
            newOrdersResult[index] = grade.id;
            newLevelsResult[index] = item.academic_level_id;
          } else {
            if (!newOrdersResult[index]) newOrdersResult[index] = false;
          }
        });
      });
    }

    if (!newOrdersResult) return false;
    if (!newLevelsResult) return false;

    setSelectedOrders(newOrdersResult);
    setSelectedLevels(newLevelsResult);
  }, [grades, schoolGrades]);

  const handleChange = (event, index) => {
    console.log(event.target.value);
    setSelectedLevels({ ...selectedLevels, [index]: event.target.value });
  };

  const handleSelectOne = (event, item, index) => {
    console.log("event.target.value");
    console.log(event.target.value);

    let data = event.target.value;
    setSelectedOrders({
      ...selectedOrders,
      [index]: selectedOrders[index] === false ? data : false
    });
  };

  /**
   * saveSchoolGrade
   */
  const saveSchoolGrade = () => {
    let data = {
      nivel_academico: values.nivel_academico,
      user_id: 1
    };

    if (
      values.grade == "" ||
      values.group == "" ||
      values.turno == "" ||
      values.teacher == ""
    ) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      console.log(selectedOrders);
      console.log(selectedLevels);

      let result = [];
      Object.values(selectedOrders).map(function(item, index) {
        if (item !== false) {
          result.push({
            school_grade_id: parseInt(item),
            school_academic_level_id: parseInt(selectedLevels[index])
          });
        }
      });

      console.log({
        schoolId: parseInt(schoolId),
        grades: result
      });

      addMutation({
        variables: {
          schoolId: parseInt(schoolId),
          grades: result
        }
      });
    }
  };

  /* Get academic levels */
  const fetchAllAcademicLevels = () => {
    getSchoolLevel({
      variables: {
        school_id: schoolId
      }
    });
  };

  const fetchSystemGrades = () => {
    getGrades();
  };

  /**
   * alertMessage
   * @param boolean isOpen
   * @param string message
   * @returns
   */
  const alertMessage = (isOpen, message) => (
    <Collapse in={isOpen}>
      <Alert severity="error">{message}</Alert>
      <Box p={1}></Box>
    </Collapse>
  );

  const fetchSchoolGrades = () => {
    getSchoolGrades({
      variables: {
        school_id: schoolId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAllAcademicLevels();
    fetchSystemGrades();
    fetchSchoolGrades();

    return () => {
      isLoaded = false;
    };
  }, []);

  const GradeFields = () => {
    return (
      <>
        {grades &&
          grades.map((item, index) => (
            <Box key={index} alignItems="center" display="flex" ml={-1} mb={2}>
              <Grid item md={4} xs={12}>
                <Checkbox
                  checked={selectedOrders[index]}
                  color="secondary"
                  name="nivel_academico"
                  onChange={event => handleSelectOne(event, item, index)}
                  itemID={item.id}
                  value={item.id}
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                  className={classes.textCheckbox}
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nivel académico"
                  name="nivel_academico"
                  onChange={event => handleChange(event, index)}
                  //onChange={event => handleSelectOne(event, item, index)}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={selectedLevels[index]}
                  variant="outlined"
                  color="secondary"
                >
                  {nivelesAcademicos.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.level}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Box>
          ))}
      </>
    );
  };

  if (!selectedLevels || !grades) return false;

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }

  return nivelesAcademicos && nivelesAcademicos.length > 0 ? (
    <Card>
      <CardHeader title="Grados" />
      {resultMutation.loading && <LinearProgress />}
      <Divider />
      <CardContent>
        <Collapse in={openAlert}>
          <Alert
            severity={errorMsg.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMsg.message}
          </Alert>
          <Box p={1}></Box>
        </Collapse>
        <form
          autoComplete="off"
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={8}>
              <Box p={1}></Box>
              {selectedOrders && <GradeFields />}
            </Grid>
            <Grid item md={6} xs={8}></Grid>
          </Grid>
          <Box my={2}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="flex-end" p={1}>
            <Button
              color="secondary"
              variant="contained"
              onClick={saveSchoolGrade}
            >
              GUARDAR
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  ) : (
    alertMessage(
      true,
      "Configure los niveles académicos para visualizar los grados"
    )
  );
};

SchoolGrade.propTypes = {
  className: PropTypes.string
};

export default SchoolGrade;
