import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
  ListItemText,
  Tooltip,
  IconButton
} from "@material-ui/core";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ApiService from "src/services/ApiService";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  }
}));

const ClassGroups = ({ className, ...rest }) => {
  const classes = useStyles();
  const [groups, setGroups] = useState([]);
  const [totalClassGroups, setTotalClassGroups] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, groups.length - page * rowsPerPage);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchClassGroups = () => {
      ApiService.getGroupsDefaultDashboard()
        .then(response => {
          if (isLoaded) {
            setGroups(response.data);
            setTotalClassGroups(response.data.length);
          }
        })
        .catch(e => {});
    };

    fetchClassGroups();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={`Grupos de Clase ${totalClassGroups}`}
        subheader="total"
      />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Grupo</TableCell>
                <TableCell>Turno</TableCell>
                <TableCell>Estudiantes</TableCell>
                <TableCell>Obras Asignadas</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(group => (
                  <TableRow hover key={group.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar className={classes.avatar_group}>
                          <GroupOutlinedIcon />
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          <Link
                            className={classes.text}
                            to={"/teacher/classroom/view/" + `${group.id}`}
                          >
                            {group.nombre}
                          </Link>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{group.turno}</TableCell>
                    <TableCell>
                      {group.total_estudiantes ? group.total_estudiantes : 0}
                    </TableCell>
                    <TableCell>
                      {group.total_asignaciones ? group.total_asignaciones : 0}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Más opciones">
                        <IconButton
                          edge="end"
                          size="small"
                          // onClick={handleMenuOpen}
                          // ref={moreRef}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                      <Link
                        className={classes.text}
                        to={"/teacher/classroom/view/" + `${group.id}`}
                      >
                        <IconButton edge="end" size="small">
                          <ArrowForwardIcon />
                        </IconButton>
                      </Link>
                      <Menu
                        anchorEl={moreRef.current}
                        keepMounted
                        onClose={handleMenuClose}
                        open={openMenu}
                      >
                        <MenuItem>
                          <ListItemText primary="Editar" />
                        </MenuItem>
                        <MenuItem>
                          <ListItemText primary="Eliminar" />
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={groups.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

ClassGroups.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.array
};

ClassGroups.defaultProps = {
  groups: []
};

export default ClassGroups;
