import React, { useState } from 'react';
import {
  Box, Grid, Tabs, Tab, Divider, Typography, colors,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import SettingsApp from './SettingsApp';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const initialRecordState = {
    total_artes: 40
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page
      className={classes.root}
      title="Configuración"
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box
              display="flex"
              justifyContent="flex-start"
            >
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                Configuraciones
              </Typography>
            </Box>
            <Box
              mt={0}
              display="flex"
              justifyContent="flex-start"
              alignItems="left"
            >
            </Box>
          </Grid>
        </Grid>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          onChange={handleChange}
          aria-label="disabled tabs">
          <Tab label="General" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {value === 0 && <SettingsApp/>}
        </div>
      </Container>
    </Page>
  );
};

export default SettingsView;
