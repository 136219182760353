import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Collapse,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  IconButton,
  makeStyles,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SAVE_SETTINGS, GET_SETTINGS } from "src/services/queries";
import {
  SAVE_SETTINGS_ARTWORKS,
  SAVE_SETTINGS_STORE
} from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles({
  root: {}
});

const SettingsApp = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef1 = useRef();
  const formRef2 = useRef();

  const [openAlert, setOpenAlert] = useState(false);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    store: 0,
    artworks: 0
  };

  const initialRecordStateStore = {
    id: 1
  };
  const [values, setValues] = useState(initialRecordState);
  //const [saveSettings, setSaveSettings] = useState([]);
  const [artworks, setArtOptions] = useState([]);
  const [store, setStoreOptions] = useState([]);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);

  const [saveArtworks, resultsaveArtworks] = useMutation(
    SAVE_SETTINGS_ARTWORKS,
    {
      onCompleted(data) {
        console.log("SAVE_SETTINGS_ARTWORKS", data.saveSettingsArtworks);
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: "Se ha guardado correctamente el orden de las obras"
        });
        fetchOrderArtOptions();
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(false);
        fetchOrderStoreOptions();
      }
    }
  );

  const [saveStore, resultsaveStore] = useMutation(SAVE_SETTINGS_STORE, {
    onCompleted(data) {
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: "Se ha guardado correctamente el orden de las obras"
      });
      fetchOrderArtOptions();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(false);
      fetchOrderStoreOptions();
    }
  });

  const [getSaveSettings, resultSaveSettings] = useLazyQuery(
    GET_SAVE_SETTINGS,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        if (result && result.settings) {
          console.log("GET_SAVE_SETTINGS", result.settings);
          setValues({
            artworks: result.settings.artworks_id,
            store: result.settings.store_id
          });
        }
      }
    }
  );

  const [getSettings, resultSettings] = useLazyQuery(GET_SETTINGS, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result && result.artworks) {
        console.log("GET_SETTINGS", result.artworks);
        setArtOptions(result.artworks);
        setStoreOptions(result.store);
      }
    }
  });

  const handleChangeArtworks = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeStore = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    //console.log(values);
  }, []);

  /**
   * updateOrderArtwork
   */
  const updateOrderArtwork = values => {
    console.log(values);
    saveArtworks({
      variables: {
        artworks_id: parseInt(values.artworks)
      }
    });
  };

  /**
   * updateOrderShop
   */
  const updateOrderShop = values => {
    console.log(values);
    saveStore({
      variables: {
        store_id: parseInt(values.store)
      }
    });
  };

  const fetchOrderArtOptions = () => {
    getSettings();
    getSaveSettings();
  };

  const fetchOrderStoreOptions = () => {};

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchOrderArtOptions();
    //fetchOrderStoreOptions();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!artworks && !store && !values) return null;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Formik
            innerRef={formRef1}
            enableReinitialize
            initialValues={values}
            validationSchema={Yup.object().shape({
              artworks: Yup.string().required("Campo requerido.")
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateOrderArtwork(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                onSubmit={handleSubmit}
                className={clsx(classes.root, className)}
                {...rest}
                noValidate
              >
                <Collapse in={openAlert}>
                  <Alert
                    severity={errorMsg.type}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {errorMsg.message}
                  </Alert>
                  <Box p={1}></Box>
                </Collapse>
                <Card>
                  <CardHeader title="Ordenamiento obras" />
                  {resultsaveArtworks.loading && <LinearProgress />}
                  <Divider />
                  <CardContent>
                    <TextField
                      fullWidth
                      label="Ordenamiento obra"
                      name="artworks"
                      error={Boolean(touched.artworks && errors.artworks)}
                      helperText={touched.artworks && errors.artworks}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.artworks}
                      variant="outlined"
                    >
                      <option key={0} value=""></option>
                      {artworks.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </CardContent>
                  <Divider />
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={
                        isSubmitting && resultsaveArtworks.loading
                          ? true
                          : false
                      }
                    >
                      GUARDAR
                    </Button>
                  </Box>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Formik
            innerRef={formRef2}
            enableReinitialize
            initialValues={values}
            validationSchema={Yup.object().shape({
              store: Yup.string().required("Campo requerido.")
            })}
            onSubmit={values => {
              updateOrderShop(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                onSubmit={handleSubmit}
                className={clsx(classes.root, className)}
                {...rest}
                noValidate
              >
                <Card>
                  <CardHeader title="Ordenamiento tienda" />
                  {resultSaveSettings.loading && <LinearProgress />}
                  <Divider />
                  <CardContent>
                    <TextField
                      fullWidth
                      label="Ordenamiento tienda"
                      name="store"
                      error={Boolean(touched.store && errors.store)}
                      helperText={touched.store && errors.store}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.store}
                      variant="outlined"
                    >
                      <option key={0} value=""></option>
                      {store.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </CardContent>
                  <Divider />
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={
                        isSubmitting && resultsaveArtworks.loading
                          ? true
                          : false
                      }
                    >
                      GUARDAR
                    </Button>
                  </Box>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

SettingsApp.propTypes = {
  className: PropTypes.string
};

export default SettingsApp;
