import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Link,
  Checkbox,
  colors,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";
import { GET_ARTWORKS, GET_STYLES } from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_ARTWORKS_FOR_SHOP } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "338px",
    height: "258px"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  textLinkUpload: {
    color: "#0074BC"
  }
}));

const AddNewArt = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    obra: "",
    disponible: 0,
    precio: "",
    prioridad: ""
  };

  const obras = [
    {
      id: "",
      nombre: ""
    }
  ];

  const [values, setValues] = useState(initialRecordState);
  const [arts, setArts] = useState(obras);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [getArt, resultArt] = useLazyQuery(GET_ARTWORKS, {
    onCompleted: result => {
      setArts(result.artworks);
    },
    fetchPolicy: "no-cache"
  });

  const [addMutation, resultAddMutation] = useMutation(
    CREATE_ARTWORKS_FOR_SHOP,
    {
      onCompleted(data) {
        console.log(data);
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
        });
        setValues(initialRecordState);
        fetchAllArts();

        formRef.current?.resetForm();
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(true);
        setValues(initialRecordState);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
        });
      }
    }
  );

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    });
  };

  /**
   * saveNewArt
   */
  const saveNewArt = values => {
    console.log({
      id: parseInt(values.obra),
      available: values.disponible == true ? 1 : 0,
      gems: parseInt(values.precio),
      priority: parseInt(values.prioridad)
    });
    addMutation({
      variables: {
        id: parseInt(values.obra),
        available: values.disponible == true ? 1 : 0,
        gems: parseInt(values.precio),
        priority: parseInt(values.prioridad)
      }
    });
  };

  /**
   * Get All
   */
  const fetchAllArts = () => {
    getArt();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAllArts();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} xs={12}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialRecordState}
          validationSchema={Yup.object().shape({
            obra: Yup.string().required("La obra es requerida."),
            precio: Yup.number()
              .typeError("Solo se permiten números.")
              .positive("Solo número positivo.")
              .integer("Solo se permiten enteros.")
              .required("El precio es requerido."),
            prioridad: Yup.number()
              .typeError("Solo se permiten números.")
              .positive("Solo número positivo.")
              .integer("Solo se permiten enteros.")
              .required("La prioriudad es requerida.")
          })}
          onSubmit={values => {
            saveNewArt(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              encType="multipart/form-data"
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader title="Información de la obra" />
                {resultAddMutation.loading && <LinearProgress />}
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Obra"
                        name="obra"
                        error={Boolean(touched.obra && errors.obra)}
                        helperText={touched.obra && errors.obra}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.obra}
                        variant="outlined"
                      >
                        <option key={""} value={""}></option>
                        {arts.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box alignItems="center" display="flex" ml={-1}>
                        <Checkbox
                          checked={values.disponible || false}
                          color="secondary"
                          name="disponible"
                          error={Boolean(
                            touched.disponible && errors.disponible
                          )}
                          helperText={touched.disponible && errors.disponible}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <Typography color="textSecondary" variant="body1">
                          Disponible
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Precio"
                        name="precio"
                        error={Boolean(touched.precio && errors.precio)}
                        helperText={touched.precio && errors.precio}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={values.precio}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Prioridad"
                        name="prioridad"
                        error={Boolean(touched.prioridad && errors.prioridad)}
                        helperText={touched.prioridad && errors.prioridad}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={values.prioridad}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting && resultAddMutation.loading ? true : false
                    }
                  >
                    GUARDAR
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

AddNewArt.propTypes = {
  className: PropTypes.string
};

export default AddNewArt;
