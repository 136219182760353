import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Box,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemText,
  TextField,
  Grid,
  LinearProgress
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ACADEMIC_LEVELS } from "src/services/queries";
import {
  CREATE_ACADEMIC_LEVEL,
  DELETE_ACADEMIC_LEVEL
} from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1"
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const AcademicLevelList = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  const [academiclevels, setAcademicLevels] = useState([]);
  const [totalAcademicLevels, setTotalAcademicLevels] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nivel: ""
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    level: "",
    detail: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [getLevels, resultLevels] = useLazyQuery(ACADEMIC_LEVELS);
  const [levelMutation, resultLevelMutation] = useMutation(
    CREATE_ACADEMIC_LEVEL,
    {
      refetchQueries: [{ query: ACADEMIC_LEVELS }],
      onCompleted(data) {
        console.log("CREATE_LEVEL");
        console.log(data);
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
        });
        setValues(initialRecordState);
        fetchAll();

        formRef.current?.resetForm();
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(true);
        setValues(initialRecordState);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
        });
      }
    }
  );

  const [deleteLevelMutation, resultDeleteLevelMutation] = useMutation(
    DELETE_ACADEMIC_LEVEL,
    {
      refetchQueries: [{ query: ACADEMIC_LEVELS }],
      onCompleted(data) {
        console.log("DELETE_LEVEL");
        console.log(data);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
        });
        openCloseModalDelete();
        fetchAll();
      },
      onError(error) {
        console.log(error);
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
        });
        openCloseModalDelete();
      }
    }
  );

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, academiclevels.length - page * rowsPerPage);

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value == SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      items.sort((a, b) => a["name"].localeCompare(b["name"]));
    }

    if (value == SYSTEM_MESSAGES.MOST_RECENT) {
      items.sort((a, b) => {
        const aDate = a.fecha_agregado
          ? new Date(a.fecha_agregado)
          : new Date(a.fecha_agregado);
        const bDate = b.fecha_agregado
          ? new Date(b.fecha_agregado)
          : new Date(b.fecha_agregado);
        return bDate.getTime() - aDate.getTime();
      });
    }
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * newAcademicLevel
   */
  const newAcademicLevel = values => {
    console.log(values);

    levelMutation({
      variables: {
        level: values.level,
        detail: values.detail
      }
    });
  };

  /**
   * Get All
   */
  const fetchAll = () => {
    let isLoaded = true;

    getLevels();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAll();

    return () => {
      isLoaded = false;
    };
  }, []);

  useEffect(() => {
    if (resultLevels.data) {
      console.log(resultLevels.data.AcademicLevels);
      setAcademicLevels(resultLevels.data.AcademicLevels);
      setTotalAcademicLevels(resultLevels.data.AcademicLevels.length);
    }
  }, [resultLevels]);

  /**
   * deleteAcademic
   */
  const deleteAcademic = id => {
    deleteLevelMutation({
      variables: {
        id
      }
    });
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nivel}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteAcademic(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={6} md={6} xs={12}>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={values}
            validationSchema={Yup.object().shape({
              level: Yup.string().required("El nivel es requerido."),
              detail: Yup.string().required("El detalle es requerido.")
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              newAcademicLevel(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                <Card>
                  <CardHeader title="Agregar Nivel Académico" />
                  {resultLevelMutation.loading && <LinearProgress />}
                  <Divider />
                  <CardContent>
                    <Collapse in={openAlert}>
                      <Alert
                        severity={errorMsg.type}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenAlert(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsg.message}
                      </Alert>
                      <Box p={1}></Box>
                    </Collapse>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Nivel"
                          name="level"
                          type="text"
                          error={Boolean(touched.level && errors.level)}
                          helperText={touched.level && errors.level}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.level}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Detalle"
                          name="detail"
                          type="text"
                          error={Boolean(touched.detail && errors.detail)}
                          helperText={touched.detail && errors.detail}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.detail}
                          variant="outlined"
                          placeholder="Detalle..."
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={
                        isSubmitting && resultLevelMutation.loading
                          ? true
                          : false
                      }
                    >
                      AGREGAR
                    </Button>
                  </Box>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Box p={1}></Box>
          <Card className={clsx(classes.root, className)} {...rest}>
            {/* Modal Delete */}
            <Modal open={modalEliminar} onClose={openCloseModalDelete}>
              {bodyModalDialogConfirmDelete}
            </Modal>
            <CardHeader
              action={
                <Box alignItems="center" display="flex">
                  <Typography align="left" color="textPrimary" variant="h6">
                    Mostrar por:
                  </Typography>
                  <Button
                    className={classes.sortButton}
                    onClick={handleSortOpen}
                    ref={sortRef}
                  >
                    {selectedSort}
                    <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    anchorEl={sortRef.current}
                    className={classes.menu}
                    onClose={handleSortClose}
                    open={openSort}
                  >
                    {[
                      SYSTEM_MESSAGES.MOST_RECENT,
                      SYSTEM_MESSAGES.ALPHABETIC_ORDER
                    ].map(option => (
                      <MenuItem
                        className={classes.menuItem}
                        key={option}
                        onClick={() => handleSortSelect(option, academiclevels)}
                      >
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              }
              title={
                <Box display="flex">
                  <Typography
                    className={classes.card_header_title}
                    variant={"h6"}
                    gutterBottom
                  >
                    Niveles &nbsp;
                  </Typography>
                  <Typography
                    className={classes.card_subtitle_total}
                    variant={"h6"}
                    gutterBottom
                  >
                    {totalAcademicLevels ? totalAcademicLevels : 0} total
                  </Typography>
                </Box>
              }
            />
            {resultLevels.loading && <LinearProgress />}
            <Divider />
            <PerfectScrollbar>
              <Box minWidth={800}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nivel</TableCell>
                      <TableCell>Detalle</TableCell>
                      <TableCell>Fecha Agregada</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {academiclevels
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(item => (
                        <TableRow hover key={item.id}>
                          <TableCell>
                            <Box alignItems="center" display="flex">
                              <Avatar className={classes.avatar_group}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/static/images/ic_school_avatar.png"
                                  }
                                ></img>
                              </Avatar>
                              <Typography color="textPrimary" variant="body1">
                                <Link
                                  className={classes.text}
                                  to={
                                    "/admin/system/academic-level/" +
                                    `${item.id}` +
                                    "/detail"
                                  }
                                >
                                  {item.level}
                                </Link>
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{item.detail ? item.detail : 0}</TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {item.createdAt
                                ? Utils.formatDateShort(item.createdAt)
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <MenuMoreActions data={item} />
                            <Link
                              className={classes.text}
                              to={
                                "/admin/system/academic-level/" +
                                `${item.id}` +
                                "/detail"
                              }
                            >
                              <IconButton edge="end" size="small">
                                <ArrowForwardIcon />
                              </IconButton>
                            </Link>
                            <Menu
                              anchorEl={moreRef.current}
                              keepMounted
                              onClose={handleMenuClose}
                              open={openMenu}
                            >
                              <MenuItem>
                                <ListItemText primary="Editar" />
                              </MenuItem>
                              <MenuItem>
                                <ListItemText primary="Eliminar" />
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <TablePagination
              component="div"
              count={academiclevels.length}
              onChangePage={handlePageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
            />
            <Box display="flex" justifyContent="flex-end" p={2}></Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

AcademicLevelList.propTypes = {
  className: PropTypes.string,
  academiclevels: PropTypes.array
};

AcademicLevelList.defaultProps = {
  academiclevels: []
};

export default AcademicLevelList;
