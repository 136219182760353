import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Box, Button, Collapse, Card, CardContent, CardHeader, Divider, Grid, TextField, makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ApiService from 'src/services/ApiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const NewGroupClass = ({ className, ...rest }) => {
  const classes = useStyles();
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selectGrado, setGradoSeleccionado] = useState({id: 0, grado: 'Seleccione...'});
  const [selectGrupo, setGrupoSeleccionado] = useState({id: 0, grupo: 'Seleccione...'});
  const [selectTurno, setTurnoSeleccionado] = useState({id: 0, turno: 'Seleccione...'});
  const initialRecordState = {
    grado: 0,
    grupo: 0,
    turno: 0
  };
  const [values, setValues] = useState(initialRecordState);
  const [openAlert, setOpenAlert] = useState(false);

  const handleChangeGrado = (event) => {
    setGradoSeleccionado({...selectGrado, id: event.target.value});
  };

  const handleChangeGrupo = (event) => {
    setGrupoSeleccionado({...selectGrupo, id: event.target.value});
  };

  const handleChangeTurno = (event) => {
    setTurnoSeleccionado({...selectTurno, id: event.target.value});
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveNewClassroom
   */
  const saveNewClassroom = () => {
    let data = {
      grado: values.grado,
      grupo: values.grupo,
      turno: values.turno,
      user_id: 1
    };

    ApiService.newClassRoom(data).then(response => {
      if(response){
        setSubmitted(true);
        setOpenAlert(true);
        setValues(initialRecordState);
      }
    }).catch(err => {
      console.log(err);
      setSubmitted(false);
      setOpenAlert(false);
      setValues(initialRecordState);
    });
  };

  /**
   * Fetch API
   */
   useEffect(() => {
    let isLoaded = true;

    /* Get Grados */
    const fetchAllGrados = () => {
      ApiService.getGrados().then(response => {
        if (isLoaded) {
          setGrados(response.data);
        }
      }).catch(e => {
      });
    };

    /* Get Grupos */
    const fetchAllGrupos = () => {
      ApiService.getGrupos().then(response => {
        if (isLoaded) {
          setGrupos(response.data);
        }
      }).catch(e => {
      });
    };

    /* Get Turnos */
    const fetchAllTurnos = () => {
      ApiService.getTurnos().then(response => {
        if (isLoaded) {
          setTurnos(response.data);
        }
      }).catch(e => {
      });
    };

    fetchAllGrados();
    fetchAllGrupos();
    fetchAllTurnos();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
      <Card>
        <CardHeader
          title="Grupo de Clase"
        />
        <Divider />
        <CardContent>
          <Collapse in={openAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              El nuevo grupo de clase se ha creado correctamente.
            </Alert>
            <Box p={1}></Box>
          </Collapse>
        <form autoComplete="off" className={clsx(classes.root, className)}
        {...rest}>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Grado"
                name="grado"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.grado}
                variant="outlined"
              >
                <option
                  key={selectGrado.id}
                  value={selectGrado.id}
                >
                  {selectGrado.grado}
                </option>
                {grados.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.grado}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Grupo"
                name="grupo"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.grupo}
                variant="outlined"
              >
                <option
                  key={selectGrupo.id}
                  value={selectGrupo.id}
                >
                  {selectGrupo.grupo}
                </option>
                {grupos.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.grupo}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Turno"
                name="turno"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.turno}
                variant="outlined"
              >
                <option
                  key={selectTurno.id}
                  value={selectTurno.id}
                >
                  {selectTurno.turno}
                </option>
                {turnos.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                  >
                    {option.turno}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Box my={2}>
            <Divider />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            p={1}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={saveNewClassroom}
            >
              CREAR
            </Button>
          </Box>
          </form>
   
        </CardContent>
      </Card>
  );
};

NewGroupClass.propTypes = {
  className: PropTypes.string
};

export default NewGroupClass;
