import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Box,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemText,
  Tooltip,
  LinearProgress
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import { useLazyQuery, useMutation } from "@apollo/client";
import { STUDENTS_BY_SCHOOL } from "src/services/queries";
import { DELETE_SCHOOL_STUDENT } from "src/services/mutations";
import AvatarUser from "src/components/AvatarUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const PATH_URL = API_URL;

const mock = [
  {
    id: 1,
    nombre: "Mario López",
    edad: 10,
    grado: "4to Grado B",
    total_asignaciones: 16,
    entregadas: 15,
    pendientes: 15,
    vencidas: 4,
    puntos: 650,
    gemas: 250
  }
];

const StudentsList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [students, setStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );
  /* school id */
  let { schoolId } = useParams();

  const [getStudents, resultGetStudents] = useLazyQuery(STUDENTS_BY_SCHOOL, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result) {
        if (result.allStudentsBySchool) {
          console.log("RESULTADO: getStudents", result.allStudentsBySchool);
          setStudents(result.allStudentsBySchool);
          setTotalStudents(result.allStudentsBySchool.length);
          setSelectedSort(SYSTEM_MESSAGES.ALPHABETIC_ORDER);
        }
      }
    }
  });

  const [deleteMutation, resultDeleteMutation] = useMutation(
    DELETE_SCHOOL_STUDENT,
    {
      onCompleted(data) {
        setOpenAlert(true);
        openCloseModalDelete();
        fetchStudents();
      },
      onError(error) {
        console.log(error);
        setOpenAlert(false);
        openCloseModalDelete();
      }
    }
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, students.length - page * rowsPerPage);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setStudents(
        [...items].sort((a, b) =>
          a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setStudents(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const fetchStudents = () => {
    getStudents({
      variables: {
        school_id: schoolId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchStudents();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * deleteStudent
   */
  const deleteStudent = id => {
    console.log(id);
    deleteMutation({
      variables: {
        id
      }
    });
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteStudent(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      {/* Modal Delete */}
      <Modal open={modalEliminar} onClose={openCloseModalDelete}>
        {bodyModalDialogConfirmDelete}
      </Modal>
      <CardHeader
        action={
          <Box alignItems="center" display="flex">
            <Typography align="left" color="textPrimary" variant="h6">
              Mostrar por:
            </Typography>
            <Button
              className={classes.sortButton}
              onClick={handleSortOpen}
              ref={sortRef}
            >
              {selectedSort}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={sortRef.current}
              className={classes.menu}
              onClose={handleSortClose}
              open={openSort}
            >
              {[
                SYSTEM_MESSAGES.ALPHABETIC_ORDER,
                SYSTEM_MESSAGES.MOST_RECENT
              ].map(option => (
                <MenuItem
                  className={classes.menuItem}
                  key={option}
                  onClick={() => handleSortSelect(option, students)}
                >
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Estudiantes &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalStudents ? totalStudents : 0} total
            </Typography>
          </Box>
        }
      />
      {/* Alert Success Delete | default hide */}
      <Collapse in={openAlert}>
        <Alert
          severity={errorMsg.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {errorMsg.message}
        </Alert>
        <Box p={1}></Box>
      </Collapse>
      {resultGetStudents.loading && <LinearProgress />}
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Estudiante</TableCell>
                <TableCell>Edad</TableCell>
                {/* <TableCell>
                  Grado
                </TableCell> */}
                <TableCell>Asignaciones</TableCell>
                <TableCell>Entregadas</TableCell>
                <TableCell>Pendientes</TableCell>
                <TableCell>Vencidas</TableCell>
                <TableCell>Puntos</TableCell>
                <TableCell>Gemas</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <AvatarUser id={item.id} />
                        <Typography color="textPrimary" variant="body1">
                          <Link
                            className={classes.text}
                            to={
                              "/admin/school/" +
                              `${schoolId}` +
                              "/student/" +
                              `${item.id}` +
                              "/detail"
                            }
                          >
                            {item.first_name}
                          </Link>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{item.age ? item.age : 0}</TableCell>
                    {/* <TableCell>
                    {item.grado ? item.grado : 0}
                  </TableCell> */}
                    <TableCell>
                      {item.assignment ? item.assignment : 0}
                    </TableCell>
                    <TableCell>{item.delivery ? item.delivery : 0}</TableCell>
                    <TableCell>{item.pending ? item.pending : 0}</TableCell>
                    <TableCell>{item.overdue ? item.overdue : 0}</TableCell>
                    <TableCell>{item.points ? item.points : 0}</TableCell>
                    <TableCell>{item.gems ? item.gems : 0}</TableCell>
                    <TableCell>
                      <IconButton edge="end" size="small" onClick={handleClick}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClosePopover}
                        PaperProps={{
                          style: {
                            width: "20ch"
                          }
                        }}
                      >
                        <MenuItem onClick={handleClosePopover}>Editar</MenuItem>
                        <MenuItem
                          key={item.id}
                          onClick={() => selectRecordToDelete(item, "Eliminar")}
                        >
                          Eliminar
                        </MenuItem>
                      </Menu>
                      <Link
                        className={classes.text}
                        to={
                          "/admin/school/" +
                          `${schoolId}` +
                          "/student/" +
                          `${item.id}` +
                          "/detail"
                        }
                      >
                        <IconButton edge="end" size="small">
                          <ArrowForwardIcon />
                        </IconButton>
                      </Link>
                      <Menu
                        anchorEl={moreRef.current}
                        keepMounted
                        onClose={handleMenuClose}
                        open={openMenu}
                      >
                        <MenuItem>
                          <ListItemText primary="Editar" />
                        </MenuItem>
                        <MenuItem>
                          <ListItemText primary="Eliminar" />
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={students.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

StudentsList.propTypes = {
  className: PropTypes.string,
  students: PropTypes.array
};

StudentsList.defaultProps = {
  students: []
};

export default StudentsList;
