import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Divider,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Modal,
  CircularProgress
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import { GET_AVATARS } from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_AVATAR } from "src/services/mutations";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  media: {
    height: "345px",
    margin: "10px",
    paddingTop: "56.25%"
  },
  actions: {
    justifyContent: "flex-end"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  title_art: {
    textAlign: "center"
  },
  titleDiamond: {
    color: "#0074BC"
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 35,
    marginTop: 35
  }
}));

const PATH_URL = API_URL;

const mock = [
  {
    id: 1,
    nombre: "Díptico de Marilyn",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 2,
    nombre: "La Noche Estrellada",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 3,
    nombre: "La Mona Lisa",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  }
];

const AvatarList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [avatars, setAvatars] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const initialRecordState = {
    nombre: "",
    apellido: "",
    puntos: 660,
    gemas: 18
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const [getAvatars, resultAvatars] = useLazyQuery(GET_AVATARS, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getAvatars");
      console.log(result);

      if (result) {
        if (result.avatars) {
          setAvatars(result.avatars);
          setDashboard(result.avatars);
        }
      }
    }
  });

  const [avatarMutation, resultAvatarMutation] = useMutation(DELETE_AVATAR, {
    onCompleted(data) {
      console.log("UPDATE_ARTIST");
      console.log(data);
      setOpenAlert(true);
      setAvatars(avatars.filter(item => item.id !== itemSelected.id));
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
      });
      openCloseModalDelete();
      fetchAllAvatars();
    },
    onError(error) {
      console.log(error);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
      });
      openCloseModalDelete();
    }
  });

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClose()}>
            <Link
              className={classes.text}
              to={"/admin/store/avatar/" + `${data.id}` + "/edit"}
            >
              Editar
            </Link>
          </MenuItem>
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * deleteAvatar
   */
  const deleteAvatar = id => {
    avatarMutation({
      variables: {
        id
      }
    });
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteAvatar(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  const fetchAllAvatars = () => {
    getAvatars();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAllAvatars();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!avatars) return false;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              href={"/admin/store/avatar/new-avatar"}
            >
              <AddIcon /> AGREGAR AVATAR
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}></Box>
      {/* Modal Delete */}
      <Modal open={modalEliminar} onClose={openCloseModalDelete}>
        {bodyModalDialogConfirmDelete}
      </Modal>
      {/* Alert Success Delete | default hide */}
      <Collapse in={openAlert}>
        <Alert
          severity={errorMsg.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {errorMsg.message}
        </Alert>
        <Box p={1}></Box>
      </Collapse>
      {resultAvatars.loading ? (
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={3}>
          {avatars &&
            avatars.map((item, index) => (
              <Grid item lg={3} md={6} xs={12} key={item.id}>
                <Card className={classes.root}>
                  <CardMedia
                    className={classes.media}
                    image={item.image}
                    title=""
                  />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item lg={9} md={6} xs={12}>
                        <Typography
                          className={classes.title_art}
                          variant="h3"
                          color="textPrimary"
                          component="p"
                        >
                          <Link
                            to={"/admin/store/avatar/" + `${item.id}` + "/edit"}
                          >
                            {item.title}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item lg={3} md={6} xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                          <MenuMoreActions data={item} />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={2}
                  >
                    <Typography
                      className={classes.titleDiamond}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/static/images/ic_diamond_store.png"
                        }
                      ></img>{" "}
                      {item.gems ? item.gems : 0}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
    </div>
  );
};

AvatarList.propTypes = {
  className: PropTypes.string,
  avatars: PropTypes.array.isRequired
};

AvatarList.defaultProps = {
  avatars: []
};

export default AvatarList;
