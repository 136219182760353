const environment = {
    localhost: "http://localhost:8000",
    develop: "http://localhost:8000",
    testing: "http://localhost:8000",
    staging: "http://localhost:8000",
    production: "http://localhost:8000"
};

const API_URL = environment.localhost;

export default API_URL;
    