import React, { Fragment, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Link,
  Checkbox,
  colors,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import { useMutation } from "@apollo/client";
import { ADD_AVATAR } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  textLinkUpload: {
    color: "#0074BC"
  }
}));

const AddNewAvatar = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* Id classroom */
  let { classroomId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    title: "",
    available: false,
    gems: "",
    priority: ""
  };

  const [values, setValues] = useState([]);
  const [imageUpload, setImageUpload] = useState("");
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [artistMutation, resultArtistMutation] = useMutation(ADD_AVATAR, {
    onCompleted(data) {
      console.log("UPDATE_ARTIST");
      console.log(data);
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: "Registro agregado correctamente"
      });
      setValues([]);
      handleRemoveAll();

      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
      setValues([]);
      handleRemoveAll();
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    });
  };

  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles.find(f => f);

    setFiles(acceptedFiles);
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {};

    if (!Utils.validateFileImage(file)) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
      });
      handleRemoveAll();
    } else {
      setImageUpload(file);
    }
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
    setImageUpload("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false
  });

  /**
   * saveNewAvatar
   */
  const saveNewAvatar = values => {
    let available = values.available == false ? 0 : 1;

    if (imageUpload.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    console.log(values);

    artistMutation({
      variables: {
        title: values.title,
        gems: parseInt(values.gems),
        priority: parseInt(values.priority),
        available: parseInt(available),
        image: imageUpload
      }
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} md={6} xs={12}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialRecordState}
          validationSchema={Yup.object().shape({
            title: Yup.string().required("El título es requerido."),
            gems: Yup.number()
              .typeError("Solo se permiten números.")
              .positive("Solo número positivo.")
              .integer("Solo se permiten enteros.")
              .required("El precio es requerido."),
            priority: Yup.number()
              .typeError("Solo se permiten números.")
              .positive("Solo número positivo.")
              .integer("Solo se permiten enteros.")
              .required("La prioriudad es requerida.")
          })}
          onSubmit={values => {
            saveNewAvatar(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              encType="multipart/form-data"
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader title="Información del avatar" />
                {resultArtistMutation.loading && <LinearProgress />}
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Titulo"
                        name="title"
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={values.title}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box alignItems="center" display="flex" ml={-1}>
                        <Checkbox
                          checked={values.available || false}
                          color="secondary"
                          name="available"
                          error={Boolean(touched.available && errors.available)}
                          helperText={touched.available && errors.available}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <Typography color="textSecondary" variant="body1">
                          Disponible
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Precio"
                        name="gems"
                        error={Boolean(touched.gems && errors.gems)}
                        helperText={touched.gems && errors.gems}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={values.gems}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Prioridad"
                        name="priority"
                        error={Boolean(touched.priority && errors.priority)}
                        helperText={touched.priority && errors.priority}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={values.priority}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box p={1}></Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting && resultArtistMutation.loading
                        ? true
                        : false
                    }
                  >
                    GUARDAR
                  </Button>
                </Box>
                <Box p={1}></Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <form
          autoComplete="off"
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader title="Cargar imagen" />
            <Divider />
            <CardContent>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                className={clsx({
                  [classes.dropZone]: true,
                  [classes.dragActive]: isDragActive
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div>
                  <img
                    alt="Seleccionar archivo"
                    className={classes.image}
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/ic_upload_file.png"
                    }
                  />
                </div>
                <Typography gutterBottom variant="h3">
                  Agregar archivo
                </Typography>
                <Typography
                  className={classes.info}
                  color="textSecondary"
                  variant="body1"
                >
                  Suelta los archivos aquí o haz clic en <br></br>{" "}
                  <Link underline="always" className={classes.textLinkUpload}>
                    cargar
                  </Link>{" "}
                  desde tu dispositivo
                </Typography>
                <Typography gutterBottom variant="h6">
                  Dimensiones: 512px x 512px
                </Typography>
                {files.length > 0 && (
                  <Fragment>
                    {files.map((file, i) => (
                      <Typography
                        key={i}
                        className={classes.info}
                        color="textSecondary"
                        variant="body1"
                      >
                        {file.name} - {bytesToSize(file.size)}
                      </Typography>
                    ))}
                  </Fragment>
                )}
              </Box>
            </CardContent>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};

AddNewAvatar.propTypes = {
  className: PropTypes.string
};

export default AddNewAvatar;
