import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Box, Container, Grid, makeStyles, Breadcrumbs, Link, Typography, FormControl, MenuItem, Select } from '@material-ui/core';
import Page from 'src/components/Page';
import AdminUsersList from './AdminUsersList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%'
  },
  containerWelcome: {
    textAlign: 'left',
    marginBottom: '20px'
  },
  welcomeMainText: {
    color: '#212529',
    fontSize: '24px',
    fontWeight: "500"
  },
  subtitleWelcomeText: {
    color: '#000000',
    fontSize: '16px',
    fontWeight: "400"
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();

  const handleClick = (event) => {
    event.preventDefault();
  }

  return (
    <Page
      className={classes.root}
      title="Usuarios Administradores"
    >
      <Container maxWidth={false}>
        <Box mb={2}>
        </Box>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
          <div className={classes.containerWelcome}>
            <Typography
              align="left"
              color="textPrimary"
              variant="body1" className={classes.welcomeMainText}>
                Administradores
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              variant="body1" className={classes.subtitleWelcomeText}>
                Kulture 4 Kids
            </Typography>
          </div>
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <AdminUsersList />
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
