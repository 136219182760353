import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  colors
} from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ApiService from "src/services/ApiService";
import { useLazyQuery } from "@apollo/client";
import { TEACHERS_COUNT } from "src/services/queries";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  }
}));

const TotalTeachers = ({ className, ...rest }) => {
  const classes = useStyles();
  const initialRecordState = {
    docentes: 0,
    nuevos: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const [getUserCount, resultUserCount] = useLazyQuery(TEACHERS_COUNT);
  const [teacherCount, setTeacherCount] = useState(0);

  useEffect(() => {
    if (resultUserCount.data) {
      console.log(resultUserCount.data.teachers);
      setTeacherCount(resultUserCount.data.teachers);
    }
  }, [resultUserCount]);

  /**
   * Fetch API
   */
  useEffect(() => {
    getUserCount();

    let isLoaded = true;

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL DOCENTES
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {teacherCount ? teacherCount : 0}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AccountCircleOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <Typography color="textSecondary" variant="caption">
            {teacherCount ? teacherCount : 0}
          </Typography>
          &nbsp;
          <Typography color="textSecondary" variant="caption">
            Docentes ({teacherCount ? teacherCount : 0} Nuevos esta semana)
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

TotalTeachers.propTypes = {
  className: PropTypes.string
};

export default TotalTeachers;
