import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Link,
  colors,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import { UPDATE_SCHOOL } from "src/services/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  COUNTRIES,
  SCHOOLS_COUNT_BY_ID_AND_ROLE,
  SCHOOL_BY_ID
} from "src/services/queries";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px",
    margin: "auto"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },
  textLinkUpload: {
    color: "#0074BC"
  },
  title_head_name: {
    color: "#212529"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  },
  avatar_teacher: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  avatar_student: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const initialRecordState = {
  name: "",
  email: "",
  address: "",
  countries_id: "",
  city: "",
  phone: ""
};

const InfoSchool = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* school id */
  let { schoolId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };

  const [getSchool, resultSchool] = useLazyQuery(SCHOOL_BY_ID);
  const [school, setSchool] = useState(0);

  const [initialValues, setInitialValues] = useState(initialRecordState);

  const [getTeachersCount, resultTeachersCount] = useLazyQuery(
    SCHOOLS_COUNT_BY_ID_AND_ROLE
  );
  const [teachersCount, setTeachersCount] = useState(0);

  const [getStudentsCount, resultStudentsCount] = useLazyQuery(
    SCHOOLS_COUNT_BY_ID_AND_ROLE
  );
  const [studentsCount, setStudentsCount] = useState(0);

  useEffect(() => {
    if (resultTeachersCount.data) {
      console.log(
        "getTeachersCount",
        resultTeachersCount.data.userCountBySchoolId
      );
      setTeachersCount(resultTeachersCount.data.userCountBySchoolId);
    }
  }, [resultTeachersCount]);

  useEffect(() => {
    if (resultStudentsCount.data) {
      console.log(
        "getStudentsCount",
        resultStudentsCount.data.userCountBySchoolId
      );
      setStudentsCount(resultStudentsCount.data.userCountBySchoolId);
    }
  }, [resultStudentsCount]);

  useEffect(() => {
    if (resultSchool.data) {
      console.log("resultSchool.data.school", resultSchool.data.school);
      setSchool(resultSchool.data.school);
      setDashboard(resultSchool.data.school);
      setValues(resultSchool.data.school);

      setInitialValues(resultSchool.data.school);
    }
  }, [resultSchool]);

  useEffect(() => {
    getSchool({ variables: { id: schoolId } });
    getTeachersCount({
      variables: {
        school_id: schoolId,
        roleId: 2
      }
    });
    getStudentsCount({
      variables: {
        school_id: schoolId,
        roleId: 3
      }
    });
  }, []);

  const [schoolMutation, resultSchoolMutaion] = useMutation(UPDATE_SCHOOL, {
    onCompleted(data) {
      console.log("UPDATE_SCHOOL");
      console.log(data);

      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
      });
      fetchDetailSchool();

      //formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
      });
      //fetchDetailSchool();
    }
  });

  const [values, setValues] = useState([]);
  const [countries, setCountries] = useState([]);
  const [imageUpload, setImageUpload] = useState("");
  const [dashboard, setDashboard] = useState([]);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [getCountries, resultcountries] = useLazyQuery(COUNTRIES, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result) {
        if (result.countries) {
          setCountries(result.countries);
        }
      }
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [files, setFiles] = useState([]);

  const handleDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles.find(f => f);

    setFiles(acceptedFiles);
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {};

    if (!Utils.validateFileImage(file)) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
      });
      handleRemoveAll();
    } else {
      setImageUpload(file);
    }
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
    setImageUpload("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  /**
   * saveUpdateSchool
   */
  const saveUpdateSchool = values => {
    if (
      values.name == "" ||
      values.email == "" ||
      values.address == "" ||
      values.countries_id == "" ||
      values.city == "" ||
      values.phone == ""
    ) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      const sendData = {
        name: values.name,
        email: values.email,
        address: values.address,
        countries_id: parseInt(values.countries_id),
        city: values.city,
        phone: values.phone,
        image: imageUpload ? imageUpload : null,
        currentImage: values.image,
        id: schoolId
      };
      console.log(sendData);
      schoolMutation({
        variables: sendData
      });
    }
  };

  /**
   * Get All
   */
  const fetchAllCountries = () => {
    getCountries();
  };

  const fetchDetailSchool = () => {
    let isLoaded = true;
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchDetailSchool();
    fetchAllCountries();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!school) return null;

  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      container
      spacing={3}
    >
      <Grid item lg={4} sm={6} xl={3} xs={12} mb={1}>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography
                  className={classes.title_head_name}
                  gutterBottom
                  variant="h2"
                >
                  {dashboard.name}
                </Typography>
              </Grid>
              <Grid item>
                {dashboard.image && (
                  <Avatar className={classes.avatar_teacher}>
                    <img
                      className={classes.avatarItem}
                      src={dashboard.image}
                      width={90}
                      height={90}
                      onError={e => {
                        e.target.onerror = null;
                      }}
                    />
                  </Avatar>
                )}
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography
                className={classes.subtitle_text_card}
                color="textPrimary"
                variant="caption"
              >
                {dashboard.countries_id}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography
                className={classes.subtitle_text_card}
                color="textPrimary"
                variant="caption"
              >
                {dashboard.email}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography
                className={classes.subtitle_text_card}
                color="textPrimary"
                variant="caption"
              >
                {dashboard.phone}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Box p={1}></Box>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  TOTAL DOCENTES
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {teachersCount ? teachersCount : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar_teacher}>
                  <AccountCircleOutlinedIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={2} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {teachersCount ? teachersCount : 0} Grupos de clase
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Box p={1}></Box>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  TOTAL DE ESTUDIANTES
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {studentsCount ? studentsCount : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar_student}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/static/images/ic_school.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={2} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {studentsCount ? studentsCount : 0} Grupos de clase
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Box p={1}></Box>
        <Card>
          <CardHeader title="Cargar imagen" />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br>{" "}
                <Link underline="always" className={classes.textLinkUpload}>
                  cargar
                </Link>{" "}
                desde tu dispositivo
              </Typography>
              <Typography gutterBottom variant="h6">
                Dimensiones: 450px x 450px
              </Typography>
              {files.length > 0 && (
                <Fragment>
                  {files.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={8} sm={6} xs={12} mb={1}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Debe ser un correo electrónico válido")
              .required("El correo electrónico es obligatorio"),
            name: Yup.string().required("El nombre es requerido."),
            address: Yup.string().required("La dirección es requerida."),
            countries_id: Yup.string().required("El país es requerido."),
            city: Yup.string().required("La ciudad es requerida."),
            phone: Yup.string().required("El teléfono es requerido.")
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            saveUpdateSchool(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Card>
                <CardHeader title="Información de la institución" />
                {resultSchoolMutaion.loading && <LinearProgress />}
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nombre de la institución"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        required
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Correo electrónico"
                        name="email"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Dirección"
                        name="address"
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        type="text"
                        value={values.address}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        label="País"
                        name="countries_id"
                        error={Boolean(
                          touched.countries_id && errors.countries_id
                        )}
                        helperText={touched.countries_id && errors.countries_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.countries_id}
                        variant="outlined"
                      >
                        <option key="" value=""></option>
                        {countries.map(option => (
                          <option key={option.code} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Ciudad / Provincia"
                        name="city"
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        type="text"
                        value={values.city}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Teléfono"
                        name="phone"
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        type="text"
                        value={values.phone}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={
                      isSubmitting && resultSchoolMutaion.loading ? true : false
                    }
                    type="submit"
                  >
                    GUARDAR
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

InfoSchool.propTypes = {
  className: PropTypes.string
};

export default InfoSchool;
