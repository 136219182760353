import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardContent, Grid, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: '#00B7BD',
    height: 56,
    width: 56
  }
}));

const BestTime = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              MEJOR TIEMPO
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              10:02
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AccessTimeOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Typography
            color="textSecondary"
            variant="caption"
            mt={2}
          >
            Andrea
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

BestTime.propTypes = {
  className: PropTypes.string
};

export default BestTime;
