import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  colors,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GROUP_SHOW } from "src/services/queries";
import { UPDATE_GROUP } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  title_head_name: {
    color: "#212529"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1",
    height: 56,
    width: 56
  }
}));

const EditSchoolGroup = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();
  /* group id */
  let { schoolGroupId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    name: ""
  };

  const [values, setValues] = useState(initialRecordState);
  const [dashboard, setDashboard] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [getGroup, resultGroup] = useLazyQuery(GROUP_SHOW);
  const [groupMutation, resultGroupMutation] = useMutation(UPDATE_GROUP, {
    onCompleted(data) {
      console.log("UPDATE_GRADE");
      console.log(data);
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
      });
      setValues(initialRecordState);
      fetchGroup();

      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
      });
      setValues(initialRecordState);
    }
  });

  useEffect(() => {
    if (resultGroup.data) {
      console.log(resultGroup.data.group);
      setDashboard(resultGroup.data.group);
      setValues(resultGroup.data.group);
    }
  }, [resultGroup]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveUpdateSchooGroup
   */
  const saveUpdateSchooGroup = values => {
    console.log(values);
    groupMutation({
      variables: {
        name: values.name,
        id: schoolGroupId
      }
    });
  };

  const fetchGroup = () => {
    getGroup({
      variables: {
        id: schoolGroupId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchGroup();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!dashboard) return null;

  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      container
      spacing={3}
    >
      <Grid item lg={4} sm={6} xl={3} xs={12} mb={1}>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography
                  className={classes.title_head_name}
                  gutterBottom
                  variant="h2"
                >
                  {dashboard.name}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar_group}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/ic_school_avatar.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography
                className={classes.subtitle_text_card}
                color="textPrimary"
                variant="caption"
              >
                {dashboard.groupId}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Box p={1}></Box>
      </Grid>
      <Grid item lg={8} sm={6} xs={12} mb={1}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={values}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("El grado es requerido.")
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            saveUpdateSchooGroup(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Card>
                <CardHeader title="Grupo" />
                {resultGroupMutation.loading && <LinearProgress />}
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Grupo"
                        name="name"
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Box p={1}></Box>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting && resultGroupMutation.loading ? true : false
                    }
                  >
                    GUARDAR
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

EditSchoolGroup.propTypes = {
  className: PropTypes.string
};

export default EditSchoolGroup;
