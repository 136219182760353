import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import {
  Box,
  Collapse,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  IconButton,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
/* Services */
import ApiService from 'src/services/ApiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileSecurity = ({ className, ...rest }) => {
  const classes = useStyles();
  /* teacher id */
  let teacherId = 45;
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertDanger, setOpenAlertDanger] = useState(false);
  const initialRecordState = {
    id: teacherId,
    nueva_clave: '',
    confirmar_clave: ''
  };
  const [values, setValues] = useState(initialRecordState);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * updatePassword
   */
   const updatePassword = () => {
    let data = {
      id: teacherId,
      nueva_clave: values.nueva_clave,
      confirmar_clave: values.confirmar_clave
    };

    if(values.nueva_clave === "" || values.confirmar_clave === ""){
      setOpenAlertDanger(true);
    } else {
      ApiService.editTeacherPassword(teacherId, data).then(response => {
        if(response){
          setSubmitted(true);
          setOpenAlert(true);
          setValues(initialRecordState);
        }
      }).catch(err => {
        console.log(err);
        setSubmitted(false);
        setOpenAlert(false);
        setOpenAlertDanger(false);
        setValues(initialRecordState);
      });
    }
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title="Cambiar contraseña"
        />
        <Divider />
        <CardContent>
          {/* Alert Success Delete | default hide */}
          <Collapse in={openAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Tu contraseña se ha actualizado correctamente.
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Collapse in={openAlertDanger}>
            <Alert severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlertDanger(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              El campo contraseña y confirmar contraseña no pueden estar vacios.
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={6}
            >
              <TextField
                fullWidth
                label="Contraseña"
                name="nueva_clave"
                type="password"
                onChange={handleChange}
                required
                value={values.nueva_clave}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={6}
            >
              <TextField
                fullWidth
                label="Confirmar contraseña"
                name="confirmar_clave"
                type="password"
                onChange={handleChange}
                required
                value={values.confirmar_clave}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updatePassword}
          >
            CREAR
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileSecurity.propTypes = {
  className: PropTypes.string
};

export default ProfileSecurity;
