import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Tabs,
  Tab,
  Divider,
  Typography,
  colors,
  makeStyles,
  Breadcrumbs
} from "@material-ui/core";
import Page from "src/components/Page";
import DetailClassGroup from "./DetailClassGroup";
import StudentClassGroupList from "./StudentClassGroupList";
import AssignmentClassGroupList from "./AssignmentClassGroupList";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inner: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  /* school id */
  let { schoolId, tab } = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tab) {
      console.log("tab", tab);
      setValue(parseInt(tab));
    }
  }, [tab]);

  return (
    <Page className={classes.root} title="Detalle del grupo">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className={classes.text}
              to={"/admin/school/" + `${schoolId}` + "/detail"}
            >
              Colegios
            </Link>
            <Link
              className={classes.text}
              to={"/admin/school/" + `${schoolId}` + "/detail/3"}
            >
              Grupos
            </Link>
            <Typography color="textPrimary">Detalle del grupo</Typography>
          </Breadcrumbs>
        </Box>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          onChange={handleChange}
          aria-label="disabled tabs"
        >
          <Tab label="Información" />
          <Tab label="Estudiantes" />
          <Tab label="Asignaciones" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {value === 0 && <DetailClassGroup />}
          {value === 1 && <StudentClassGroupList />}
          {value === 2 && <AssignmentClassGroupList />}
        </div>
      </Container>
    </Page>
  );
};

export default Dashboard;
