import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography
} from "@material-ui/core";
import Page from "src/components/Page";
import ListCompletedPuzzles from "./ListCompletedPuzzles";
import InfoPublicUser from "./InfoPublicUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();

  const handleClick = event => {
    event.preventDefault();
  };

  return (
    <Page className={classes.root} title="Detalle del usuario">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.text} to={"/admin/users/public-users"}>
              Usuarios Públicos
            </Link>
            <Typography color="textPrimary">Detalle del usuario</Typography>
          </Breadcrumbs>
        </Box>
        <InfoPublicUser />
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <ListCompletedPuzzles />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
