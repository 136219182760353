import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes, { element } from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import PerfectScrollbar from "react-perfect-scrollbar";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Container,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Breadcrumbs,
  colors,
  LinearProgress
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Page from "src/components/Page";
import CloseIcon from "@material-ui/icons/Close";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import bytesToSize from "src/utils/bytesToSize";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ARTISTS, GET_STYLES, GET_TECHNIQUES } from "src/services/queries";
import { CREATE_ARTWORK } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";
import { useSendNotification } from "src/services/notifications";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  dropZone: {
    border: "1px dashed rgba(0, 0, 0, 0.32)",
    padding: "5px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer"
    },
    width: "100%",
    height: "258px",
    margin: "auto"
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 60
  },
  info: {
    marginTop: theme.spacing(1),
    textAlign: "center"
  },
  list: {
    maxHeight: 320
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  title_head_name: {
    color: "#212529"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  },
  avatar_teacher: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  textLinkUpload: {
    color: "#0074BC"
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  titleTypeAnswer: {
    color: "#263238"
  },
  customright: {
    marginRight: "10px"
  }
}));

const NewArt = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();
  const send = useSendNotification();
  /* art id */
  let { artId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };

  const initialRecordState = {
    name: "",
    artists_id: "",
    description: "",
    story: "",
    year: "",
    styles_id: "",
    techniques_id: "",
    thumbnail: "",
    image: "",
    audio: "",
    priority: ""
  };

  const styleInitialState = [
    {
      id: 1,
      nombre: ""
    }
  ];

  const techniqueInitialState = [
    {
      id: 1,
      nombre: ""
    }
  ];

  const [arts, setArts] = useState([]);
  const [totalArts, setTotalArts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(initialRecordState);
  const [stylearts, setStyleArts] = useState(styleInitialState);
  const [arttechniques, setArtTechniques] = useState(styleInitialState);
  const [artists, setArtists] = useState([]);
  const [typeAnswer, setTypeAnswer] = useState(0);
  const [dashboard, setDashboard] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [imageUpload, setImageUpload] = useState("");
  const [imagethumbnail, setImageThumbnail] = useState("");
  const [audiotrack, setAudioTrack] = useState("");
  const [files, setFiles] = useState([]);
  const [files_thumbnail, setFileThumbnail] = useState([]);
  const [files_audio, setFileAudio] = useState([]);

  useEffect(() => {
    console.log(imageUpload);
  }, [imageUpload]);
  useEffect(() => {
    console.log(imagethumbnail);
  }, [imagethumbnail]);
  useEffect(() => {
    console.log(audiotrack);
  }, [audiotrack]);

  const [getStyles, resultStyles] = useLazyQuery(GET_STYLES);
  useEffect(() => {
    if (resultStyles.data) {
      console.log(resultStyles.data.styles);
      setStyleArts(resultStyles.data.styles);
    }
  }, [resultStyles]);

  const [getTechniques, resultTechniques] = useLazyQuery(GET_TECHNIQUES);
  useEffect(() => {
    if (resultTechniques.data) {
      console.log(resultTechniques.data.techniques);
      setArtTechniques(resultTechniques.data.techniques);
    }
  }, [resultTechniques]);

  const [getArtists, resultArtists] = useLazyQuery(GET_ARTISTS);
  useEffect(() => {
    if (resultArtists.data) {
      console.log(resultArtists.data.artists);
      setArtists(resultArtists.data.artists);
    }
  }, [resultArtists]);

  const [artMutation, resultArtMutation] = useMutation(CREATE_ARTWORK, {
    onCompleted(data) {
      console.log("UPDATE_LEVEL");
      console.log(data);
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
      });
      setValues(initialRecordState);
      setImageUpload("");
      setImageThumbnail("");
      setAudioTrack("");

      const notificationParams = {
        userId: 1,
        message: "Nueva obra disponible.",
        link: "",
        allTeachers: true,
        allAdmins: false
      };
      send.sendNotification(notificationParams);

      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
      });
      //setValues(initialRecordState);
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, arts.length - page * rowsPerPage);

  const UploadCover = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFiles(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileImage(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setImageUpload(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFiles([]);
      setImageUpload("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography gutterBottom variant="h6">
                Dimensiones: 512px x 512px
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br> cargar desde tu
                dispositivo
              </Typography>
              {files.length > 0 && (
                <Fragment>
                  {files.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  const UploadThumbnail = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFileThumbnail(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileImage(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setImageThumbnail(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFileThumbnail([]);
      setImageThumbnail("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography gutterBottom variant="h6">
                Dimensiones: 256px x 256px
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br> cargar desde tu
                dispositivo
              </Typography>
              {files_thumbnail.length > 0 && (
                <Fragment>
                  {files_thumbnail.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  const UploadAudioTrack = props => {
    const { title } = props;

    const handleDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles.find(f => f);

      setFileAudio(acceptedFiles);
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {};

      if (!Utils.validateFileAudio(file)) {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_FORMAT_INVALID
        });
        handleRemoveAll();
      } else {
        setAudioTrack(file);
      }
    }, []);

    const handleRemoveAll = () => {
      setFileAudio([]);
      setAudioTrack("");
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop
    });

    return (
      <React.Fragment>
        <Card>
          <CardHeader title={`${title}`} />
          <Divider />
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              className={clsx({
                [classes.dropZone]: true,
                [classes.dragActive]: isDragActive
              })}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                <img
                  alt="Seleccionar archivo"
                  className={classes.image}
                  src={
                    process.env.PUBLIC_URL + "/static/images/ic_upload_file.png"
                  }
                />
              </div>
              <Typography gutterBottom variant="h3">
                Agregar archivo
              </Typography>
              <Typography
                className={classes.info}
                color="textSecondary"
                variant="body1"
              >
                Suelta los archivos aquí o haz clic en <br></br> cargar desde tu
                dispositivo
              </Typography>
              {files_audio.length > 0 && (
                <Fragment>
                  {files_audio.map((file, i) => (
                    <Typography
                      key={i}
                      className={classes.info}
                      color="textSecondary"
                      variant="body1"
                    >
                      {file.name} - {bytesToSize(file.size)}
                    </Typography>
                  ))}
                </Fragment>
              )}
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  };

  /**
   * saveNewArtwork
   */
  const saveNewArtwork = values => {
    if (imageUpload.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    if (imagethumbnail.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_IMAGE_EMPTY
      });
      return;
    }

    if (audiotrack.length === 0) {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_AUDIO_EMPTY
      });
      return;
    }

    console.log({
      artists_id: parseInt(values.artists_id),
      styles_id: parseInt(values.styles_id),
      techniques_id: parseInt(values.techniques_id),
      name: values.name,
      year: parseInt(values.year),
      description: values.description,
      story: values.story,
      image: imageUpload,
      thumbnail: imagethumbnail,
      audio: audiotrack,
      priority: parseInt(values.priority)
    });
    artMutation({
      variables: {
        artists_id: parseInt(values.artists_id),
        styles_id: parseInt(values.styles_id),
        techniques_id: parseInt(values.techniques_id),
        name: values.name,
        year: parseInt(values.year),
        description: values.description,
        story: values.story,
        image: imageUpload,
        thumbnail: imagethumbnail,
        audio: audiotrack,
        priority: parseInt(values.priority)
      }
    });
  };

  /**
   * Get All
   */
  const fetchNewArtwork = () => {};

  const fetchStyleArts = () => {
    getStyles();
  };

  const fetchTechniques = () => {
    getTechniques();
  };

  const fetchAllArtists = () => {
    getArtists();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchNewArtwork();
    fetchStyleArts();
    fetchTechniques();
    fetchAllArtists();
  }, []);

  return (
    <Page className={classes.root} title="Agregar obra">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.text} to={"/admin/arts"}>
              Arte
            </Link>
            <Typography color="textPrimary">Obras</Typography>
            <Typography color="textPrimary">Agregar obra</Typography>
          </Breadcrumbs>
        </Box>
        <Box mb={1}>
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            Agregar nueva obra
          </Typography>
        </Box>
        <Grid
          className={clsx(classes.root, className)}
          {...rest}
          container
          spacing={3}
        >
          <Grid item lg={8} sm={6} xs={12} mb={1}>
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialRecordState}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("El nombre es requerido."),
                artists_id: Yup.string().required("El artista es requerido."),
                techniques_id: Yup.string().required(
                  "La técnica es requerida."
                ),
                styles_id: Yup.string().required("La biografía es requerida."),
                year: Yup.number()
                  .typeError("Solo se permiten números.")
                  .positive("Solo número positivo.")
                  .integer("Solo se permiten enteros.")
                  .required("El año es requerido."),
                description: Yup.string().required(
                  "La descripción es requerida."
                ),
                story: Yup.string().required("La historia es requerida."),
                priority: Yup.number()
                  .typeError("Solo se permiten números.")
                  .positive("Solo número positivo.")
                  .integer("Solo se permiten enteros.")
                  .required("La prioridad es requerida.")
              })}
              onSubmit={values => {
                saveNewArtwork(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <Card>
                    <CardHeader title="Información de la obra" />
                    {resultArtMutation.loading && <LinearProgress />}
                    <Divider />
                    <CardContent>
                      <Collapse in={openAlert}>
                        <Alert
                          severity={errorMsg.type}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenAlert(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMsg.message}
                        </Alert>
                        <Box p={1}></Box>
                      </Collapse>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Nombre de la obra"
                            name="name"
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Artista"
                            name="artists_id"
                            error={Boolean(
                              touched.artists_id && errors.artists_id
                            )}
                            helperText={touched.artists_id && errors.artists_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.artists_id}
                            variant="outlined"
                          >
                            <option key="" value=""></option>
                            {artists.map(option => (
                              <option key={option.id} value={option.id}>
                                {option.first_name} {option.last_name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Año"
                            name="year"
                            error={Boolean(touched.year && errors.year)}
                            helperText={touched.year && errors.year}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.year}
                            variant="outlined"
                            placeholder="Año"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Estilo"
                            name="styles_id"
                            error={Boolean(
                              touched.styles_id && errors.styles_id
                            )}
                            helperText={touched.styles_id && errors.styles_id}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.styles_id}
                            variant="outlined"
                          >
                            <option key="" value=""></option>

                            {stylearts.map(option => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Técnica"
                            name="techniques_id"
                            error={Boolean(
                              touched.techniques_id && errors.techniques_id
                            )}
                            helperText={
                              touched.techniques_id && errors.techniques_id
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.techniques_id}
                            variant="outlined"
                          >
                            <option key="" value=""></option>

                            {arttechniques.map(option => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Descripción"
                            name="description"
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            helperText={
                              touched.description && errors.description
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            type="text"
                            value={values.description}
                            variant="outlined"
                            placeholder="Descripción..."
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Historia"
                            name="story"
                            error={Boolean(touched.story && errors.story)}
                            helperText={touched.story && errors.story}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            type="text"
                            value={values.story}
                            variant="outlined"
                            placeholder="Historia..."
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <TextField
                            fullWidth
                            label="Peso (prioridad)"
                            name="priority"
                            error={Boolean(touched.priority && errors.priority)}
                            helperText={touched.priority && errors.priority}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            value={values.priority}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={
                          isSubmitting && resultArtMutation.loading
                            ? true
                            : false
                        }
                      >
                        GUARDAR
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
            <Box p={1}></Box>
          </Grid>
          <Grid item lg={4} sm={6} xs={12} mb={1}>
            <UploadCover title="Cargar imagen"></UploadCover>
            <Box p={1}></Box>
            <UploadThumbnail title="Cargar thumbnail"></UploadThumbnail>
            <Box p={1}></Box>
            <UploadAudioTrack title="Cargar pista de audio"></UploadAudioTrack>
            <Box p={1}></Box>
          </Grid>
          <Box p={3}></Box>
        </Grid>
      </Container>
    </Page>
  );
};

NewArt.propTypes = {
  className: PropTypes.string
};

export default NewArt;
