const { useLazyQuery } = require("@apollo/client");
const { useState } = require("react");
const { useEffect } = require("react");
const { ME } = require("./queries");

export const useMeProfile = () => {
  const [me, setMe] = useState([]);

  const [getMe, resultMe] = useLazyQuery(ME, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result) {
        if (result.me) {
          console.log("RESULTADO: getMe", result.me[0]);
          setMe(result.me[0]);
        }
      }
    }
  });

  useEffect(() => {
    getMe();
  }, []);

  return [me, resultMe];
};
