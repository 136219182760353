import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import { useLazyQuery } from "@apollo/client";
import {
  GET_PG,
  GET_STUDENT_ASSIGNMENT_PERCENT,
  GET_USER_DETAILS
} from "src/services/queries";
import AvatarUser from "src/components/AvatarUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  title_head_name: {
    color: "#212529"
  },
  avatar: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  avatarItem: {
    width: "90px",
    height: "90px",
    backgroundSize: "cover",
    objectFit: "cover"
  }
}));

const StudentInfo = ({ className, ...rest }) => {
  const classes = useStyles();
  /* student id */
  let { studentId, schoolId } = useParams();

  const [dashboard, setDashboard] = useState([]);
  const [points, setPoints] = useState([]);
  const [percent, setPercent] = useState(0);

  const [getPercent, resultGetPercent] = useLazyQuery(
    GET_STUDENT_ASSIGNMENT_PERCENT,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getPercent", result);
        if (result && result.total_student_assignment_percent) {
          setPercent(result.total_student_assignment_percent);
        }
      }
    }
  );

  const [getPoints, resultGetPoints] = useLazyQuery(GET_PG, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getPG", result);
      if (result && result.getPGByID) {
        setPoints(result.getPGByID);
      }
    }
  });

  useEffect(() => {
    if (dashboard && dashboard.id) {
      getPoints({
        variables: {
          userId: dashboard.id
        }
      });
    }
  }, [dashboard]);

  const [getUser, resultUser] = useLazyQuery(GET_USER_DETAILS, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: getUser", result);
      if (result) {
        if (result.user[0]) {
          setDashboard(result.user[0]);
        }
      }
    }
  });

  const fetchStudentDetail = () => {
    getUser({
      variables: {
        id: parseInt(studentId)
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchStudentDetail();
    getPercent({
      variables: {
        student_id: parseInt(studentId),
        school_id: parseInt(schoolId)
      }
    });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          {resultUser.loading && <LinearProgress />}
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography
                  className={classes.title_head_name}
                  gutterBottom
                  variant="h2"
                >
                  {dashboard.first_name} {dashboard.last_name}
                </Typography>
              </Grid>
              <Grid item>
                {dashboard.id && <AvatarUser id={dashboard.id} />}
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.email}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.age ? dashboard.age : 0} años
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.nombre_grado}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          {resultGetPercent.loading && <LinearProgress />}
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ASIGNACIONES COMPLETADAS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {percent.percent ? percent.percent : 0}%
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatarProgress}>
                  <ShowChartOutlinedIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Typography color="textSecondary" variant="caption">
                {percent.total_completed}/{percent.total}
              </Typography>
              <LinearProgress
                value={percent.percent ? percent.percent : 0}
                variant="determinate"
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          {resultGetPoints.loading && <LinearProgress />}
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  PUNTOS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {points.points ? points.points : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/ic_star_white.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

StudentInfo.propTypes = {
  className: PropTypes.string
};

export default StudentInfo;
