import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles
} from "@material-ui/core";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import {
  COUNT_STUDENTS_BY_TEACHER_AND_SCHOOL,
  GET_CG_ASSIGNMENT_PERCENT,
  GET_STUDENT_ASSIGNMENT_PERCENT,
  GET_USER
} from "src/services/queries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  title_head_name: {
    color: "#212529"
  },
  avatar: {
    backgroundColor: "#F1F1F1",
    height: 56,
    width: 56
  },
  avatar_hat: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  avatarItem: {
    backgroundSize: "cover",
    objectFit: "cover"
  }
}));

const PATH_URL = API_URL;

const InfoTeacher = ({ className, ...rest }) => {
  const classes = useStyles();
  /* school id */
  let { schoolId } = useParams();
  /* teacher id */
  let { teacherId } = useParams();
  const initialRecordState = {
    avatar: "",
    nombre: "Mario",
    apellido: "González",
    colegio: "Mi Colegio",
    nombre_grado: "Primaria",
    estudiantes: 18,
    asignaciones_impartidas: 80,
    porcentaje_impartidas: 0,
    asignaciones_impartidas: 0,
    asignaciones_totales: 0
  };
  const [dashboard, setDashboard] = useState();
  const [countStudent, setCountStudent] = useState();

  const [getUser, resultUser] = useLazyQuery(GET_USER);
  useEffect(() => {
    if (resultUser.data) {
      console.log(resultUser.data.user);
      setDashboard(resultUser.data.user[0]);
    }
  }, [resultUser]);

  const [getCountStudent, resultCountStudent] = useLazyQuery(
    COUNT_STUDENTS_BY_TEACHER_AND_SCHOOL,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getCountStudent", result);
        if (result) {
          setCountStudent(result.students);
        }
      }
    }
  );

  const [percent, setPercent] = useState(0);
  const [getPercent, resultGetPercent] = useLazyQuery(
    GET_STUDENT_ASSIGNMENT_PERCENT,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getPercent", result);
        if (result && result.total_classgroup_assignment_percent) {
          setPercent(result.total_classgroup_assignment_percent);
        }
      }
    }
  );

  const fetchCountStudents = () => {
    getCountStudent({
      variables: {
        userId: parseInt(teacherId),
        school_id: parseInt(schoolId)
      }
    });
  };

  const fetchTeacherDetail = () => {
    getUser({
      variables: {
        id: teacherId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchTeacherDetail();
    fetchCountStudents();
    getPercent({
      variables: {
        student_id: parseInt(teacherId),
        school_id: parseInt(schoolId)
      }
    });
  }, [teacherId]);

  if (!dashboard) return null;

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography
                  className={classes.title_head_name}
                  gutterBottom
                  variant="h2"
                >
                  {dashboard.first_name} {dashboard.last_name}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  {dashboard.avatar ? (
                    <img
                      className={classes.avatarItem}
                      src={`${PATH_URL}` + dashboard.avatar}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src =
                          process.env.PUBLIC_URL + "/static/images/ic_user.png";
                      }}
                    />
                  ) : (
                    <img
                      className={classes.avatarItem}
                      src={
                        process.env.PUBLIC_URL + "/static/images/ic_user.png"
                      }
                    />
                  )}
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.colegio}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.nombre_grado}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ESTUDIANTES
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {countStudent ? countStudent : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar_hat}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/static/images/ic_school.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ASIGNACIONES IMPARTIDAS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {dashboard.percent ? dashboard.percent : 0}%
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatarProgress}>
                  <ShowChartOutlinedIcon />
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Typography color="textSecondary" variant="caption">
                {dashboard.total_completed ? dashboard.total_completed : 0}/
                {dashboard.total ? dashboard.total : 0}
              </Typography>
              <LinearProgress
                value={dashboard.percent ? dashboard.percent : 0}
                variant="determinate"
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

InfoTeacher.propTypes = {
  className: PropTypes.string
};

export default InfoTeacher;
