import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography
} from "@material-ui/core";
import Page from "src/components/Page";
import DetailAdminUser from "./DetailAdminUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Detalle del administrador">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.text} to={"/admin/users/admin-users"}>
              Administradores
            </Link>
            <Typography color="textPrimary">
              Detalle del administrador
            </Typography>
          </Breadcrumbs>
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <DetailAdminUser />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
