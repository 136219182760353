import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
  LinearProgress
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Page from "src/components/Page";
/* Services */
import ApiService from "src/services/ApiService";
import { SIGNIN } from "src/services/mutations";
import { useMutation } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logoImgk4k: {
    marginBottom: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 170,
    height: 120
  },
  k4kBoxLogin: {
    background: "#ffffff",
    padding: 35,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
  },
  buttonProgress: {
    color: theme.palette.background.light,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);

  const [signinMutation, resultSigninMutation] = useMutation(SIGNIN, {
    onCompleted(data) {
      console.log(data);
      ApiService.setUserData(data.signIn.token);
      navigate("/app/dashboard", { replace: true });
    },
    onError(error) {
      console.log(error);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "El usuario o la contraseña son incorrectos"
      });
    }
  });

  useEffect(() => {
    console.log(resultSigninMutation.loading);
  }, [resultSigninMutation]);

  const initialValues = {
    username: "",
    password: "",
    grant_type: "password"
  };
  const [user, setUser] = useState(initialValues);

  const handleChange = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Page className={classes.root} title="Iniciar Sesión">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box textAlign="center">
            <img
              className={classes.logoImgk4k}
              src={process.env.PUBLIC_URL + "/static/images/logo-kulture.png"}
            />
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .email("Debe ser un correo electrónico válido")
                .required("El correo electrónico es obligatorio"),
              password: Yup.string().required("Se requiere una contraseña")
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                /**
                 * handlesubmitSignIn
                 */
                console.log({
                  login: values.username,
                  password: values.password
                });
                signinMutation({
                  variables: {
                    login: values.username,
                    password: values.password,
                    roleId: 1
                  }
                });
              }, 500);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <>
                {resultSigninMutation.loading && <LinearProgress />}

                <Box className={classes.k4kBoxLogin}>
                  <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                      <Typography color="textPrimary" variant="h2">
                        Iniciar Sesión
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        Iniciar sesión en la plataforma interna
                      </Typography>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}></Grid>
                      <Grid item xs={12} md={6}></Grid>
                    </Grid>
                    <Box mt={3} mb={1}></Box>
                    <Collapse in={openAlert}>
                      <Alert
                        severity={errorMsg.type}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenAlert(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsg.message}
                      </Alert>
                    </Collapse>
                    <TextField
                      error={Boolean(touched.username && errors.username)}
                      fullWidth
                      helperText={touched.username && errors.username}
                      label="Correo electrónico"
                      margin="normal"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.username}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Contraseña"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <Box my={2}>
                      <Button
                        color="secondary"
                        disabled={
                          isSubmitting && resultSigninMutation.loading
                            ? true
                            : false
                        }
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading
                      >
                        Iniciar Sesión
                      </Button>
                    </Box>
                  </form>
                </Box>
              </>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
