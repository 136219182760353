import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  colors
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ACADEMIC_LEVEL_SHOW } from "src/services/queries";
import { UPDATE_ACADEMIC_LEVEL } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  title_head_name: {
    color: "#212529"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1",
    height: 56,
    width: 56
  }
}));

const PATH_URL = API_URL;

const EditAcademicLevel = ({ className, ...rest }) => {
  const classes = useStyles();

  const formRef = useRef();
  /* academic level id */
  let { academicLevelId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    id: academicLevelId,
    level: "",
    detail: ""
  };

  const [values, setValues] = useState(initialRecordState);
  const [dashboard, setDashboard] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [getAcademicLevel, resultAcademicLevel] = useLazyQuery(
    ACADEMIC_LEVEL_SHOW
  );
  const [academicLevelMutation, resultAcademicLevelMutation] = useMutation(
    UPDATE_ACADEMIC_LEVEL,
    {
      //refetchQueries: [{ query: LEVEL_SHOW }],
      onCompleted(data) {
        console.log("UPDATE_LEVEL");
        console.log(data);
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
        });
        setValues(initialRecordState);
        fetchAcademicLevel();

        //formRef.current?.resetForm();
      },
      onError(error) {
        console.log(error);
      }
    }
  );

  useEffect(() => {
    if (resultAcademicLevel.data) {
      console.log(resultAcademicLevel.data.AcademicLevel);
      setDashboard(resultAcademicLevel.data.AcademicLevel);
      setValues(resultAcademicLevel.data.AcademicLevel);
    }
  }, [resultAcademicLevel]);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveUpdateAcademic
   */
  const saveUpdateAcademic = values => {
    console.log({
      level: values.level,
      detail: values.detail,
      id: academicLevelId
    });
    academicLevelMutation({
      variables: {
        level: values.level,
        detail: values.detail,
        id: academicLevelId
      }
    });
  };

  const fetchAcademicLevel = () => {
    getAcademicLevel({
      variables: {
        id: academicLevelId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAcademicLevel();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!dashboard) return null;

  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      container
      spacing={3}
    >
      <Grid item lg={4} sm={6} xl={3} xs={12} mb={1}>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography
                  className={classes.title_head_name}
                  gutterBottom
                  variant="h2"
                >
                  {dashboard.level}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar_group}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/ic_school_avatar.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography
                className={classes.subtitle_text_card}
                color="textPrimary"
                variant="caption"
              >
                {dashboard.id}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography
                className={classes.subtitle_text_card}
                color="textPrimary"
                variant="caption"
              >
                {dashboard.detail}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Box p={1}></Box>
      </Grid>
      <Grid item lg={8} sm={6} xs={12} mb={1}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={values}
          validationSchema={Yup.object().shape({
            level: Yup.string().required("El nivel es requerido."),
            detail: Yup.string().required("El detalle es requerido.")
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            saveUpdateAcademic(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Card>
                <CardHeader title="Nivel" />
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nivel"
                        name="level"
                        error={Boolean(touched.level && errors.level)}
                        helperText={touched.level && errors.level}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.level}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Detalle"
                        name="detail"
                        error={Boolean(touched.detail && errors.detail)}
                        helperText={touched.detail && errors.detail}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.detail}
                        variant="outlined"
                        placeholder="Detalle..."
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Box p={1}></Box>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting && resultAcademicLevelMutation.loading
                        ? true
                        : false
                    }
                  >
                    GUARDAR
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

EditAcademicLevel.propTypes = {
  className: PropTypes.string
};

export default EditAcademicLevel;
