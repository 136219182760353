import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Avatar, makeStyles } from "@material-ui/core";
import { GET_USER_AVATAR } from "src/services/queries";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  avatarItem: {
    backgroundSize: "cover",
    objectFit: "cover"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  }
}));

function AvatarUser({ id, only = false }) {
  const classes = useStyles();

  const sizeDefault = 60;
  const [avatar, setAvatar] = useState(false);
  const [size, setSize] = useState(sizeDefault);

  const [getAvatar, resultGetAvatar] = useLazyQuery(GET_USER_AVATAR, {
    fetchPolicy: "no-cache",
    pollInterval: 3000
  });

  useEffect(() => {
    if (resultGetAvatar.data) {
      console.log("GET_AVATAR: ", resultGetAvatar.data.getAvatarById);
      setAvatar(resultGetAvatar.data.getAvatarById);
    }
  }, [resultGetAvatar]);

  useEffect(() => {
    getAvatar({
      variables: {
        userId: parseInt(id)
      }
    });
    setSize(sizeDefault);
  }, []);

  return (
    <Avatar className={classes.avatar_group}>
      {avatar ? (
        <img
          className={classes.avatarItem}
          src={
            !only
              ? `${process.env.REACT_APP_URL_IMAGES}${avatar.image}`
              : `${avatar.image}`
          }
          alt=""
          width={size}
          height={size}
          //center={true}
        />
      ) : (
        <img
          className={classes.avatarItem}
          width={size}
          height={size}
          src={
            process.env.PUBLIC_URL +
            "/static/images/avatars/neutral_avatar.jpeg"
          }
        />
      )}
    </Avatar>
  );
}

export default AvatarUser;
