import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors
} from "@material-ui/core";
import { NotificationList, EmptyList } from "./components";

const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: "100%"
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: "center"
  }
}));

const NotificationsPopover = props => {
  const { notifications } = props;

  const { anchorEl, ...rest } = props;
  const [total, setTotal] = useState(0);
  const [title, setTitle] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (notifications.length == 1) setTitle("notificación nueva.");
    else setTitle("notificaciones nuevas.");

    setTotal(notifications.length);
  }, [notifications]);

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Notificaciones" subheader={`${total} ${title}`} />
        <Divider />
        {notifications.length > 0 ? (
          <NotificationList
            onClose={props.onClose}
            notifications={notifications}
          />
        ) : (
          <EmptyList />
        )}
      </div>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default NotificationsPopover;
