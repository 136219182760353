import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

require("dotenv").config();

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL_TEST
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_API_URL
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem("session");

  console.log("TOKEN - - - - - - - - - - -");
  console.log(token);

  if (!token) return false;

  return {
    headers: {
      ...headers,
      "x-token": token
    }
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  //link: httpLink
  link: authLink.concat(httpLink)
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// serviceWorker.unregister();
