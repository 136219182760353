import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import Logo from "src/components/Logo";
import NotificationsPopover from "src/components/NotificationsPopover";
import data from "src/components/NotificationsPopover/components/NotificationList/notifications.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useLazyQuery } from "@apollo/client";
import { GET_NOTIFICATION } from "src/services/queries";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  ic_toolbar_white: {
    color: "#ffffff"
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  // Notifications
  const notificationsRef = useRef(null);
  const logoutRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  // Open Modal Logout
  const [openModal, setOpenModal] = useState(false);
  const [badgeContent, setBadgeContent] = useState(0);

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("isLogin");
    localStorage.removeItem("tokenk4k");

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("session");
  };

  const [getNotification, resultNotification] = useLazyQuery(GET_NOTIFICATION, {
    fetchPolicy: "no-cache",
    pollInterval: 3000
  });

  useEffect(() => {
    if (resultNotification.data) {
      console.log("Notifications", resultNotification.data.notifications);
      setNotifications(resultNotification.data.notifications);
      if (resultNotification.data.notifications.length > 0) {
        let countUnReadIt = resultNotification.data.notifications.filter(
          item => item.readIt === 0
        );
        console.log("badgeContent.length", countUnReadIt);
        setBadgeContent(countUnReadIt.length);
      }
    } else {
      setBadgeContent(0);
    }
  }, [resultNotification]);

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton
            className={classes.ic_toolbar_white}
            onClick={handleNotificationsOpen}
          >
            <Badge
              badgeContent={badgeContent}
              color="secondary"
              ref={notificationsRef}
              variant="standard"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.ic_toolbar_white}
            ref={logoutRef}
            onClick={handleOpenModal}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            className={classes.ic_toolbar_white}
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
      <div>
        <Dialog
          maxWidth={"sm"}
          open={openModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.contentDialog }}
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: "20px", fontWeight: "bold" }}
            >
              ¿Seguro que quieres cerrar sesión?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actionsButtons}>
            <Button onClick={handleClose} variant="outlined">
              CANCELAR
            </Button>
            <RouterLink to="/login">
              <Button
                onClick={handleLogout}
                variant="contained"
                color="secondary"
              >
                CERRAR SESIÓN
              </Button>
            </RouterLink>
          </DialogActions>
          <Box mb={2}></Box>
        </Dialog>
      </div>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
