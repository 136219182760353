import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Box,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  TextField,
  Grid,
  Menu,
  MenuItem,
  ListItemText,
  LinearProgress
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ARTWORKS_BY_TECHNIQUE, GET_TECHNIQUE } from "src/services/queries";
import { UPDATE_TECHNIQUE } from "src/services/mutations";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1"
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const DetailTechnique = ({ className, ...rest }) => {
  const classes = useStyles();
  const [arts, setArts] = useState([]);
  const [totalArts, setTotalArts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertTable, setOpenAlertTable] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    name: "",
    description: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );
  /* Id technique */
  let { techniqueId } = useParams();

  const [getTechnique, resultTechnique] = useLazyQuery(GET_TECHNIQUE);
  useEffect(() => {
    if (resultTechnique.data) {
      console.log(resultTechnique.data.technique);
      setValues(resultTechnique.data.technique);
    }
  }, [resultTechnique]);

  const [getArtworks, resultGetArtworks] = useLazyQuery(
    GET_ARTWORKS_BY_TECHNIQUE,
    {
      onCompleted: result => {
        if (result && result.artworks_by_technique) {
          console.log("GET_TECHNIQUES", result.artworks_by_technique);
          setArts(result.artworks_by_technique);
          setTotalArts(result.artworks_by_technique.length);
        }
      },
      onError: error => {
        console.log("error", error);
      }
    }
  );

  const [techniqueMutation, resultTechniqueMutation] = useMutation(
    UPDATE_TECHNIQUE,
    {
      onCompleted(data) {
        console.log("UPDATE_TECHNIQUE");
        console.log(data);
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
        });
        fetchTechniqueDetail();
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
        });
        fetchTechniqueDetail();
      }
    }
  );

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, arts.length - page * rowsPerPage);

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setArts(
        [...items].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setArts(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  /**
   * updateTecnique
   */
  const updateTecnique = () => {
    if (values.name === "" || values.description === "") {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      console.log(values);
      techniqueMutation({
        variables: {
          id: techniqueId,
          name: values.name,
          description: values.description
        }
      });
    }
  };

  const fetchTechniqueDetail = () => {
    let isLoaded = true;

    getTechnique({
      variables: {
        id: techniqueId
      }
    });
  };

  const fetchArtsByTechnique = () => {
    getArtworks({
      variables: {
        id: techniqueId
      }
    });
  };

  /**
   * deleteWork
   */
  const deleteWork = id => {};

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.name}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteWork(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchTechniqueDetail();
    fetchArtsByTechnique();
  }, []);

  if (!values) return null;

  return (
    <div>
      <form autoComplete="off" noValidate>
        <Card>
          <CardHeader title="Información de la técnica" />
          <Divider />
          <CardContent>
            {/* Alert Success Delete | default hide */}
            <Collapse in={openAlert}>
              <Alert
                severity={errorMsg.type}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMsg.message}
              </Alert>
              <Box p={1}></Box>
            </Collapse>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Descripción"
                  name="description"
                  type="text"
                  onChange={handleChange}
                  required
                  value={values.description}
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="secondary"
              variant="contained"
              onClick={updateTecnique}
            >
              GUARDAR
            </Button>
          </Box>
        </Card>
      </form>
      <Box p={1}></Box>
      <Card className={clsx(classes.root, className)} {...rest}>
        {/* Modal Delete */}
        <Modal open={modalEliminar} onClose={openCloseModalDelete}>
          {bodyModalDialogConfirmDelete}
        </Modal>
        <CardHeader
          action={
            <Box alignItems="center" display="flex">
              <Typography align="left" color="textPrimary" variant="h6">
                Mostrar por:
              </Typography>
              <Button
                className={classes.sortButton}
                onClick={handleSortOpen}
                ref={sortRef}
              >
                {selectedSort}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={sortRef.current}
                className={classes.menu}
                onClose={handleSortClose}
                open={openSort}
              >
                {[
                  SYSTEM_MESSAGES.MOST_RECENT,
                  SYSTEM_MESSAGES.ALPHABETIC_ORDER
                ].map(option => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option}
                    onClick={() => handleSortSelect(option, arts)}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }
          title={
            <Box display="flex">
              <Typography
                className={classes.card_header_title}
                variant={"h6"}
                gutterBottom
              >
                Obras &nbsp;
              </Typography>
              <Typography
                className={classes.card_subtitle_total}
                variant={"h6"}
                gutterBottom
              >
                {totalArts ? totalArts : 0} total
              </Typography>
            </Box>
          }
        />
        {/* Alert Success Delete | default hide */}
        <Collapse in={openAlertTable}>
          <Alert
            severity={errorMsg.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlertTable(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMsg.message}
          </Alert>
          <Box p={1}></Box>
        </Collapse>
        {resultGetArtworks.loading && <LinearProgress />}
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Obra</TableCell>
                  <TableCell>Autor</TableCell>
                  <TableCell>Año</TableCell>
                  <TableCell>Preguntas</TableCell>
                  <TableCell>Fecha Agregada</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => (
                    <TableRow hover key={item.id}>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar className={classes.avatar_group}>
                            {item.thumbnail ? (
                              <img
                                className={classes.avatarItem}
                                src={item.thumbnail}
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    process.env.PUBLIC_URL +
                                    "/static/images/neutral_avatar.jpeg";
                                }}
                              />
                            ) : (
                              <img
                                className={classes.avatarItem}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/images/neutral_avatar.jpeg"
                                }
                              />
                            )}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {item.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {item.first_name} {item.last_name}
                      </TableCell>
                      <TableCell>{item.year ? item.year : 0}</TableCell>
                      <TableCell>
                        {item.questions ? item.questions : 0}
                      </TableCell>
                      <TableCell>
                        {item.createdAt
                          ? Utils.formatDateShort(item.createdAt)
                          : ""}
                      </TableCell>
                      <TableCell>
                        <MenuMoreActions data={item} />
                        <Link
                          className={classes.text}
                          to={"/admin/art/" + `${item.id}` + "/detail"}
                        >
                          <IconButton edge="end" size="small">
                            <ArrowForwardIcon />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={arts.length}
          onChangePage={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
        <Box display="flex" justifyContent="flex-end" p={2}></Box>
      </Card>
    </div>
  );
};

DetailTechnique.propTypes = {
  className: PropTypes.string
};

DetailTechnique.defaultProps = {};

export default DetailTechnique;
