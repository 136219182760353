import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Tabs, Tab, Divider, colors, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import TopbarAssignment from './TopbarAssignment';
import TopbarStudent from './TopbarStudent';
import ToolbarAssignment from './ToolbarAssignment';
import ToolbarStudent from './ToolbarStudent';
import AssignmentsList from './AssignmentsList';
import StudentsList from './StudentsList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inner: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <Page
      className={classes.root}
      title="Grupo"
    >
      <Container maxWidth={false}>
        <div className={classes.content}>
            {value === 0 && <TopbarAssignment />}
            {value === 1 && <TopbarStudent />}
        </div>
        
        <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example">
            <Tab label="Asignaciones" />
            <Tab label="Estudiantes" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
            {value === 0 && <ToolbarAssignment />}
            {value === 1 && <ToolbarStudent />}
        </div>
        <div className={classes.content}>
            {value === 0 && <AssignmentsList />}
            {value === 1 && <StudentsList />}
        </div>
      </Container>
    </Page>
  );
};

Dashboard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.any
};

export default Dashboard;
