import { v4 as uuid } from 'uuid';

export default [
  {
    id        : uuid(),
    title     : 'Cambia tu contraseña',
    type      : 'password',
    created_at: 'Hace 1 minuto'
  },
  {
    id        : uuid(),
    title     : 'Inicio de Sesión exitoso',
    type      : 'session',
    created_at: 'Hace 1 minuto'
  }
]