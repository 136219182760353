import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

export const CREATE_NOTIFICATION = gql`
  mutation(
    $userId: Int
    $message: String
    $link: String
    $allTeachers: Boolean
    $allAdmins: Boolean
  ) {
    createNotification(
      userId: $userId
      message: $message
      link: $link
      allTeachers: $allTeachers
      allAdmins: $allAdmins
    )
  }
`;

export const useSendNotification = () => {
  const [finished, setFinished] = useState(false);

  const [addMutation, resultAddMutation] = useMutation(CREATE_NOTIFICATION, {
    onCompleted(data) {
      console.log("CREATE_NOTIFICATION", data);
      setFinished(true);
    },
    onError(error) {
      console.log(error);
    }
  });

  const sendNotification = params => {
    console.log("params", params);
    addMutation({
      variables: {
        userId: params.userId,
        message: params.message,
        link: params.link,
        allTeachers: params.allTeachers,
        allAdmins: params.allAdmins
      }
    });
  };

  return { sendNotification, finished };
};
