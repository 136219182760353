import "react-perfect-scrollbar/dist/css/styles.css";
import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import "src/mixins/chartjs";
import theme from "src/theme";
import routing_app from "src/routes";

const App = () => {
  const routing = useRoutes(routing_app.routes);
  const routing_default = useRoutes(routing_app.defaultRoute);

  const _userData = sessionStorage.getItem("session");

  if (_userData) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing_default}
      </ThemeProvider>
    );
  }
};

export default App;
