import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Grid,
  Tabs,
  Tab,
  Divider,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
import Page from "src/components/Page";
import AcademicLevelList from "./AcademicLevels/AcademicLevelList";
import SchoolGradeList from "./SchoolGrades/SchoolGradeList";
import SchoolGroupsList from "./SchoolGroups/SchoolGroupsList";
import SchoolDifficultyList from "./SchoolDifficulty/SchoolDifficultyList";
import { useParams } from "react-router";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inner: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  welcomeMainText: {
    color: "#212529",
    fontSize: "24px",
    fontWeight: "500"
  },
  subtitleWelcomeText: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "400"
  }
}));

const DashboardSystem = () => {
  const classes = useStyles();
  let { tab } = useParams();

  const [value, setValue] = React.useState(0);

  const initialRecordState = {
    total_artes: 40
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    if (tab) {
      console.log("tab", tab);
      setValue(parseInt(tab));
    }
  }, [tab]);

  useEffect(() => {
    setDashboard(initialRecordState);
  }, []);

  return (
    <Page className={classes.root} title="Sistema">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-start">
              <Typography
                align="left"
                color="textPrimary"
                variant="body1"
                className={classes.welcomeMainText}
              >
                Sistema
              </Typography>
            </Box>
            <Box
              mt={0}
              display="flex"
              justifyContent="flex-start"
              alignItems="left"
            >
              <Typography align="left" color="textPrimary" variant="h6">
                Kulture 4 Kids
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          onChange={handleChange}
          aria-label="disabled tabs"
        >
          <Tab label="Niveles Académicos" />
          <Tab label="Grados" />
          <Tab label="Grupos" />
          <Tab label="Dificultades" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {value === 0 && <AcademicLevelList />}
          {value === 1 && <SchoolGradeList />}
          {value === 2 && <SchoolGroupsList />}
          {value === 3 && <SchoolDifficultyList />}
        </div>
      </Container>
    </Page>
  );
};

export default DashboardSystem;
