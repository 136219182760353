import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Grid,
  Tabs,
  Tab,
  Divider,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Page from "src/components/Page";
import InfoSchool from "./InfoSchool";
import TeacherList from "./Teachers/TeacherList";
import StudentsList from "./Students/StudentsList";
import ClassGroupList from "./ClassGroups/ClassGroupList";
import DashboardAcademic from "./AcademicLevels/AcademicLevels";
/* Services */
import ApiService from "src/services/ApiService";
import { gql, useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inner: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const SCHOOL_BY_ID = gql`
  query($id: ID!) {
    school(id: $id) {
      id
      name
      school_code
      countries_id
      city
    }
  }
`;

const Dashboard = () => {
  const classes = useStyles();
  /* school id */
  let { schoolId } = useParams();
  const [value, setValue] = React.useState(0);
  const initialRecordState = {
    id: schoolId,
    codigo: "Xh230jy8",
    nombre: "Nombre Colegio",
    pais: "San José, Costa Rica"
  };
  const [dashboard, setDashboard] = useState(null);
  const [getSchool, resultSchool] = useLazyQuery(SCHOOL_BY_ID);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let { tab } = useParams();
  useEffect(() => {
    if (tab) {
      console.log("tab", tab);
      setValue(parseInt(tab));
    }
  }, [tab]);

  useEffect(() => {
    if (resultSchool.data) {
      setDashboard(resultSchool.data.school);
    }
  }, [resultSchool]);

  const fetchDetailSchool = () => {
    let isLoaded = true;

    getSchool({
      variables: {
        id: schoolId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchDetailSchool();
  }, []);

  if (!dashboard) return null;

  return (
    <Page className={classes.root} title="Detalle del colegio">
      <Container maxWidth={false}>
        {/*}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-start">
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                {dashboard.name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {*/}
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          onChange={handleChange}
          aria-label="disabled tabs"
        >
          <Tab label="Información" />
          <Tab label="Docentes" />
          <Tab label="Estudiantes" />
          <Tab label="Grupos de Clase" />
          <Tab label="Niveles Académicos" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {value === 0 && <InfoSchool />}
          {value === 1 && <TeacherList />}
          {value === 2 && <StudentsList />}
          {value === 3 && <ClassGroupList />}
          {value === 4 && <DashboardAcademic />}
        </div>
      </Container>
    </Page>
  );
};

export default Dashboard;
