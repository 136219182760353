import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Grid,
  Tabs,
  Tab,
  Divider,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
import Page from "src/components/Page";
import ArtList from "./ArtList";
import ArtistsList from "./Artists/ArtistsList";
import StylesList from "./Styles/StylesList";
import ArtTechniquesList from "./ArtTechniques/ArtTechniquesList";
/* Services */
import ApiService from "src/services/ApiService";
import { useParams } from "react-router";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inner: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}));

const DashboardArt = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let { tab } = useParams();
  useEffect(() => {
    if (tab) {
      console.log("tab", tab);
      setValue(parseInt(tab));
    }
  }, [tab]);

  return (
    <Page className={classes.root} title="Obras">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-start">
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                Arte
              </Typography>
            </Box>
            <Box
              mt={0}
              display="flex"
              justifyContent="flex-start"
              alignItems="left"
            >
              <Typography align="left" color="textPrimary" variant="h6">
                {totalRecords ? totalRecords : 0} obras
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          onChange={handleChange}
          aria-label="disabled tabs"
        >
          <Tab label="Obras" />
          <Tab label="Artistas" />
          <Tab label="Estilos" />
          <Tab label="Técnicas" />
        </Tabs>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {value === 0 && <ArtList setTotalRecords={setTotalRecords} />}
          {value === 1 && <ArtistsList />}
          {value === 2 && <StylesList />}
          {value === 3 && <ArtTechniquesList />}
        </div>
      </Container>
    </Page>
  );
};

export default DashboardArt;
