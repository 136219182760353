import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardContent, Grid, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import ShowChartOutlinedIcon from '@material-ui/icons/ShowChartOutlined';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: '#0074BC',
    height: 56,
    width: 56
  }
}));

const TasksProgress = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              ASIGNACIÓN COMPLETA
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              50%
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ShowChartOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Typography
            color="textSecondary"
            variant="caption"
            mt={2}
          >
            16/32
          </Typography>
          <LinearProgress
            value={50}
            variant="determinate"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

TasksProgress.propTypes = {
  className: PropTypes.string
};

export default TasksProgress;
