import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: '#00B7BD',
    height: 56,
    width: 56
  }
}));

const TotalPoints = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              PUNTOS
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              660
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <StarBorderOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalPoints.propTypes = {
  className: PropTypes.string
};

export default TotalPoints;
