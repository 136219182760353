import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from "@material-ui/core";
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon
} from "react-feather";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import PersonIcon from "@material-ui/icons/Person";
import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined";
import PanoramaOutlinedIcon from "@material-ui/icons/PanoramaOutlined";
import NavItem from "./NavItem";
/* Services */
import ApiService from "src/services/ApiService";
import { useMeProfile } from "src/services/me";
import { useLazyQuery } from "@apollo/client";
import { ME } from "src/services/queries";
import AvatarUser from "src/components/AvatarUser";

const items = [
  {
    href: "/app/dashboard",
    icon: "/static/images/menu/ic_menu_dashboard.png",
    title: "Dashboard"
  },
  {
    href: "/admin/schools",
    icon: "/static/images/menu/ic_menu_school.png",
    title: "Colegios"
  },
  {
    href: "/admin/arts",
    icon: "/static/images/menu/ic_menu_arts.png",
    title: "Arte"
  },
  {
    href: "/admin/users/public-users",
    icon: "/static/images/menu/ic_menu_public_users.png",
    title: "Usuarios Públicos"
  },
  {
    href: "/admin/system",
    icon: "/static/images/menu/ic_menu_system_general.png",
    title: "Sistema"
  },
  {
    href: "/admin/store",
    icon: "/static/images/menu/ic_menu_shop.png",
    title: "Tienda"
  },
  {
    href: "/admin/ads",
    icon: "/static/images/menu/ic_menu_ads.png",
    title: "Ads"
  },
  {
    href: "/admin/users/admin-users",
    icon: "/static/images/menu/ic_menu_admin_users.png",
    title: "Administradores"
  },
  {
    href: "/admin/settings",
    icon: "/static/images/menu/ic_menu_system_dashboard.png",
    title: "Configuraciones"
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  /* teacher id */
  let teacherId = 45;
  const location = useLocation();
  const [user, setUser] = useState(useMeProfile());

  const [getMe, resultMe] = useLazyQuery(ME, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result) {
        if (result.me) {
          //console.log("RESULTADO: getMe", result.me[0]);
          setUser(result.me[0]);
        }
      }
    }
  });

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {user && (
        <>
          <Box alignItems="center" display="flex" flexDirection="column" p={2}>
            {user.id && <AvatarUser id={user.id} only />}
            <Typography color="textPrimary" variant="h5">
              {user.first_name} {user.last_name}
            </Typography>
            {user.role && (
              <Typography color="textSecondary" variant="body2">
                {user.role.name}
              </Typography>
            )}
          </Box>
          <Divider />
        </>
      )}
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
