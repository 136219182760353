import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import Page from "src/components/Page";
import AdsList from "./AdsList/AdsList";
import { GET_COUNT_ADS } from "src/services/queries";
import { useLazyQuery } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardBlock: {
    minHeight: "100%",
    paddingBottom: "24px"
  },
  titleHeadTopbarSection: {
    fontSize: "24px"
  },
  inner: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  title_head_name: {
    color: "#212529"
  },
  avatar: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  avatarItem: {
    width: "90px",
    height: "90px",
    backgroundSize: "cover",
    objectFit: "cover"
  }
}));

const DashboardAds = ({ className, ...rest }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [totalAds, setTotalAds] = useState(0);
  const initialRecordState = {
    clicks: 0,
    impressions: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const [getCount, resultCount] = useLazyQuery(GET_COUNT_ADS, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("RESULTADO: GET_COUNT_ADS", result);
      if (result) {
        setDashboard({
          clicks: result.clicks,
          impressions: result.impressions
        });
      }
    }
  });

  const fetchAllArts = () => {
    getCount();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchAllArts();
    setDashboard(initialRecordState);
  }, []);

  return (
    <Page className={classes.root} title="Anuncios">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-start">
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant="h4"
                className={classes.titleHeadTopbarSection}
              >
                Ads
              </Typography>
            </Box>
            <Box
              mt={0}
              display="flex"
              justifyContent="flex-start"
              alignItems="left"
            >
              <Typography align="left" color="textPrimary" variant="h6">
                {totalAds ? totalAds : 0} Ads
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={1}></Box>
        <Grid container spacing={3}>
          <Grid item lg={6} sm={12} xs={12}>
            <Card className={clsx(classes.cardBlock, className)} {...rest}>
              <CardContent>
                <Grid container justify="space-between" spacing={3}>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      IMPRESIONES
                    </Typography>
                    <Typography color="textPrimary" variant="h3">
                      {dashboard.impressions ? dashboard.impressions : 0}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/static/images/ic_star_white.png"
                        }
                      ></img>
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} sm={12} xs={12}>
            <Card className={clsx(classes.cardBlock, className)} {...rest}>
              <CardContent>
                <Grid container justify="space-between" spacing={3}>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="h6">
                      CLICS
                    </Typography>
                    <Typography color="textPrimary" variant="h3">
                      {dashboard.clicks ? dashboard.clicks : 0}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar className={classes.avatarProgress}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/static/images/ic_arrow_pointer.png"
                        }
                      ></img>
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box mt={3}></Box>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xs={12}>
            <AdsList countAds={setTotalAds} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardAds;
