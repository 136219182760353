import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  ListItemText,
  LinearProgress
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import SYSTEM_MESSAGES from "src/config/messages";
import { GET_SCHOOL_TEACHER_LIST } from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_TEACHER } from "src/services/mutations";
import AvatarUser from "src/components/AvatarUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const mock = [
  {
    id: 1,
    nombre: "Mario López",
    nivel_educacion: "Completa",
    grupos_clase: 124,
    total_estudiantes: 4,
    total_asignaciones: 8
  },
  {
    id: 2,
    nombre: "Mario López",
    nivel_educacion: "Primaria",
    grupos_clase: 110,
    total_estudiantes: 4,
    total_asignaciones: 8
  },
  {
    id: 3,
    nombre: "Mario López",
    nivel_educacion: "Pre-escolar",
    grupos_clase: 82,
    total_estudiantes: 4,
    total_asignaciones: 8
  }
];

const TeacherList = ({ className, ...rest }) => {
  const classes = useStyles();
  /* school id */
  let { schoolId } = useParams();
  const [teachers, setTeachers] = useState([]);
  const [totalClassGroups, setTotalClassGroups] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [deleteMutation, resultDeleteMutation] = useMutation(DELETE_TEACHER, {
    onCompleted(data) {
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
      });
      //setTeachers(teachers.filter(item => item.id !== itemSelected.id));
      openCloseModalDelete();
      fetchTeachers();
    },
    onError(error) {
      console.log(error);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
      });
      openCloseModalDelete();
    }
  });

  const [schoolTeachersList, resultSchoolTeachersList] = useLazyQuery(
    GET_SCHOOL_TEACHER_LIST,
    {
      fetchPolicy: "no-cache"
    }
  );

  useEffect(() => {
    if (resultSchoolTeachersList.data) {
      console.log(resultSchoolTeachersList.data.schoolTeachersList);
      setTeachers(resultSchoolTeachersList.data.schoolTeachersList);
      setTotalClassGroups(
        resultSchoolTeachersList.data.schoolTeachersList.length
      );
    }
  }, [resultSchoolTeachersList]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, teachers.length - page * rowsPerPage);

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClose()}>
            <Link
              to={
                "/admin/school/" +
                `${schoolId}` +
                "/teacher/" +
                `${data.id}` +
                "/edit"
              }
            >
              Editar
            </Link>
          </MenuItem>
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setTeachers(
        [...items].sort((a, b) =>
          a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setTeachers(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteTeacher(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  /**
   * deleteTeacher
   */
  const deleteTeacher = id => {
    console.log("deleted id: " + id);
    deleteMutation({
      variables: {
        id
      }
    });
    /* ApiService.deleteTeacher(schoolId, id)
      .then(response => {
        if (response) {
          setOpenAlert(true);
          setErrorMsg({
            type: "success",
            message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
          });
          setTeachers(teachers.filter(item => item.id !== itemSelected.id));
          openCloseModalDelete();
          fetchTeachers();
        } else {
          setOpenAlert(true);
          setErrorMsg({
            type: "error",
            message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
          });
          openCloseModalDelete();
        }
      })
      .catch(err => {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
        });
        openCloseModalDelete();
        fetchTeachers();
      }); */
  };

  const fetchTeachers = () => {
    schoolTeachersList({
      variables: {
        school_id: schoolId
      }
    });
    /* ApiService.getAllTeachersBySchool(schoolId)
      .then(response => {
        if (response) {
          setTeachers(response.data);
          setTotalClassGroups(response.data.length);
          setSelectedSort(SYSTEM_MESSAGES.ALPHABETIC_ORDER);
        }
      })
      .catch(e => {
        setTeachers([]);
        setTotalClassGroups(0);
        setSelectedSort(SYSTEM_MESSAGES.ALPHABETIC_ORDER);
      }); */
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchTeachers();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!teachers) return null;

  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="secondary"
          variant="contained"
          href={"/admin/school/" + `${schoolId}` + "/new-teacher"}
        >
          <AddIcon /> AGREGAR DOCENTE
        </Button>
      </Box>
      <Box mt={3}></Box>
      <Card className={clsx(classes.root, className)} {...rest}>
        {/* Modal Delete */}
        <Modal open={modalEliminar} onClose={openCloseModalDelete}>
          {bodyModalDialogConfirmDelete}
        </Modal>
        <CardHeader
          action={
            <Box alignItems="center" display="flex">
              <Typography align="left" color="textPrimary" variant="h6">
                Mostrar por:
              </Typography>
              <Button
                className={classes.sortButton}
                onClick={handleSortOpen}
                ref={sortRef}
              >
                {selectedSort}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={sortRef.current}
                className={classes.menu}
                onClose={handleSortClose}
                open={openSort}
              >
                {[
                  SYSTEM_MESSAGES.MOST_RECENT,
                  SYSTEM_MESSAGES.ALPHABETIC_ORDER
                ].map(option => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option}
                    onClick={() => handleSortSelect(option, teachers)}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }
          title={
            <Box display="flex">
              <Typography
                className={classes.card_header_title}
                variant={"h6"}
                gutterBottom
              >
                Docentes &nbsp;
              </Typography>
              <Typography
                className={classes.card_subtitle_total}
                variant={"h6"}
                gutterBottom
              >
                {totalClassGroups ? totalClassGroups : 0} total
              </Typography>
            </Box>
          }
        />
        {/* Alert Success Delete | default hide */}
        <Collapse in={openAlert}>
          <Alert
            severity={errorMsg.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMsg.message}
          </Alert>
          <Box p={1}></Box>
        </Collapse>
        {resultSchoolTeachersList.loading && <LinearProgress />}
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Docentes</TableCell>
                  {/* <TableCell>
                    Nivel
                  </TableCell> */}
                  <TableCell>Grupos de clase</TableCell>
                  <TableCell>Estudiantes</TableCell>
                  <TableCell>Asignaciones</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teachers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => (
                    <TableRow hover key={item.id}>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <AvatarUser id={item.id} only={true} />
                          <Typography color="textPrimary" variant="body1">
                            <Link
                              className={classes.text}
                              to={
                                "/admin/school/" +
                                `${schoolId}` +
                                "/teacher/" +
                                `${item.id}` +
                                "/detail"
                              }
                            >
                              {item.first_name} {item.last_name}
                            </Link>
                          </Typography>
                        </Box>
                      </TableCell>
                      {/* <TableCell>
                    {item.nivel_educacion ? item.nivel_educacion : 0}
                    </TableCell> */}
                      <TableCell>
                        {item.classGroup ? item.classGroup : 0}
                      </TableCell>
                      <TableCell>{item.students ? item.students : 0}</TableCell>
                      <TableCell>
                        {item.assignments ? item.assignments : 0}
                      </TableCell>
                      <TableCell>
                        <MenuMoreActions data={item} />
                        <Link
                          className={classes.text}
                          to={
                            "/admin/school/" +
                            `${schoolId}` +
                            "/teacher/" +
                            `${item.id}` +
                            "/detail"
                          }
                        >
                          <IconButton edge="end" size="small">
                            <ArrowForwardIcon />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={teachers.length}
          onChangePage={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
        <Box display="flex" justifyContent="flex-end" p={2}></Box>
      </Card>
    </div>
  );
};

TeacherList.propTypes = {
  className: PropTypes.string,
  teachers: PropTypes.array
};

TeacherList.defaultProps = {
  teachers: []
};

export default TeacherList;
