import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles
} from "@material-ui/core";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  }
}));

const AssignmentProgress = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              ASIGNACIONES COMPLETADAS
            </Typography>
            <Typography color="textPrimary" variant="h3">
              90%
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ShowChartOutlinedIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Typography color="textSecondary" variant="caption">
            21/23
          </Typography>
          <LinearProgress value={90} variant="determinate" />
        </Box>
      </CardContent>
    </Card>
  );
};

AssignmentProgress.propTypes = {
  className: PropTypes.string
};

export default AssignmentProgress;
