import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import WelcomeTeacher from '../../../reports/DashboardView/WelcomeTeacher';
import ClassGroups from './ClassGroups';
import AdsFooter from '../../../reports/DashboardView/AdsFooter';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%'
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Grupos de Clase"
    >
      <Container maxWidth={false}>
        <WelcomeTeacher/>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
          >
            <ClassGroups />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={9}
            xs={12}
          >
            <AdsFooter />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
