import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography
} from "@material-ui/core";
import Page from "src/components/Page";
import EditAvatar from "./EditAvatar";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const DashboardEditAvatar = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Editar obra">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className={classes.text} to={"/admin/store"}>
              Tienda
            </Link>
            <Link className={classes.text} to={"/admin/store/1"}>
              Avatars
            </Link>
            <Typography color="textPrimary">Editar Avatar</Typography>
          </Breadcrumbs>
        </Box>
        <Box mb={2}>
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            Editar Avatar
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <EditAvatar />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardEditAvatar;
