import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Button,
  Box,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemText,
  TextField,
  Grid,
  LinearProgress
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_STYLES } from "src/services/queries";
import { CREATE_STYLE, DELETE_STYLE } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1"
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const StylesList = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  const [stylearts, setStyleArts] = useState([]);
  const [totalStyleArts, setTotalStyleArts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    name: "",
    description: "",
    artworks_count: 0
  };
  const [values, setValues] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [getStyle, resultStyle] = useLazyQuery(GET_STYLES, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result && result.styles) {
        console.log("GET_STYLES", result.styles);
        setStyleArts(result.styles);
        setTotalStyleArts(result.styles.length);
      }
    },
    onError: error => {
      console.log("error", error);
    }
  });

  const [styleMutation, resultStyleMutation] = useMutation(CREATE_STYLE, {
    refetchQueries: [{ query: GET_STYLES }],
    onCompleted(data) {
      console.log("CREATE_STYLE");
      console.log(data);
      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
      });
      setValues(initialRecordState);
      fetchStyleArts();

      formRef.current?.resetForm();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(false);
      setValues(initialRecordState);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
      });
      fetchStyleArts();
    }
  });

  const [deleteStyleMutation, resultDeleteStyleMutation] = useMutation(
    DELETE_STYLE,
    {
      refetchQueries: [{ query: GET_STYLES }],
      onCompleted(data) {
        setOpenAlert(true);
        setStyleArts(stylearts.filter(item => item.id !== itemSelected.id));
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
        });
        openCloseModalDelete();
        fetchStyleArts();
      },
      onError(error) {
        console.log(error);
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
        });
        openCloseModalDelete();
      }
    }
  );

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, stylearts.length - page * rowsPerPage);

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value == SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      items.sort((a, b) => a["nombre"].localeCompare(b["nombre"]));
    }

    if (value == SYSTEM_MESSAGES.MOST_RECENT) {
      items.sort((a, b) => {
        const aDate = a.fecha_agregado
          ? new Date(a.fecha_agregado)
          : new Date(a.fecha_agregado);
        const bDate = b.fecha_agregado
          ? new Date(b.fecha_agregado)
          : new Date(b.fecha_agregado);
        return bDate.getTime() - aDate.getTime();
      });
    }
  };

  /**
   * newStyle
   */
  const newStyle = values => {
    console.log(values);
    styleMutation({
      variables: {
        name: values.name,
        description: values.description
      }
    });
  };

  /**
   * Get All
   */
  const fetchStyleArts = () => {
    getStyle();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchStyleArts();
  }, []);

  /**
   * deleteStyle
   */
  const deleteStyle = id => {
    deleteStyleMutation({
      variables: {
        id
      }
    });
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteStyle(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  if (!stylearts) return null;

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialRecordState}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("El título es requerido."),
          description: Yup.string().required("La descripción es requerida.")
        })}
        onSubmit={values => {
          newStyle(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <Grid container spacing={3}>
              <Grid item lg={6} md={12} xl={6} xs={12}>
                <Card>
                  <CardHeader title="Agregar Estilo" />
                  {resultStyleMutation.loading && <LinearProgress />}
                  <Divider />
                  <CardContent>
                    <Collapse in={openAlert}>
                      <Alert
                        severity={errorMsg.type}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpenAlert(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        {errorMsg.message}
                      </Alert>
                      <Box p={1}></Box>
                    </Collapse>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Nombre"
                          name="name"
                          type="text"
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.name}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          label="Descripción"
                          name="description"
                          type="text"
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          helperText={touched.description && errors.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          required
                          value={values.description}
                          variant="outlined"
                          multiline
                          rows={4}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={
                        isSubmitting && resultStyleMutation.loading
                          ? true
                          : false
                      }
                    >
                      AGREGAR
                    </Button>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Box p={1}></Box>
      <Card className={clsx(classes.root, className)} {...rest}>
        {/* Modal Delete */}
        <Modal open={modalEliminar} onClose={openCloseModalDelete}>
          {bodyModalDialogConfirmDelete}
        </Modal>
        <CardHeader
          action={
            <Box alignItems="center" display="flex">
              <Typography align="left" color="textPrimary" variant="h6">
                Mostrar por:
              </Typography>
              <Button
                className={classes.sortButton}
                onClick={handleSortOpen}
                ref={sortRef}
              >
                {selectedSort}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={sortRef.current}
                className={classes.menu}
                onClose={handleSortClose}
                open={openSort}
              >
                {[
                  SYSTEM_MESSAGES.MOST_RECENT,
                  SYSTEM_MESSAGES.ALPHABETIC_ORDER
                ].map(option => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option}
                    onClick={() => handleSortSelect(option, stylearts)}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }
          title={
            <Box display="flex">
              <Typography
                className={classes.card_header_title}
                variant={"h6"}
                gutterBottom
              >
                Estilos &nbsp;
              </Typography>
              <Typography
                className={classes.card_subtitle_total}
                variant={"h6"}
                gutterBottom
              >
                {totalStyleArts} total
              </Typography>
            </Box>
          }
        />
        {resultStyle.loading && <LinearProgress />}
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell># de obras</TableCell>
                  <TableCell>Fecha agregada</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stylearts
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => (
                    <TableRow hover key={item.id}>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Typography color="textPrimary" variant="body1">
                            <Link
                              className={classes.text}
                              to={
                                "/admin/art/style/" + `${item.id}` + "/detail"
                              }
                            >
                              {item.name}
                            </Link>
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {item.artworks_count ? item.artworks_count : 0}
                      </TableCell>
                      <TableCell>
                        {item.createdAt
                          ? Utils.formatDateShort(item.createdAt)
                          : ""}
                      </TableCell>
                      <TableCell>
                        <MenuMoreActions data={item} />
                        <Link
                          className={classes.text}
                          to={"/admin/art/style/" + `${item.id}` + "/detail"}
                        >
                          <IconButton edge="end" size="small">
                            <ArrowForwardIcon />
                          </IconButton>
                        </Link>
                        <Menu
                          anchorEl={moreRef.current}
                          keepMounted
                          onClose={handleMenuClose}
                          open={openMenu}
                        >
                          <MenuItem>
                            <ListItemText primary="Editar" />
                          </MenuItem>
                          <MenuItem>
                            <ListItemText primary="Eliminar" />
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={stylearts.length}
          onChangePage={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
        <Box display="flex" justifyContent="flex-end" p={2}></Box>
      </Card>
    </div>
  );
};

StylesList.propTypes = {
  className: PropTypes.string,
  stylearts: PropTypes.array
};

StylesList.defaultProps = {
  stylearts: []
};

export default StylesList;
