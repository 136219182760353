import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Modal
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import { GET_ADS } from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_AD, UPDATE_AD, DELETE_AD } from "src/services/mutations";

require("dotenv").config();

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  media: {
    height: "345px",
    margin: "10px",
    paddingTop: "56.25%"
  },
  actions: {
    justifyContent: "flex-end"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  title_art: {
    textAlign: "center",
    fontSize: "20px"
  },
  titleDiamond: {
    color: "#0074BC"
  }
}));

const PATH_URL = API_URL;

const mock = [
  {
    id: 1,
    nombre: "Ads",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 2,
    nombre: "La Noche Estrellada",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 3,
    nombre: "La Mona Lisa",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 4,
    nombre: "Ads",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 5,
    nombre: "La Noche Estrellada",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 6,
    nombre: "La Mona Lisa",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 7,
    nombre: "Ads",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 8,
    nombre: "La Noche Estrellada",
    autor: "Andy Warhol",
    anio: "1962",
    total_preguntas: "10",
    fecha_agregado: "28/04/2021"
  }
];

const AdsList = ({ countAds, className, ...rest }) => {
  const classes = useStyles();
  const [ads, setAds] = useState([]);
  const [totalAds, setTotalAds] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    titulo: ""
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);

  const [getAds, resultAds] = useLazyQuery(GET_ADS);
  const [deleteAdMutation, resultDeleteAdMutation] = useMutation(DELETE_AD, {
    refetchQueries: [{ query: GET_ADS }],
    onCompleted(data) {
      setOpenAlert(true);
      setAds(ads.filter(item => item.id !== itemSelected.id));
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
      });
      openCloseModalDelete();
      fetchAllAds();
    },
    onError(error) {
      console.log(error);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
      });
      openCloseModalDelete();
    }
  });

  useEffect(() => {
    if (resultAds.data) {
      console.log(resultAds.data.ads);
      setAds(resultAds.data.ads);
      setTotalAds(resultAds.data.ads.length);
      countAds(resultAds.data.ads.length);
    }
  }, [resultAds]);

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClose()}>
            <Link
              className={classes.text}
              to={"/admin/ads/" + `${data.id}` + "/detail"}
            >
              Editar
            </Link>
          </MenuItem>
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * deleteAd
   */
  const deleteAd = id => {
    deleteAdMutation({
      variables: {
        id
      }
    });
    /*
    ApiService.deleteAdById(id)
      .then(response => {
        if (response) {
          setOpenAlert(true);
          setAds(ads.filter(item => item.id !== itemSelected.id));
          setErrorMsg({
            type: "success",
            message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
          });
          openCloseModalDelete();
          fetchAllAds();
        } else {
          setOpenAlert(true);
          setErrorMsg({
            type: "error",
            message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
          });
        }
      })
      .catch(err => {
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
        });
        openCloseModalDelete();
      });
      */
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.titulo}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteAd(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  const fetchAllAds = () => {
    getAds();
    /*ApiService.getAllAds()
      .then(response => {
        if (response) {
          setAds(response.data);
          setTotalAds(response.data.length);
        }
      })
      .catch(e => {
        setAds(mock);
        setTotalAds(mock.length);
      });
      */
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;
    console.log(process.env.REACT_APP_API_DOMAIN);

    fetchAllAds();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!ads) return null;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              href={"/admin/ads/new-ads"}
            >
              <AddIcon /> AGREGAR AD
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}></Box>
      {/* Modal Delete */}
      <Modal open={modalEliminar} onClose={openCloseModalDelete}>
        {bodyModalDialogConfirmDelete}
      </Modal>
      {/* Alert Success Delete | default hide */}
      <Collapse in={openAlert}>
        <Alert
          severity={errorMsg.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {errorMsg.message}
        </Alert>
        <Box p={1}></Box>
      </Collapse>
      <Grid container spacing={3}>
        {ads &&
          ads.map((item, index) => (
            <Grid item lg={3} md={6} xs={12} key={item.id}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={`${item.image}`}
                  title=""
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item lg={9} md={6} xs={12}>
                      <Typography
                        className={classes.title_art}
                        variant="h3"
                        color="textPrimary"
                        component="p"
                      >
                        <Link
                          className={classes.text}
                          to={"/admin/ads/" + `${item.id}` + "/detail"}
                        >
                          {item.title}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <MenuMoreActions data={item} />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

AdsList.propTypes = {
  className: PropTypes.string,
  ads: PropTypes.array.isRequired
};

AdsList.defaultProps = {
  ads: []
};

export default AdsList;
