import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Breadcrumbs,
  Typography
} from "@material-ui/core";
import Page from "src/components/Page";
import EditStudent from "./EditStudent";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgAds: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    height: "100%"
  }
}));

const IndexEditStudent = () => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();

  const handleClick = event => {
    event.preventDefault();
  };

  return (
    <Page className={classes.root} title="Editar Estudiante">
      <Container maxWidth={false}>
        <Box mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className={classes.text}
              to={"/teacher/classroom/view/" + `${classroomId}`}
            >
              Estudiantes
            </Link>
            <Typography color="textPrimary">Editar Estudiante</Typography>
          </Breadcrumbs>
        </Box>
        <Box mb={2}>
          <Typography
            align="left"
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            Editar Estudiante
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={8} md={6} xs={12}>
            <EditStudent />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default IndexEditStudent;
