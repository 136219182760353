import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Box, Button, Collapse, Card, CardContent, CardHeader, Divider, Grid, TextField, makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
/* Services */
import ApiService from 'src/services/ApiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EditStudent = ({ className, ...rest }) => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();
  /* student id */
  let { studentId } = useParams();
  const initialRecordState = {
    nombre: '',
    apellido: '',
    correo_estudiante: '',
    correo_tutor: '',
    nueva_clave: '',
    confirmar_clave: '',
    classroom_id: classroomId,
    user_id: 1
  };
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errors);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * updateStudent
   */
  const updateStudent = () => {
    let data = {
      nombre: "",
      apellido: "",
      correo_estudiante: values.correo_estudiante,
      correo_tutor: values.correo_tutor,
      nueva_clave: values.nueva_clave,
      confirmar_clave: values.confirmar_clave,
      classroom_id: classroomId,
      user_id: 1
    };

    if(values.nueva_clave != values.confirmar_clave){
      setOpenAlert(true);
      setErrorMsg({"type": "error", "message": "Las contraseñas deben coincidir."});
    } else {
      ApiService.editStudent(studentId, data).then(response => {
        if(response){
          setSubmitted(true);
          setOpenAlert(true);
          setErrorMsg({"type": "success", "message": "Los datos del estudiante se han actualizado correctamente."});
          ApiService.getStudentById(studentId).then(response => {
            if(response){
              const initialRecordState = {
                nombre: response.data.nombre,
                apellido: response.data.apellido,
                correo_estudiante: response.data.correo,
                correo_tutor: response.data.correo_tutor,
                classroom_id: classroomId,
                user_id: 1
              }
              setValues(initialRecordState);
            }
          }).catch(e => {
          });
        }
      }).catch(err => {
        setSubmitted(false);
        setOpenAlert(false);
      });
    }
  };

  /**
   * Fetch API
   */
   useEffect(() => {
    let isLoaded = true;

    const fetchStudentDetail = () => {
      ApiService.getStudentById(studentId).then(response => {
        if (isLoaded) {
          const initialRecordState = {
            nombre: response.data.nombre,
            apellido: response.data.apellido,
            correo_estudiante: response.data.correo,
            correo_tutor: response.data.correo_tutor,
            classroom_id: classroomId,
            user_id: 1
          }
          setValues(initialRecordState);
        }
      }).catch(e => {
      });
    };

    fetchStudentDetail();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title="Estudiante"
        />
        <Divider />
        <CardContent>
          <Collapse in={openAlert}>
            <Alert severity={errorMsg.type}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
            {errorMsg.message}
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Grid
            container
            spacing={3}>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nombre"
                name="nombre"
                onChange={handleChange}
                required
                value={values.nombre}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Apellido"
                name="apellido"
                onChange={handleChange}
                required
                value={values.apellido}
                variant="outlined"
              />
            </Grid> */}
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Correo del estudiante"
                name="correo_estudiante"
                onChange={handleChange}
                required
                value={values.correo_estudiante}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Correo del padre o tutor"
                name="correo_tutor"
                onChange={handleChange}
                value={values.correo_tutor}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Contraseña"
                name="nueva_clave"
                onChange={handleChange}
                required
                type="password"
                value={values.nueva_clave}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Confirmar contraseña"
                name="confirmar_clave"
                onChange={handleChange}
                required
                type="password"
                value={values.confirmar_clave}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updateStudent}
          >
            GUARDAR CAMBIOS
          </Button>
        </Box>
      </Card>
    </form>
  );
};

EditStudent.propTypes = {
  className: PropTypes.string
};

export default EditStudent;
