import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton,
  Checkbox,
  colors,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ALL_ARTWORKS,
  GET_ARTWORKS,
  GET_ARTWORK_SHOP
} from "src/services/queries";
import { CREATE_ARTWORKS_FOR_SHOP } from "src/services/mutations";

import * as Yup from "yup";
import { Formik } from "formik";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  title_head_name: {
    color: "#212529"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  },
  avatar_group: {
    marginRight: theme.spacing(2),
    background: "#F1F1F1",
    height: 56,
    width: 56
  },
  avatarItem: {
    width: "90px",
    height: "90px",
    backgroundSize: "cover",
    objectFit: "cover"
  }
}));

const PATH_URL = API_URL;

const EditArt = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* artwork id */
  let { artId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    id: "",
    available: false,
    gems: "",
    priority: ""
  };

  const initialArtwork = {
    obra: "",
    imagen: "",
    autor: "",
    anho: 0,
    autor: "",
    precio: 0
  };

  const obras = [
    {
      id: "",
      nombre: ""
    }
  ];

  const [values, setValues] = useState(initialRecordState);
  const [arts, setArts] = useState(obras);
  const [dashboard, setDashboard] = useState(initialArtwork);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [getArt, resultArt] = useLazyQuery(ALL_ARTWORKS, {
    onCompleted: result => {
      console.log(result.allArtworks);
      setArts(result.allArtworks);
    },
    fetchPolicy: "no-cache"
  });

  const [getArtShop, resultArtShop] = useLazyQuery(GET_ARTWORK_SHOP, {
    onCompleted: result => {
      console.log("shopArtwork", result.shopArtwork);
      setValues(result.shopArtwork);
      setDashboard(result.shopArtwork);
    },
    fetchPolicy: "no-cache"
  });

  const [addMutation, resultAddMutation] = useMutation(
    CREATE_ARTWORKS_FOR_SHOP,
    {
      onCompleted(data) {
        console.log(data);
        setSubmitted(true);
        setOpenAlert(true);
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_CREATED
        });
        //setValues(initialRecordState);
        fetchAllArts();
      },
      onError(error) {
        console.log(error);
        setSubmitted(false);
        setOpenAlert(true);
        //setValues(initialRecordState);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_CREATED
        });
      }
    }
  );

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    });
  };

  /**
   * saveUpdateArt
   */
  const saveUpdateArt = values => {
    console.log({
      id: parseInt(values.id),
      available: values.available == true ? 1 : 0,
      gems: parseInt(values.gems),
      priority: parseInt(values.priority)
    });
    addMutation({
      variables: {
        id: parseInt(values.id),
        available: values.available == true ? 1 : 0,
        gems: parseInt(values.gems),
        priority: parseInt(values.priority)
      }
    });
  };

  const fetchArtworkStoreDetail = () => {
    let isLoaded = true;

    getArtShop({
      variables: {
        id: artId
      }
    });
  };

  /**
   * Get All
   */
  const fetchAllArts = () => {
    getArt();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchArtworkStoreDetail();
    fetchAllArts();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      container
      spacing={3}
    >
      <Grid item lg={4} sm={6} xl={3} xs={12} mb={1}>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography
                  className={classes.title_head_name}
                  gutterBottom
                  variant="h2"
                >
                  {dashboard.name}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar_group}>
                  {dashboard.image ? (
                    <img
                      className={classes.avatarItem}
                      src={dashboard.image}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src =
                          process.env.PUBLIC_URL +
                          "/static/images/neutral_avatar.jpeg";
                      }}
                    />
                  ) : (
                    <img
                      className={classes.avatarItem}
                      src={
                        process.env.PUBLIC_URL +
                        "/static/images/neutral_avatar.jpeg"
                      }
                    />
                  )}
                </Avatar>
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography
                className={classes.subtitle_text_card}
                color="textPrimary"
                variant="caption"
              >
                Artista: {dashboard.autor}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography
                className={classes.subtitle_text_card}
                color="textPrimary"
                variant="caption"
              >
                Gemas: {dashboard.gems ? dashboard.gems : 0}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <Box p={1}></Box>
      </Grid>
      <Grid item lg={8} sm={6} xs={12} mb={1}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={values}
          validationSchema={Yup.object().shape({
            id: Yup.string().required("La obra es requerida."),
            gems: Yup.number()
              .typeError("Solo se permiten números.")
              .positive("Solo número positivo.")
              .integer("Solo se permiten enteros.")
              .required("El precio es requerido."),
            priority: Yup.number()
              .typeError("Solo se permiten números.")
              .positive("Solo número positivo.")
              .integer("Solo se permiten enteros.")
              .required("La prioriudad es requerida.")
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            saveUpdateArt(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              encType="multipart/form-data"
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader title="Información de la obra" />
                {resultAddMutation.loading && <LinearProgress />}
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Obra"
                        name="id"
                        error={Boolean(touched.id && errors.id)}
                        helperText={touched.id && errors.id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.id}
                        variant="outlined"
                      >
                        <option key={""} value={""}></option>
                        {arts.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box alignItems="center" display="flex" ml={-1}>
                        <Checkbox
                          checked={values.available == 1 ? true : false}
                          color="secondary"
                          name="available"
                          error={Boolean(touched.available && errors.available)}
                          helperText={touched.available && errors.available}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <Typography color="textSecondary" variant="body1">
                          Disponible
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Precio"
                        name="gems"
                        error={Boolean(touched.gems && errors.gems)}
                        helperText={touched.gems && errors.gems}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={values.gems}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Prioridad"
                        name="priority"
                        error={Boolean(touched.priority && errors.priority)}
                        helperText={touched.priority && errors.priority}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="text"
                        value={values.priority}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting && resultAddMutation.loading ? true : false
                    }
                  >
                    GUARDAR
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

EditArt.propTypes = {
  className: PropTypes.string
};

export default EditArt;
