import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  makeStyles,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import API_URL from "src/config/config";
import { GET_TEACHER } from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import SYSTEM_MESSAGES from "src/config/messages";
import AvatarUser from "src/components/AvatarUser";

import * as Yup from "yup";
import { Formik } from "formik";
import { EDIT_TEACHER } from "src/services/mutations";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  avatar_group: {
    background: "#F1F1F1"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  subtitle_text_card: {
    color: "#9EA0A5"
  }
}));

const PATH_URL = API_URL;

const EditTeacher = ({ className, ...rest }) => {
  const classes = useStyles();
  const formRef = useRef();

  /* school id */
  let { schoolId } = useParams();
  let { teacherId } = useParams();
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const initialRecordState = {
    first_name: "",
    last_name: "",
    email: ""
  };
  const [values, setValues] = useState(initialRecordState);
  const [dashboard, setDashboard] = useState(initialRecordState);
  const [errorMsg, setErrorMsg] = useState(errors);
  const [submitted, setSubmitted] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [getTeacher, resultTeacher] = useLazyQuery(GET_TEACHER, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("GET_TEACHER", result);
      if (result && result.getTeacher) {
        setValues(result.getTeacher);
        setDashboard(result.getTeacher);
      }
    }
  });

  const [editTeacher, resultEditTeacher] = useMutation(EDIT_TEACHER, {
    onCompleted(data) {
      console.log("EDIT_TEACHER", data);

      setSubmitted(true);
      setOpenAlert(true);
      setErrorMsg({
        type: "success",
        message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_UPDATED
      });
      fetchTeacherDetail();
    },
    onError(error) {
      console.log(error);
      setSubmitted(false);
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: SYSTEM_MESSAGES.MESSAGE_ERROR_UPDATED
      });
      fetchTeacherDetail();
    }
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * saveUpdateTeacher
   */
  const saveUpdateTeacher = values => {
    if (values.nombre == "" || values.correo == "") {
      setOpenAlert(true);
      setErrorMsg({
        type: "error",
        message: "Los campos no pueden estar vacíos"
      });
    } else {
      editTeacher({
        variables: {
          teacherId: parseInt(teacherId),
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email
        }
      });
    }
  };

  const fetchTeacherDetail = () => {
    getTeacher({
      variables: {
        id: teacherId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchTeacherDetail();
  }, []);

  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      container
      spacing={3}
    >
      <Grid item lg={4} sm={6} xl={3} xs={12} mb={1}>
        <Card>
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography
                  className={classes.title_head_name}
                  gutterBottom
                  variant="h2"
                >
                  {dashboard.first_name} {dashboard.last_name}
                </Typography>
              </Grid>
              <Grid item>
                <AvatarUser id={dashboard.id} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={8} sm={6} xl={6} xs={12} mb={1}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={values}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required("El nombre es requerido."),
            last_name: Yup.string().required("El apellido es requerido."),
            email: Yup.string()
              .email("Debe ser un email válido.")
              .max(255)
              .required("El correo electrónico es requerido.")
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            saveUpdateTeacher(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Card>
                <CardHeader title="Docente" />
                <Divider />
                <CardContent>
                  <Collapse in={openAlert}>
                    <Alert
                      severity={errorMsg.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {errorMsg.message}
                    </Alert>
                    <Box p={1}></Box>
                  </Collapse>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nombre"
                        name="first_name"
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Apellido"
                        name="last_name"
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Correo del docente"
                        name="email"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting && resultEditTeacher.loading ? true : false
                    }
                  >
                    GUARDAR
                  </Button>
                </Box>
              </Card>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

EditTeacher.propTypes = {
  className: PropTypes.string
};

export default EditTeacher;
