import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
/* Services */
import ApiService from "src/services/ApiService";
import { useLazyQuery } from "@apollo/client";
import { STUDENTS_COUNT } from "src/services/queries";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  avatar: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const Students = ({ className, ...rest }) => {
  const classes = useStyles();
  const initialRecordState = {
    estudiantes: 0,
    colegios: 0,
    colegios_nuevos: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  const [getUserCount, resultUserCount] = useLazyQuery(STUDENTS_COUNT);
  const [studentCount, setStudentCount] = useState(0);

  useEffect(() => {
    if (resultUserCount.data) {
      console.log(resultUserCount.data.students);
      setStudentCount(resultUserCount.data.students);
    }
  }, [resultUserCount]);

  /**
   * Fetch API
   */
  useEffect(() => {
    getUserCount();

    let isLoaded = true;

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL DE ESTUDIANTES
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {studentCount ? studentCount : 0}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <img
                src={process.env.PUBLIC_URL + "/static/images/ic_school.png"}
              ></img>
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <Typography color="textSecondary" variant="caption">
            {studentCount ? studentCount : 0}
          </Typography>
          &nbsp;
          <Typography color="textSecondary" variant="caption">
            Estudiantes ({studentCount ? studentCount : 0} Nuevos esta semana)
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Students.propTypes = {
  className: PropTypes.string
};

export default Students;
