import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from "@material-ui/core";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import { useLazyQuery } from "@apollo/client";
import { LIST_STUDENTS_BY_SCHOOL_AND_CLASSGROUP } from "src/services/queries";
import AvatarUser from "src/components/AvatarUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  }
}));

const PATH_URL = API_URL;

const mock = [
  {
    id: 1,
    avatar: "",
    nombre: "Mario López",
    nivel_educativo: "Primaria",
    grado: "4to Grado B",
    total_asignaciones: 16,
    puntos: 650,
    gemas: 250
  }
];

const StudentClassGroupList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [students, setStudents] = useState([]);
  const [totalStudents, setTotalStudents] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  /* school id */
  let { schoolId } = useParams();
  /* class group id */
  let { classgroupId } = useParams();

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, students.length - page * rowsPerPage);

  const [getStudent, resultStudent] = useLazyQuery(
    LIST_STUDENTS_BY_SCHOOL_AND_CLASSGROUP,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getStudent", result);
        if (result) {
          if (result.listStudents) {
            setStudents(result.listStudents);
            setTotalStudents(result.listStudents.length);
          }
        }
      }
    }
  );

  const fetchStudents = () => {
    getStudent({
      variables: {
        school_id: schoolId,
        classGroup_id: classgroupId
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchStudents();
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Estudiantes &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalStudents} total
            </Typography>
          </Box>
        }
      />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Estudiante</TableCell>
                {/* <TableCell>
                  Nivel educativo
                </TableCell> */}
                <TableCell>Asignaciones</TableCell>
                {/* <TableCell>
                  Grupo
                </TableCell> */}
                <TableCell>Puntos</TableCell>
                <TableCell>Gemas</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <AvatarUser id={item.id} />
                        <Typography color="textPrimary" variant="body1">
                          {item.first_name} {item.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {item.assignments ? item.assignments : 0}
                    </TableCell>
                    <TableCell>{item.points ? item.points : 0}</TableCell>
                    <TableCell>{item.gems ? item.gems : 0}</TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={students.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

StudentClassGroupList.propTypes = {
  className: PropTypes.string,
  students: PropTypes.array
};

StudentClassGroupList.defaultProps = {
  students: []
};

export default StudentClassGroupList;
