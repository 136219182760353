import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  colors,
  makeStyles
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import { useLazyQuery } from "@apollo/client";
import { GET_PG, GET_USER } from "src/services/queries";
import AvatarUser from "src/components/AvatarUser";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    height: "100%"
  },
  title_head_name: {
    color: "#212529"
  },
  avatar: {
    backgroundColor: "#00B7BD",
    height: 56,
    width: 56
  },
  avatarProgress: {
    backgroundColor: "#0074BC",
    height: 56,
    width: 56
  },
  avatarItem: {
    width: "90px",
    height: "90px",
    backgroundSize: "cover",
    objectFit: "cover"
  }
}));

const PATH_URL = API_URL;

const InfoPublicUser = ({ className, ...rest }) => {
  const classes = useStyles();
  let { publicUserId } = useParams();
  const [dashboard, setDashboard] = useState([]);
  const [pg, setPG] = useState([]);

  const [getUser, resultUser] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("GET_USER", result);
      if (result && result.user) setDashboard(result.user[0]);
    }
  });

  const [getPG, resultPG] = useLazyQuery(GET_PG, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      console.log("GET_PG", result);
      if (result && result.getPGByID) setPG(result.getPGByID);
    }
  });

  const fetchPublicUserDetail = () => {
    getUser({
      variables: {
        id: parseInt(publicUserId)
      }
    });

    getPG({
      variables: {
        userId: parseInt(publicUserId)
      }
    });
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchPublicUserDetail();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          {resultUser.loading && <LinearProgress />}
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography
                  className={classes.title_head_name}
                  gutterBottom
                  variant="h2"
                >
                  {dashboard.first_name} {dashboard.last_name}
                </Typography>
              </Grid>
              <Grid item>
                {dashboard && dashboard.id && <AvatarUser id={dashboard.id} />}
              </Grid>
            </Grid>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.correo}
              </Typography>
            </Box>
            <Box mt={0} display="flex" alignItems="center">
              <Typography color="textSecondary" variant="caption">
                {dashboard.edad ? dashboard.edad : 0} años
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          {resultPG.loading && <LinearProgress />}
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  PUNTOS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {pg.points ? pg.points : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/ic_star_white.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} sm={6} xl={3} xs={12}>
        <Card className={clsx(classes.root, className)} {...rest}>
          {resultPG.loading && <LinearProgress />}
          <CardContent>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  GEMAS
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {pg.gems ? pg.gems : 0}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatarProgress}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/static/images/ic_diamond_white.png"
                    }
                  ></img>
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

InfoPublicUser.propTypes = {
  className: PropTypes.string
};

export default InfoPublicUser;
