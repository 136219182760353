import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from "@material-ui/core";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import Utils from "src/utils/utils";
import { useLazyQuery } from "@apollo/client";
import { LIST_ASSIGNMENT_BY_SCHOOL_AND_CLASSGROUP } from "src/services/queries";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  }
}));

const PATH_URL = API_URL;

const AssignmentClassGroupList = ({ className, ...rest }) => {
  const classes = useStyles();
  /* school id */
  let { schoolId } = useParams();
  /* class group id */
  let { classgroupId } = useParams();
  const [assignments, setAssignments] = useState([]);
  const [totalAssignments, setTotalAssignments] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, assignments.length - page * rowsPerPage);

  const [getAssignment, resultAssignment] = useLazyQuery(
    LIST_ASSIGNMENT_BY_SCHOOL_AND_CLASSGROUP,
    {
      fetchPolicy: "no-cache",
      onCompleted: result => {
        console.log("RESULTADO: getAssignment", result);
        if (result) {
          if (result.listAssignments) {
            setAssignments(result.listAssignments);
            setTotalAssignments(result.listAssignments.length);
          }
        }
      }
    }
  );

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    const fetchAssignments = () => {
      getAssignment({
        variables: {
          school_id: schoolId,
          classGroup_id: classgroupId
        }
      });
    };

    fetchAssignments();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Asignaciones &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalAssignments} total
            </Typography>
          </Box>
        }
      />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rompecabezas</TableCell>
                <TableCell>Puntos</TableCell>
                <TableCell>Gemas</TableCell>
                <TableCell>Fecha de entrega</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar className={classes.avatar_group}>
                          {item.thumbnail && (
                            <img
                              className={classes.avatarItem}
                              src={item.thumbnail}
                            />
                          )}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {item.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{item.points ? item.points : 0}</TableCell>
                    <TableCell>{item.gems ? item.gems : 0}</TableCell>
                    <TableCell>
                      {item.delivery_date
                        ? Utils.formatDateShort(item.delivery_date)
                        : ""}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={assignments.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  );
};

AssignmentClassGroupList.propTypes = {
  className: PropTypes.string,
  assignments: PropTypes.array.isRequired
};

AssignmentClassGroupList.defaultProps = {
  assignments: []
};

export default AssignmentClassGroupList;
