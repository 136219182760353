import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import {
  Box,
  Collapse,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField, 
  IconButton,
  makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
/* Services */
import ApiService from 'src/services/ApiService';
import API_URL from 'src/config/config';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  /* teacher id */
  let teacherId = 45;
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertDanger, setOpenAlertDanger] = useState(false);
  const initialRecordState = {
    id: teacherId,
    nombre: '',
    apellido: '',
    correo: '',
    telefono: '',
    pais: '',
    ciudad: ''
  };
  const [values, setValues] = useState(initialRecordState);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  /**
   * updateTeacher
   */
   const updateTeacher = () => {
    let data = {
      id: teacherId,
      nombre: values.nombre,
      apellido: values.apellido,
      correo: values.correo,
      telefono: values.telefono,
      pais: values.pais,
      ciudad: values.ciudad
    };

    let isLoaded = true;

    if(values.nombre === "" || values.apellido === "" || values.correo === "" || values.telefono === "" || values.pais === "" || values.ciudad === ""){
      setOpenAlertDanger(true);
    } else {
      ApiService.editTeacherProfile(teacherId, data).then(response => {
        if(response){
          setSubmitted(true);
          setOpenAlert(true);
  
          ApiService.getTeacherProfile(teacherId).then(response => {
            if (isLoaded) {
              setValues(response.data);
            } else {
              isLoaded = false;
            }
          }).catch(e => {
            setValues(initialRecordState);
          });
        } else {
          ApiService.getTeacherProfile(teacherId).then(response => {
            if (isLoaded) {
              setValues(response.data);
            } else {
              isLoaded = false;
            }
          }).catch(e => {
            setValues(initialRecordState);
          });
        }
      }).catch(err => {
        console.log(err);
        setSubmitted(false);
        setOpenAlert(false);
        setOpenAlertDanger(false);
        setValues(initialRecordState);
      });
    }
  };

  /**
   * Fetch API
   */
   useEffect(() => {
    let isLoaded = true;

    const fetchTeacherDetail = () => {
      ApiService.getTeacherProfile(teacherId).then(response => {
        if (isLoaded) {
          setValues(response.data);
        }
      }).catch(e => {
        setValues(initialRecordState);
      });
    };

    fetchTeacherDetail();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title="Información del perfil"
        />
        <Divider />
        <CardContent>
          {/* Alert Success Delete | default hide */}
          <Collapse in={openAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Tus datos de perfil se han actualizado correctamente.
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Collapse in={openAlertDanger}>
            <Alert severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlertDanger(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Los campos no pueden estar vacios.
            </Alert>
            <Box p={1}></Box>
          </Collapse>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Por favor, especifique el nombre"
                label="Nombre"
                name="nombre"
                onChange={handleChange}
                required
                value={values.nombre}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Apellido"
                name="apellido"
                onChange={handleChange}
                required
                value={values.apellido}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Correo electrónico"
                name="correo"
                onChange={handleChange}
                required
                value={values.correo}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Teléfono"
                name="telefono"
                onChange={handleChange}
                type="number"
                value={values.telefono}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Estado"
                name="ciudad"
                onChange={handleChange}
                required
                value={values.ciudad}
                variant="outlined"
              >
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="País"
                name="pais"
                onChange={handleChange}
                required
                value={values.pais}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updateTeacher}
          >
            GUARDAR
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
