import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const SkeletonTable = props => {
  const { columns, rows } = props;
  const skeletonColumns = Array(columns).fill("");
  const skeletonRows = Array(rows).fill("");

  return skeletonRows.map(row => {
    return (
      <TableRow>
        {skeletonColumns.map(column => (
          <TableCell>
            <Skeleton animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    );
  });
};

export default SkeletonTable;
