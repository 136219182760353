import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Box,
  Collapse,
  Button,
  Grid,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemText,
  LinearProgress
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";
import Utils from "src/utils/utils";
import { GET_ARTISTS } from "src/services/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_ARTIST } from "src/services/mutations";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const PATH_URL = API_URL;

const mock = [
  {
    id: 1,
    avatar: "",
    nombre: "Andy",
    apellido: "Warhol",
    pais: "Estados Unidos",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 2,
    avatar: "",
    nombre: "Andy",
    apellido: "Warhol",
    pais: "Estados Unidos",
    fecha_agregado: "28/04/2021"
  },
  {
    id: 3,
    avatar: "",
    nombre: "Andy",
    apellido: "Warhol",
    pais: "Estados Unidos",
    fecha_agregado: "28/04/2021"
  }
];

const ArtistsList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [artists, setArtists] = useState([]);
  const [totalArtists, setTotalArtists] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const [getArtist, resultArtist] = useLazyQuery(GET_ARTISTS);
  const [deleteArtistMutation, resultDeleteArtistMutation] = useMutation(
    DELETE_ARTIST,
    {
      refetchQueries: [{ query: GET_ARTISTS }],
      onCompleted(data) {
        setOpenAlert(true);
        //setArtists(artists.filter(item => item.id !== itemSelected.id));
        setErrorMsg({
          type: "success",
          message: SYSTEM_MESSAGES.MESSAGE_SUCCESS_DELETED
        });
        openCloseModalDelete();
        fetchAllArtists();
      },
      onError(error) {
        console.log(error);
        setOpenAlert(true);
        setErrorMsg({
          type: "error",
          message: SYSTEM_MESSAGES.MESSAGE_ERROR_DELETED
        });
        openCloseModalDelete();
      }
    }
  );

  useEffect(() => {
    if (resultArtist.data) {
      console.log(resultArtist.data.artists);
      setArtists(resultArtist.data.artists);
      setTotalArtists(resultArtist.data.artists.length);
    }
  }, [resultArtist]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, artists.length - page * rowsPerPage);

  /**
   * Menu Actions
   */
  const MenuMoreActions = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = val => {};

    const handleClick = e => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    return (
      <React.Fragment>
        <IconButton edge="end" size="small" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => selectRecordToDelete(data, "Eliminar")}>
            Eliminar
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setArtists(
        [...items].sort((a, b) =>
          a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setArtists(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  /**
   * Get All
   */
  const fetchAllArtists = () => {
    getArtist();
  };

  /**
   * deleteArtist
   */
  const deleteArtist = id => {
    console.log("delete: " + id);
    deleteArtistMutation({
      variables: {
        id
      }
    });
  };

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteArtist(itemSelected && itemSelected.id)}
            variant="contained"
            color="secondary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  /**
   * Fetch API
   */
  useEffect(() => {
    let isLoaded = true;

    fetchAllArtists();

    return () => {
      isLoaded = false;
    };
  }, []);

  if (!artists) return null;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              href={"/admin/art/artist/new-artist"}
            >
              <AddIcon /> AGREGAR ARTISTA
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}></Box>
      <Card className={clsx(classes.root, className)} {...rest}>
        {/* Modal Delete */}
        <Modal open={modalEliminar} onClose={openCloseModalDelete}>
          {bodyModalDialogConfirmDelete}
        </Modal>
        <CardHeader
          action={
            <Box alignItems="center" display="flex">
              <Typography align="left" color="textPrimary" variant="h6">
                Mostrar por:
              </Typography>
              <Button
                className={classes.sortButton}
                onClick={handleSortOpen}
                ref={sortRef}
              >
                {selectedSort}
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={sortRef.current}
                className={classes.menu}
                onClose={handleSortClose}
                open={openSort}
              >
                {[
                  SYSTEM_MESSAGES.MOST_RECENT,
                  SYSTEM_MESSAGES.ALPHABETIC_ORDER
                ].map(option => (
                  <MenuItem
                    className={classes.menuItem}
                    key={option}
                    onClick={() => handleSortSelect(option, artists)}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }
          title={
            <Box display="flex">
              <Typography
                className={classes.card_header_title}
                variant={"h6"}
                gutterBottom
              >
                Artistas &nbsp;
              </Typography>
              <Typography
                className={classes.card_subtitle_total}
                variant={"h6"}
                gutterBottom
              >
                {totalArtists ? totalArtists : 0} total
              </Typography>
            </Box>
          }
        />
        {/* Alert Success Delete | default hide */}
        <Collapse in={openAlert}>
          <Alert
            severity={errorMsg.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMsg.message}
          </Alert>
          <Box p={1}></Box>
        </Collapse>
        {resultArtist.loading && <LinearProgress />}
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>País</TableCell>
                  <TableCell>Fecha Agregada</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {artists
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => (
                    <TableRow hover key={item.id}>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Avatar className={classes.avatar_group}>
                            {item.image ? (
                              <img
                                className={classes.avatarItem}
                                src={item.image}
                                width={60}
                                height={60}
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    process.env.PUBLIC_URL +
                                    "/static/images/neutral_avatar.jpeg";
                                }}
                              />
                            ) : (
                              <img
                                className={classes.avatarItem}
                                width={60}
                                height={60}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/static/images/neutral_avatar.jpeg"
                                }
                              />
                            )}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {item.first_name}{" "}
                            {item.last_name ? item.last_name : ""}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {item.countries_id ? item.country : ""}
                      </TableCell>
                      <TableCell>
                        {item.createdAt
                          ? Utils.formatDateShort(item.createdAt)
                          : Utils.formatDateShort(item.createdAt)}
                      </TableCell>
                      <TableCell>
                        <MenuMoreActions data={item} />
                        <Link
                          to={"/admin/art/artist/" + `${item.id}` + "/detail"}
                        >
                          <IconButton edge="end" size="small">
                            <ArrowForwardIcon />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={artists.length}
          onChangePage={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
        <Box display="flex" justifyContent="flex-end" p={2}></Box>
      </Card>
    </div>
  );
};

ArtistsList.propTypes = {
  className: PropTypes.string,
  artists: PropTypes.array.isRequired
};

ArtistsList.defaultProps = {
  artists: []
};

export default ArtistsList;
