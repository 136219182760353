import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardContent, Grid, Typography, colors, makeStyles } from '@material-ui/core';
import ApiService from 'src/services/ApiService';
import Utils from 'src/utils/utils';
import API_URL from 'src/config/config';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    height: 64,
    width: 64,
    borderRadius: '10px'
  },
  avatarAssignment: {
    width: '64px',
    height: '64px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    borderRadius: '10px'
  },
  avatarItem: {
    width: '40px',
    height: '40px',
    backgroundSize: 'cover',
    objectFit: 'cover'
  }
}));

const PATH_URL = API_URL;

const AssignmentInfo = ({ className, ...rest }) => {
  const classes = useStyles();
  /* assignment id */
  let { assignmentId } = useParams();
  const initialRecordState = {
    avatar: "",
    nombre: "",
    dificultad: "",
    total_preguntas: 0,
    fecha_entrega: "",
    total_asignados: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  /**
   * Fetch API
   */
   useEffect(() => {
    let isLoaded = true;

    const fetchAssignmentDetail = () => {
      ApiService.getAssignmentsById(assignmentId).then(response => {
        if (isLoaded) {
          setDashboard(response.data);
        }
      }).catch(e => {
        setDashboard(initialRecordState);
      });
    };

    fetchAssignmentDetail();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {dashboard.nombre}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              {dashboard.avatar ? <img className={classes.avatarAssignment} src={`${PATH_URL}`+ dashboard.avatar} onError={(e)=>{e.target.onerror = null; e.target.src=process.env.PUBLIC_URL +"/static/images/marilyn.png"}} /> : <img className={classes.avatarItem} src={process.env.PUBLIC_URL +"/static/images/marilyn.png"} />}
            </Avatar>
          </Grid>
        </Grid>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Dificultad:
          </Typography>
          &nbsp;
          <Typography
            color="textSecondary"
            variant="caption"
          >
            {dashboard.dificultad}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Preguntas Quiz:
          </Typography>
          &nbsp;
          <Typography
            color="textSecondary"
            variant="caption"
          >
            6
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Asignada:
          </Typography>
          &nbsp;
          <Typography
            color="textSecondary"
            variant="caption"
          >
            {moment(dashboard.fecha_entrega).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

AssignmentInfo.propTypes = {
  className: PropTypes.string
};

export default AssignmentInfo;
