import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import {
  Avatar,
  Button,
  Box,
  Collapse,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Modal,
  Typography,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  ListItemText,
  LinearProgress
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
/* Dialog */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
/* Services */
import ApiService from "src/services/ApiService";
import API_URL from "src/config/config";
import SYSTEM_MESSAGES from "src/config/messages";

import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_PG,
  GET_LIST_CLASS_GROUP_BY_TEACHER_AND_SCHOOL,
  GET_PUBLIC_USERS_LIST
} from "src/services/queries";
import AvatarUser from "src/components/AvatarUser";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {},
  actions: {
    justifyContent: "flex-end"
  },
  avatar_group: {
    marginRight: theme.spacing(2)
  },
  avatarItem: {
    width: "40px",
    height: "40px",
    backgroundSize: "cover",
    objectFit: "cover"
  },
  contentDialog: {
    borderRadius: "10px !important"
  },
  actionsButtons: {
    alignItems: "center",
    justifyContent: "center !important"
  },
  card_header_title: {
    color: "#3A3B3F",
    fontSize: "16px",
    lineHeight: "20px"
  },
  card_subtitle_total: {
    color: "#9EA0A5",
    fontSize: "12px"
  },
  sortButton: {
    textTransform: "capitalize",
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  }
}));

const PATH_URL = API_URL;

const mock = [
  {
    id: 1,
    nombre: "Javier Bustos",
    edad: 11,
    genero: "Masculino",
    rompecabezas: 23,
    puntos: 650,
    gemas: 250,
    ranking: 34
  },
  {
    id: 2,
    nombre: "Andrea Gonzalez",
    edad: 10,
    genero: "Femenino",
    rompecabezas: 16,
    puntos: 650,
    gemas: 250,
    ranking: 34
  },
  {
    id: 3,
    nombre: "Javier Bustos",
    edad: 11,
    genero: "Masculino",
    rompecabezas: 23,
    puntos: 650,
    gemas: 250,
    ranking: 34
  }
];

const PublicUsersList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [publicusers, setPublicUsers] = useState([]);
  const [totalPublicUsers, setTotalPublicUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  // Open Modal Delete
  const [openModal, setOpenModal] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [itemSelected, setItemSelected] = useState({
    id: "",
    nombre: ""
  });
  const [topten, setTopten] = useState(null);
  /* Error message */
  const errors = {
    type: "success",
    message: ""
  };
  const [errorMsg, setErrorMsg] = useState(errors);
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSort, setSelectedSort] = useState(
    SYSTEM_MESSAGES.ALPHABETIC_ORDER
  );

  const [getAllPG, resultGetAllPG] = useLazyQuery(GET_ALL_PG, {
    fetchPolicy: "no-cache",
    onCompleted: result => {
      if (result && result.getAllPG) {
        console.log("TOP TEN", result.getAllPG);
        const tt = result.getAllPG;
        //console.log("before", tt);
        tt.sort((a, b) => (a.points < b.points ? 1 : -1));
        //console.log("after", tt);
        setTopten(tt);
      }
    }
  });

  const [publicUsersQuery, resultPublicUsers] = useLazyQuery(
    GET_PUBLIC_USERS_LIST,
    {
      fetchPolicy: "no-cache"
    }
  );

  useEffect(() => {
    if (resultPublicUsers.data) {
      console.log(resultPublicUsers.data.publicUsersDashboardList);
      setPublicUsers(resultPublicUsers.data.publicUsersDashboardList);
      setTotalPublicUsers(
        resultPublicUsers.data.publicUsersDashboardList.length
      );
    }
  }, [resultPublicUsers]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, publicusers.length - page * rowsPerPage);

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  /**
   * openCloseModalDelete
   */
  const openCloseModalDelete = () => {
    setModalEliminar(!modalEliminar);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalEliminar(false);
  };

  const selectRecordToDelete = (item, action) => {
    setOpenModal(true);
    setItemSelected(item);
    action === "Eliminar" ? openCloseModalDelete() : openCloseModalDelete();
  };

  /**
   * handleSortOpen
   */
  const handleSortOpen = () => {
    setOpenSort(true);
  };

  /**
   * handleSortClose
   */
  const handleSortClose = () => {
    setOpenSort(false);
  };

  /**
   * handleSortSelect
   */
  const handleSortSelect = (value, items) => {
    setSelectedSort(value);
    setOpenSort(false);

    if (value === SYSTEM_MESSAGES.ALPHABETIC_ORDER) {
      console.log("Modo: alfabetico");
      setPublicUsers(
        [...items].sort((a, b) =>
          a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
        )
      );
    }

    if (value === SYSTEM_MESSAGES.MOST_RECENT) {
      console.log("Modo: más reciente");
      setPublicUsers(
        [...items].sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          else return -1;
        })
      );
    }
  };

  const fetchPublicUsers = () => {
    publicUsersQuery();
  };

  /**
   * Fetch API
   */
  useEffect(() => {
    fetchPublicUsers();
    getAllPG();
  }, []);

  /**
   * deleteStudent
   */
  const deleteStudent = id => {};

  /**
   * Modal Confirm Dialog
   */
  const bodyModalDialogConfirmDelete = (
    <div>
      <Dialog
        maxWidth={"sm"}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.contentDialog }}
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            ¿Está usted seguro de que desea eliminar este registro:{" "}
            <b>{itemSelected && itemSelected.nombre}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actionsButtons}>
          <Button onClick={() => openCloseModalDelete()} variant="outlined">
            CANCELAR
          </Button>
          <Button
            onClick={() => deleteStudent(itemSelected && itemSelected.id)}
            variant="contained"
            color="primary"
          >
            ELIMINAR
          </Button>
        </DialogActions>
        <Box mb={2}></Box>
      </Dialog>
    </div>
  );

  const getTopTenPosition = id => {
    if (topten) {
      const foundUser = topten.find(item => item.userId == id);
      if (foundUser) {
        const findPos = topten.findIndex(item => item.userId == id);
        console.log("findPos", findPos);
        return findPos + 1;
      } else return 0;
    }
    return 0;
  };

  return topten && publicusers ? (
    <Card className={clsx(classes.root, className)} {...rest}>
      {/* Modal Delete */}
      <Modal open={modalEliminar} onClose={openCloseModalDelete}>
        {bodyModalDialogConfirmDelete}
      </Modal>
      <CardHeader
        action={
          <Box alignItems="center" display="flex">
            <Typography align="left" color="textPrimary" variant="h6">
              Mostrar por:
            </Typography>
            <Button
              className={classes.sortButton}
              onClick={handleSortOpen}
              ref={sortRef}
            >
              {selectedSort}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={sortRef.current}
              className={classes.menu}
              onClose={handleSortClose}
              open={openSort}
            >
              {[
                SYSTEM_MESSAGES.MOST_RECENT,
                SYSTEM_MESSAGES.ALPHABETIC_ORDER
              ].map(option => (
                <MenuItem
                  className={classes.menuItem}
                  key={option}
                  onClick={() => handleSortSelect(option, publicusers)}
                >
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
        title={
          <Box display="flex">
            <Typography
              className={classes.card_header_title}
              variant={"h6"}
              gutterBottom
            >
              Usuarios Públicos &nbsp;
            </Typography>
            <Typography
              className={classes.card_subtitle_total}
              variant={"h6"}
              gutterBottom
            >
              {totalPublicUsers ? totalPublicUsers : 0} total
            </Typography>
          </Box>
        }
      />
      {/* Alert Success Delete | default hide */}
      <Collapse in={openAlert}>
        <Alert
          severity={errorMsg.type}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {errorMsg.message}
        </Alert>
        <Box p={1}></Box>
      </Collapse>
      {resultPublicUsers.loading || resultGetAllPG.loading ? (
        <LinearProgress />
      ) : null}
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Edad</TableCell>
                <TableCell>Completados</TableCell>
                <TableCell>Puntos</TableCell>
                <TableCell>Gemas</TableCell>
                <TableCell>Posición</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publicusers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(item => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <AvatarUser id={item.id} />
                        <Typography color="textPrimary" variant="body1">
                          <Link
                            className={classes.text}
                            to={
                              "/admin/users/public-user/" +
                              `${item.id}` +
                              "/detail"
                            }
                          >
                            {item.first_name ? item.first_name : ""}{" "}
                            {item.last_name ? item.last_name : ""}
                            {!item.first_name && !item.last_name
                              ? item.email
                              : ""}
                          </Link>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{item.age ? item.age : 0}</TableCell>
                    <TableCell>{item.puzzle ? item.puzzle : 0}</TableCell>
                    <TableCell>{item.points ? item.points : 0}</TableCell>
                    <TableCell>{item.gems ? item.gems : 0}</TableCell>
                    <TableCell>{getTopTenPosition(item.id)}</TableCell>
                    <TableCell>
                      <Link
                        className={classes.text}
                        to={
                          "/admin/users/public-user/" + `${item.id}` + "/detail"
                        }
                      >
                        <IconButton edge="end" size="small">
                          <ArrowForwardIcon />
                        </IconButton>
                      </Link>
                      <Menu
                        anchorEl={moreRef.current}
                        keepMounted
                        onClose={handleMenuClose}
                        open={openMenu}
                      >
                        <MenuItem>
                          <ListItemText primary="Editar" />
                        </MenuItem>
                        <MenuItem>
                          <ListItemText primary="Eliminar" />
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={publicusers.length}
        onChangePage={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
      />
      <Box display="flex" justifyContent="flex-end" p={2}></Box>
    </Card>
  ) : (
    <></>
  );
};

PublicUsersList.propTypes = {
  className: PropTypes.string,
  publicusers: PropTypes.array
};

PublicUsersList.defaultProps = {
  publicusers: []
};

export default PublicUsersList;
