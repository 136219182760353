import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#263238"
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%"
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto"
  },
  bgBodyGray: {
    color: "#ffffff",
    flex: "1 1 auto",
    display: "flex",
    overflow: "hidden",
    paddingTop: 64,
    background: "#F2F2F2 !important"
  }
}));

const MainLayoutGuest = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.pathname && location.pathname == "/") {
      console.log("<<< ROOT >>>");
      navigate("/login", { replace: true });
    } else console.log("location", location.pathname);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.bgBodyGray}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayoutGuest;
