import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ApiService from 'src/services/ApiService';

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const TopbarStudent = ({ className, ...rest }) => {
  const classes = useStyles();
  /* Id classroom */
  let { classroomId } = useParams();
  const initialRecordState = {
    nombre: "",
    codigo: "",
    total_estudiantes: 0
  };
  const [dashboard, setDashboard] = useState(initialRecordState);

  /**
   * Fetch API
   */
   useEffect(() => {
    let isLoaded = true;

    const fetchClassroomDetail = () => {
      ApiService.getGroupById(classroomId).then(response => {
        if (isLoaded) {
          setDashboard(response.data);
        }
      }).catch(e => {
        setDashboard(initialRecordState);
      });
    };

    fetchClassroomDetail();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
     <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box
            display="flex"
            justifyContent="flex-start"
          >
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {dashboard.nombre}
            </Typography>
          </Box>
          <Box
            mt={0}
            display="flex"
            justifyContent="flex-start"
            alignItems="left"
          >
            <Typography
              align="left"
              color="textPrimary"
              variant="h6"
            >
            {dashboard.total_estudiantes} Estudiantes
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <Typography
              align="right"
              color="textSecondary"
              gutterBottom
              variant="h4"
            >
              CÓDIGO DE LA CLASE
            </Typography>
          </Box>
          <Box
            mt={0}
            alignItems="right"
          >
            <Typography
              align="right"
              color="textPrimary"
              variant="h6"
            >
              <FileCopyOutlinedIcon/> {dashboard.codigo}
            </Typography>
          </Box>
        </Grid>
      </Grid> 
    </div>
  );
};

TopbarStudent.propTypes = {
  className: PropTypes.string
};

export default TopbarStudent;
